import "react-responsive-modal/styles.css";
import "../../../component/popup/styles.scss";
import { useEffect, useState } from "react";
import { Page } from "../../../interface";
import Layout from "../../../component/Layout";
import { useParams } from "react-router-dom";
import DateTimeUtil from "../../../utils/DateTimeUtil";
import WarehouseModel from "../../../model/WarehouseModel";
import TransactionWarehouseModel from "../../../model/TransactionWarehouseModel";
import * as TypeTransactionWarehouse from '../../../constant/type/TypeTransactionWarehouse'
import ConfigConstant from "../../../constant/config";
import { ExportOutlined } from "@ant-design/icons";
import useExportUserTransaction from "./export/useExportUserTransaction";

const default_avatar = "http://cdn.onlinewebfonts.com/svg/img_264570.png";
function DetailWarehousePromotion2023Page() {
  let { userId } = useParams();
  const [warehouse, setwarehouse] = useState<any>();
  const [transactions, setTransactions] = useState<Page<any>>()
  const ExportUserTransaction = useExportUserTransaction()

  const fetchwarehouse = () => {
    WarehouseModel.get(userId, ConfigConstant.INVOICE_PROMOTION_2023.promotionId).then((resp) => {
      if (resp.error === 0) {
        setwarehouse(resp.data);
        fetchListTransactionWarehouse(resp.data.id)
      }
    });
  };

  const fetchListTransactionWarehouse = (warehouseId) => {
    TransactionWarehouseModel.find(warehouseId)
      .then(resp => {
        if (resp.error == 0) {
          setTransactions(resp.data)
        }
      })
  }

  useEffect(() => {
    fetchwarehouse();
  }, []);

  return (
    <Layout>
      <main className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-xl-12">
              <div className="tab-content">
                <div
                  className="tab-pane fade show active"
                  id="account"
                  role="tabpanel"
                >
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title mb-0">Cửa hàng / NPP</h5>
                    </div>
                    <div className="card-body">
                      {warehouse && (
                        <form>
                          <div className="row">
                            <div className="col-md-8">
                              <div className="form-group">
                                <label>Tên</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={warehouse.user.name}
                                />
                              </div>
                              <div className="form-group">
                                <label>Số điện thoại</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={warehouse.user.phone}
                                />
                              </div>
                              <div className="form-group">
                                <label>Tên cửa hàng</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={warehouse.user.nameStore}
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="text-center">
                                <img
                                  alt=""
                                  src={
                                    warehouse.user.avatar
                                      ? warehouse.user.avatar
                                      : default_avatar
                                  }
                                  className="rounded-circle img-responsive mt-2"
                                  width={128}
                                  height={128}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <h4 style={{ paddingTop: '30px' }}>Chi tiết kho</h4>
                              <table className="table">
                                <tr>
                                  <th></th>
                                  <th>Tấm</th>
                                  <th>Khung</th>
                                  <th>Bột</th>
                                  <th>Tấm Mini 8mm</th>
                                </tr>
                                <tr style={{ color: '#e91e63', fontWeight: '600' }}>
                                  <td>Hiện tại</td>
                                  <td>{warehouse.plate}</td>
                                  <td>{warehouse.frame}</td>
                                  <td>{warehouse.compound}</td>
                                  <td>{warehouse.mini8Plate}</td>
                                </tr>
                                <tr>
                                  <td>Tổng</td>
                                  <td>{warehouse.historyPlate}</td>
                                  <td>{warehouse.historyFrame}</td>
                                  <td>{warehouse.historyCompound}</td>
                                  <td>{warehouse.historyMini8Plate}</td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-12 col-xl-12">
                  <div style={{display: 'flex'}} className="header">
                    <h1
                      style={{ color: "#3e4676" }}
                      className="header-title"
                    >
                      {" "}
                      Lịch sử{" "}
                    </h1>
                    <div style={{paddingLeft: '10px', paddingTop: '3px'}}>
                      <ExportOutlined onClick={() => {
                        if (transactions?.list) {
                           ExportUserTransaction.excute(transactions?.list, warehouse.userId)
                        }
                      }} style={{ cursor: 'pointer' }} />
                    </div>
                  </div>
                </div>
                <div style={{ marginBottom: "120px" }} className="card">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Thời gian</th>
                        <th>Avatar</th>
                        <th>Thành viên</th>
                        <th>Số tấm</th>
                        <th>Số khung</th>
                        <th>Số bột</th>
                        <th>Số tấm mini 8mm</th>
                        <th>Loại</th>
                        <th>Note</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactions &&
                        transactions.list &&
                        transactions.list.map((transaction, key) => {
                          let actor;
                          if (!transaction.form) {
                            actor = {
                              avatar: "https://s160-ava-talk.zadn.vn/2/0/c/0/1/160/ed961026aa0cd1557f142d600a52c8cb.jpg",
                              name: "Admin Knauf"
                            }
                          } else {
                            let form = transaction.form;
                            if (form && warehouse && warehouse.userId == form.userId) {
                              actor = {
                                avatar: form.distributor.avatar,
                                name: form.distributor.nameStore
                              }
                            }else {
                              actor = {
                                avatar: form.user.avatar,
                                name: form.user.nameStore || form.user.name
                              }
                            }
                          }
                          if (transaction.id == 9601 ) {
                            console.log(transaction)
                          }

                          return (
                            <tr key={key}>
                              <td>{transaction.id}</td>
                              <td>
                                {DateTimeUtil.toString(
                                  transaction.createdTime * 1000
                                )}
                              </td>
                              <td>
                                <img style={{ width: '70px', height: '70px', borderRadius: '50%' }} src={actor?.avatar} />
                              </td>
                              <td>
                                {actor?.name}
                              </td>
                              <td>{transaction.plate}</td>
                              <td>{transaction.frame}</td>
                              <td>{transaction.compound}</td>
                              <td>{transaction.mini8Plate}</td>
                              <td><span style={{ padding: '5px 15px', borderRadius: '5px', color: '#fff', backgroundColor: `${TypeTransactionWarehouse.getColor(transaction.type)}` }}>{TypeTransactionWarehouse.getName(transaction.type)}</span></td>
                              <td>{transaction.note}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}

export default DetailWarehousePromotion2023Page;
