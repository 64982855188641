import APIUtil from '../utils/APIUtils'
import * as TypeUser from "../constant/type/UserType";

const domain = process.env.REACT_APP_DOMAIN;
export default class FormModel {


    static get(id) {
        return new Promise((resolve, reject) => {
            APIUtil.getJSONWithCredentials(domain + `/api/form/get?id=${id}`, resolve, reject);
        });
    }

    static find(search, status, promotionId, page, pageSize, roleIds) {
        var param = Object.fromEntries(Object.entries({
            search, status, promotionId, page, pageSize, roleIds
        }).filter(([_, v]) => v != null && v !== 0));
        return new Promise((resolve, reject) => {
            APIUtil.getJSONWithCredentials(domain + `/api/form/find?${new URLSearchParams(param).toString()}`, resolve, reject);
        });
    }

    static findV2(promotionId, matchId, search, status, type, page, pageSize) {
        var param = Object.fromEntries(Object.entries({
            search, status, type, page, pageSize, promotionId, matchId,
        }).filter(([_, v]) => v != null && v !== 0));

        return new Promise((resolve, reject) => {
            APIUtil.getJSONWithCredentials(domain + `/api/form/find-v2?${new URLSearchParams(param).toString()}`, resolve, reject);
        });
    }

    static findByUser(userId, promotionId, page, pageSize) {
        var param = Object.fromEntries(Object.entries({
            userId, promotionId, page, pageSize
        }).filter(([_, v]) => v != null && v !== 0));

        return new Promise((resolve, reject) => {
            APIUtil.getJSONWithCredentials(domain + `/api/form/find?${new URLSearchParams(param).toString()}`, resolve, reject);
        });
    }

    static findInvocieForms(search, status, promotionId, page, pageSize, roleIds, searchByDistributor) {
        var param = Object.fromEntries(Object.entries({
            search, status, promotionId, page, pageSize, roleIds, searchByDistributor
        }).filter(([_, v]) => v != null && v !== 0));
        return new Promise((resolve, reject) => {
            APIUtil.getJSONWithCredentials(domain + `/api/invoice-form/find?${new URLSearchParams(param).toString()}`, resolve, reject);
        });
    }

    static updateStatus(id, status, note) {
        var form = {
            status,
            note
        }
        return new Promise((resolve, reject) => {
            APIUtil.postJSONWithCredentials(domain + `/api/invoice-form/update-status?id=${id}`,
                JSON.stringify(form), resolve, reject);
        });
    }

    static updateStatusPredictForm(id, status, note) {
        var form = {
            status,
            note
        }
        return new Promise((resolve, reject) => {
            APIUtil.postJSONWithCredentials(domain + `/api/predict-football-form/update-status?id=${id}`,
                JSON.stringify(form), resolve, reject);
        });
    }

    static updateForm(id, form) {
        var tmp = {
            billCreatedTime: form.billCreatedTime,
            plate: form.plate,
            frame: form.frame,
            compound: form.compound,
            mini8Plate: form.mini8Plate,
            distributorId: form.distributorId,
            standard9Plate: form.standard9Plate,
            moistureResistant9Plate: form.moistureResistant9Plate,
            fireProofPlate: form.fireProofPlate,
            threeInOnePlate: form.threeInOnePlate,
            ultraMoistPlate: form.ultraMoistPlate
        }
        return new Promise((resolve, reject) => {
            APIUtil.postJSONWithCredentials(domain + `/api/invoice-form/update?id=${id}`,
                JSON.stringify(tmp), resolve, reject);
        });
    }

    static recallForm(id, note) {
        var form = {
            note
        }
        return new Promise((resolve, reject) => {
            APIUtil.postJSONWithCredentials(domain + `/api/invoice-form/recall-form?id=${id}`,
                JSON.stringify(form), resolve, reject);
        });
    }

    static countByStatus(status, promotionId) {
        var param = Object.fromEntries(
            Object.entries({
                status,
                promotionId
            }).filter(([_, v]) => v != null)
        );
        return new Promise((resolve, reject) => {
            APIUtil.getJSONWithCredentials(
                domain +
                `/api/form/count?${new URLSearchParams(param).toString()}`,
                resolve,
                reject
            );
        });
    }
    static count(status, promotionId, userId) {
        var param = Object.fromEntries(
            Object.entries({
                status,
                promotionId, userId
            }).filter(([_, v]) => v != null)
        );
        return new Promise((resolve, reject) => {
            APIUtil.getJSONWithCredentials(
                domain +
                `/api/form/count?${new URLSearchParams(param).toString()}`,
                resolve,
                reject
            );
        });
    }

}