import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from "react";
import { UserCityMetric, UserDateMetric } from "../../interface";
import DateTimeUtil from "../../utils/DateTimeUtil";
import { City } from "../../utils/ProvinceUtil";

export function UserCityMetricChart({ data }) {
  const [option, setOption] = useState();

  const buildOption = (data: Array<UserCityMetric>) => {
    var series: any = [];
    for (var i = 0; i < data.length; i++) {
      var metric = data[i];
      series.push({
        name: City.getName(metric.city),
        y: metric.total,
      });
    }

    return {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
      },
      title: {
        text: "",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          },
        },
      },
      series: [
        {
          name: "Thành viên",
          colorByPoint: true,
          data: series,
        },
      ],
    };
  };

  useEffect(() => {
    const option: any = buildOption(data);
    setOption(option);
  }, []);

  return (
    <HighchartsReact
      constructorType={"chart"}
      highcharts={Highcharts}
      options={option}
    />
  );
}

export function UserCityMetricChartV2({ data }) {
  const [option, setOption] = useState();

  const buildOption = (data: Array<UserCityMetric>) => {
    var categories: any = [];
    var total: any = [];
    for (var i = data.length - 1; i >= 0; i--) {
      total.push(data[i].total);
      categories.push(City.getName(data[i].city));
    }
    var dataSeries = [
      {
        name: "Khách hàng",
        data: total,
      },
    ];
    return {
      chart: {
        type: "column",
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: categories,
        labels: {
          format: "{value:%e %b}",
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y}</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },

      plotOptions: {
        column: {
          stacking: "normal",
          dataLabels: {
            enabled: true
          },
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      colors: ["#009EE0", "#ff9800"],
      series: dataSeries,
    };
  };

  useEffect(() => {
    const option: any = buildOption(data);
    setOption(option);
  }, []);

  return (
    <HighchartsReact
      constructorType={"chart"}
      highcharts={Highcharts}
      options={option}
    />
  );
}

export function UserDateMetricChart({ data }) {
  const [option, setOption] = useState();

  const buildOption = (data: Array<UserDateMetric>) => {
    var categories: any = [];
    var retailers: any = [];
    var contractors: any = [];
    for (var i = data.length - 1; i >= 0; i--) {
      retailers.push(data[i].retailer);
      contractors.push([data[i].contractor]);
      categories.push(
        DateTimeUtil.toStringNotYear(DateTimeUtil.parseDate(data[i].date))
      );
    }
    var dataSeries = [
      {
        name: "Cửa hàng",
        data: retailers,
      },
      {
        name: "Nhà thầu",
        data: contractors,
      },
    ];
    return {
      chart: {
        type: "column",
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: categories,
        labels: {
          format: "{value:%e %b}",
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y}</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },

      plotOptions: {
        column: {
          stacking: "normal",
          // dataLabels: {
          //     enabled: true
          // },
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      colors: ["#009EE0", "#ff9800"],
      series: dataSeries,
    };
  };

  useEffect(() => {
    const option: any = buildOption(data);
    setOption(option);
  }, []);

  return (
    <HighchartsReact
      constructorType={"chart"}
      highcharts={Highcharts}
      options={option}
    />
  );
}

//Feedback

export function StatusMessageFeedbackDateMetricChart({ data }) {
  const [option, setOption] = useState();

  const buildOption = (data: Array<any>) => {
    var categories: any = [];
    var init: any = [];
    var received: any = [];
    var close: any = [];

    for (var i = data.length - 1; i >= 0; i--) {
      init.push(data[i].init);
      received.push([data[i].received]);
      close.push([data[i].close]);
      categories.push(
        data[i].date
      );
    }


    var dataSeries = [
      {
        name: "Đang xử lý",
        data: init,
      },
      {
        name: "Đã phản hồi",
        data: received,
      },
      {
        name: "Đã đóng",
        data: close,
      },
    ];


    return {
      chart: {
        type: "column",
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: categories,
        labels: {
          format: "{value:%e %b}",
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y}</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },

      plotOptions: {
        column: {
          stacking: "normal",
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      colors: ["#dc3545", "#faad14", "#28a745"],
      series: dataSeries,
    };
  };

  useEffect(() => {
    const option: any = buildOption(data);
    setOption(option);
  }, []);

  return (
    <HighchartsReact
      constructorType={"chart"}
      highcharts={Highcharts}
      options={option}
    />
  );
}

export function TopicMessageFeedbackDateMetricChart({ data }) {
  const [option, setOption] = useState();

  const buildOption = (data: Array<any>) => {
    var categories: any = [];
    const _series = {}
    for (var i = data.length - 1; i >= 0; i--) {
      const { date, mapCount } = data[i];
      Object.keys(mapCount).forEach(key => {
        if (!_series[key]) {
          _series[key] = [];
        }
        _series[key].push(mapCount[key] || 0);
      });
      categories.push(
        DateTimeUtil.toStringNotYear(DateTimeUtil.parseDate(data[i].date))
      );
    }

    const series = Object.entries(_series).map(([key, value]) => ({
      name: key,
      data: value,
    }));


    return {
      chart: {
        type: "column",
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: categories,
        labels: {
          format: "{value:%e %b}",
        },
        crosshair: true
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y}</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },

      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      // colors: ["#dc3545", "#faad14", "#28a745"],
      series: series,
    };
  };

  useEffect(() => {
    const option: any = buildOption(data);
    setOption(option);
  }, []);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={option}
    />
  );
}

export function RatingTopicMessageFeedbackDateMetricChart({ data }) {
  const [option, setOption] = useState();

  const options = {
    chart: {
      type: 'column'
    },
    title: {
      text: 'Biểu đồ cột theo tháng'
    },
    xAxis: {
      categories: data.map(item => item.month),
      crosshair: true,
      reversed: true, 
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Đánh giá'
      }
    },
    series: Object.entries(data[0].mapRating).map(([name, value]) => ({
      name,
      data: data.map(item => item.mapRating[name] || 0)
    }))
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  );
}

export function RatingTopicBarPercentChart({ content }) {
  const [option, setOption] = useState();

  const buildOption = () => {

    const data = content.map(_ => {
      return {
        "name": _.topic.title,
        "rating": {
          "5 Sao": _.five,
          "4 Sao": _.four,
          "3 Sao": _.three,
          "2 Sao": _.two,
          "1 Sao": _.one
        }
      }
    })

    // Convert data to series format
    const seriesData = Object.keys(data[0].rating).map(star => {
      return {
        name: star,
        data: data.map(item => item.rating[star])
      };
    });


    // Define categories
    const categories = data.map(_ => _.name)

    return {
      chart: {
        type: 'bar' // Sử dụng loại biểu đồ bar
      },
      title: {
        text: ''
      },
      xAxis: {
        reversed: false, // Đảo ngược trục x
        categories: categories
      },
      yAxis: {
        reversed: false, // Đảo ngược trục x
        min: 1,
        title: {
          text: ''
        }
      },
      plotOptions: {
        bar: {
          stacking: 'percent',
          dataLabels: {
            enabled: true,
            format: '{point.percentage:.0f}%', // Hiển thị phần trăm,
          }
        }
      },
      tooltip: {
        headerFormat: '',
        pointFormat: '<b>{point.category}</b><br/>' +
          '{series.name}: {point.y} (' +
          '{point.percentage:.0f}%)'
      },
      series: seriesData
    }
  };

  useEffect(() => {
    const option: any = buildOption();
    setOption(option);
  }, []);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={option}
    />
  );
}