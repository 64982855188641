import Layout from "../../../component/Layout";
import "../../../component/popup/styles.scss";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as UserType from "../../../constant/type/UserType";
import { DraftProfile } from "../../../interface";
import { City } from "../../../utils/ProvinceUtil";
import { Image } from "antd";


import {
  AreYouSurePopup,
  AreYouSureWithNoteDropdownPopup,
} from "../../../component/popup";
import { useNavigate } from "react-router-dom";

import "../user/user.css";
import DraftProfileModel from "../../../model/DraftProfileModel";
import DraftProfileStatus from "../../../constant/status/DraftProfileStatus";

const default_avatar = "http://cdn.onlinewebfonts.com/svg/img_264570.png";

let list_resson_user_reject_ctr = [
  "Hình ảnh CMND/CCCD không rõ",
  "Họ tên không trùng khớp với CMND/CCCD",
  "Số CMND/CCCD này đã được sử  dụng bởi một SDT khác, vui lòng liên hệ hotline: “0906997459” để biết thêm chi tiết",
];

function DetailDraftProfilePage() {
  const navigate = useNavigate();
  let { id } = useParams();
  const [isShowConfirmApprovedPopup, setIsShowConfirmApprovedPopup] =
    useState(false);
  const [
    isShowConfirmRejectedWithNotePopup,
    setIsShowConfirmRejectedWithNotePopup,
  ] = useState(false);
  const [draftProfile, setDraftProfile] = useState<DraftProfile>();
  const [isLoading, setIsLoading] = useState(false);

  const fetchDraftProfile = (id: any) => {
    setIsLoading(true);
    DraftProfileModel.get(id)
      .then(resp => {
        if (resp.error === 0) {
          setDraftProfile(resp.data)
        }
      })
      .then(() => {
        setIsLoading(false)
      })

  };

  const updateStatus = (id: any, status: number, note: any) => {
    DraftProfileModel.updateStatus(id, status, note).then((resp) => {
      if (resp.error === 0) {
        navigate(-1);
        fetchDraftProfile(id);
      }
    });
  };


  useEffect(() => {
    fetchDraftProfile(id);
  }, []);

  if (isLoading) {
    return <div></div>;
  }

  const user = draftProfile?.user
  return (
    <Layout>
      {
        <AreYouSurePopup
          open={isShowConfirmApprovedPopup}
          onAgree={() => {
            setIsShowConfirmApprovedPopup(false);
            updateStatus(draftProfile?.id, DraftProfileStatus.APPROVED, null);
          }}
          onCloseModal={() => {
            setIsShowConfirmApprovedPopup(false);
          }}
        />
      }
      {
        <AreYouSureWithNoteDropdownPopup
          list={
            list_resson_user_reject_ctr
          }
          open={isShowConfirmRejectedWithNotePopup}
          onAgree={(note) => {
            setIsShowConfirmRejectedWithNotePopup(false);
            updateStatus(draftProfile?.id, DraftProfileStatus.REJECTED, note);
          }}
          onCloseModal={() => {
            setIsShowConfirmRejectedWithNotePopup(false);
          }}
        />
      }
      <main className="content">
        <div className="container-fluid card">
          {user && (
            <div className="row profile">
              <div className="col-3">
                <div className="profile-sidebar">
                  <div className="profile-userpic">
                    <div className="text-center">
                      <img
                        alt=""
                        src={user.avatar ? user.avatar : default_avatar}
                        className="rounded-circle img-responsive mt-2"
                        width={128}
                        height={128}
                      />
                    </div>
                  </div>
                  <div className="profile-usertitle">
                    <div className="profile-usertitle-name">{user.name}</div>
                    <div className="profile-usertitle-job">
                      {UserType.findName(user.roleId)}
                    </div>
                  </div>
                  <div className="portlet light bordered">
                    <div>
                      <h4 className="profile-desc-title">Tài khoản zalo</h4>
                      <div className="margin-top-20 profile-desc-link">
                        <span
                          className="uppercase profile-desc-title"
                          style={{ fontSize: "14px" }}
                        >
                          ID:
                        </span>{" "}
                        {user.id}
                      </div>
                      <div className="margin-top-20 profile-desc-link">
                        <span
                          className="uppercase profile-desc-title"
                          style={{ fontSize: "14px" }}
                        >
                          Tên Zalo:
                        </span>{" "}
                        {user.name}
                      </div>
                      <div className="margin-top-20 profile-desc-link">
                        <span
                          className="uppercase profile-desc-title"
                          style={{ fontSize: "14px" }}
                        >
                          SDT:
                        </span>{" "}
                        {user.phone}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-8">
                <div className="profile-content">
                  <div className="card box-shadow-none">
                    <div className="card-body">
                      {user && (
                        <form>
                          <div className="row">
                            <div className="col-12">
                              <div className="form-group">
                                <label htmlFor="inputUsername">
                                  Họ và tên
                                </label>
                                <input
                                  disabled
                                  type="text"
                                  className="form-control"
                                  value={user.fullName}
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="inputUsername">
                                  Thành phố / Tỉnh
                                </label>
                                <select
                                  disabled
                                  className="form-control"
                                  value={user.cityId}
                                  onChange={(
                                    e: React.FormEvent<HTMLSelectElement>
                                  ) => {
                                    // setDraftProfile({
                                    //   ...draftProfile,
                                    //   cityId: Number(e.currentTarget.value),
                                    // });
                                  }}
                                >
                                  {(!user.cityId || user.cityId === 0) && (
                                    <option value={0}></option>
                                  )}
                                  {City.getOptions().map((item) => {
                                    return (
                                      <option
                                        key={item.value}
                                        value={item.value}
                                      >
                                        {item.label}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="form-group">
                                <label htmlFor="inputUsername">Địa chỉ</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(
                                    e: React.FormEvent<HTMLInputElement>
                                  ) => {
                                    // setDraftProfile({
                                    //   ...draftProfile,
                                    //   address: e.currentTarget.value,
                                    // });
                                  }}
                                  value={user.address}
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="inputUsername">
                                  Số CMND / CCCD
                                </label>
                                <input
                                  type="text"
                                  onChange={(
                                    e: React.FormEvent<HTMLInputElement>
                                  ) => {
                                    setDraftProfile({
                                      ...draftProfile,
                                      personalCode: e.currentTarget.value,
                                    });
                                  }}
                                  className="form-control"
                                  value={draftProfile.personalCode}
                                />
                              </div>
                              {draftProfile.imageCMND && (
                                <div className="form-group">
                                  <label htmlFor="inputUsername">
                                    Hình ảnh CMND
                                  </label>
                                  <div style={{ width: '100%', textAlign: 'center' }} className="img-center">
                                    <Image
                                      style={{ width: '350px', height: '200px' }}
                                      src={draftProfile.imageCMND}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </form>
                      )}
                    </div>
                    {draftProfile && (
                      <div className="card-footer">
                        {draftProfile.status === DraftProfileStatus.INIT && (
                          <>
                            <button
                              onClick={() => {
                                setIsShowConfirmApprovedPopup(true);
                              }}
                              type="submit"
                              className="btn btn-danger m-btn-danger"
                            >
                              Duyệt
                            </button>
                            <button
                              onClick={() => {
                                setIsShowConfirmRejectedWithNotePopup(true);
                              }}
                              style={{
                                marginLeft: "40px",
                                backgroundColor: "#3e4676",
                              }}
                              type="submit"
                              className="btn btn-danger m-btn-danger"
                            >
                              Từ chối
                            </button>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </main>
    </Layout>
  );
}

export default DetailDraftProfilePage;
