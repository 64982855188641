import Layout from "../../../component/Layout";
import "../../../component/popup/styles.scss";
import { useEffect, useState } from "react";
import { GiftOrder, Page } from "../../../interface";
import * as GiftOrderStatus from "../../../constant/status/GiftOrderStatus";
import GiftOrderModel from "../../../model/GiftOrderModel";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import DateTimeUtil from "../../../utils/DateTimeUtil";
import { useSearchParams } from "react-router-dom";
import { Pagination } from "antd";
import LinkUtil from "../../../utils/LinkUtil";
import {
  UploadFilePopup,
  ConfirmContentPoup,
} from "../../Promotion2022/gift-order/popup/index";
import AlertUtils from "../../../utils/AlertUtils";
import ConfigConstant from "../../../constant/config";
import * as GiftOrderType from "../../../constant/type/GiftOrderType";
interface GiftOrderFilter {
  search: string;
  page: number;
  pageSize: number;
}

const PAGE_SIZE = 10;
function ListContractorGiftOrderPage() {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const [gifts, setGifts] = useState<Page<GiftOrder>>();
  const [filter, setFilter] = useState<GiftOrderFilter>(
    LinkUtil.initParam(searchParams)
  );
  const [is_show_upload_file_popup, set_is_show_upload_file_popup] =
    useState(false);
  const [is_show_confirm_content_popup, set_is_show_confirm_content_popup] =
    useState(false);
  const [parsed_file_content, set_parsed_file_content] = useState();

  const fetchGifts = () => {
    GiftOrderModel.find({
      search: filter.search,
      promotionId: ConfigConstant.CTR_SCAN_QR_FORM_TYPE.promotionId,
      status: null,
      page: filter.page,
      pageSize: PAGE_SIZE,
    }).then((resp) => {
      if (resp.error === 0) {
        setGifts(resp.data);
      }
    });
  };

  const serialize2ParamsUrl = () => {
    searchParams.set("q", JSON.stringify(filter));
    setSearchParams(searchParams);
  };

  const submitFileVoucher = (file) => {
    set_is_show_upload_file_popup(false);
    if (file) {
      GiftOrderModel.uploadGotITVoucher(
        file,
        ConfigConstant.CTR_SCAN_QR_FORM_TYPE.promotionId
      ).then((resp) => {
        if (resp.error < 0) {
          AlertUtils.showError(resp.msg);
        } else {
          set_parsed_file_content(resp.data);
          set_is_show_confirm_content_popup(true);
        }
      });
    }
  };

  const approvedContentFile = (fileName) => {
    set_is_show_confirm_content_popup(false);
    GiftOrderModel.approvalContentFile(
      fileName,
      ConfigConstant.CTR_SCAN_QR_FORM_TYPE.promotionId
    ).then((resp) => {
      if (resp.error < 0) {
        AlertUtils.showError(resp.msg);
      } else {
        fetchGifts();
        AlertUtils.showSuccess("Thành công");
      }
    });
  };

  const export_excel = () => {
    window.location.href =
      process.env.REACT_APP_DOMAIN +
      `/api/gift-order/export-excel?promotionId=${ConfigConstant.CTR_SCAN_QR_FORM_TYPE.promotionId}`;
    return;
  };

  useEffect(() => {
    fetchGifts();
    serialize2ParamsUrl();
  }, [filter]);

  return (
    <Layout>
      <main className="content">
        <div className="container-fluid">
          <div className="header">
            <h1 className="header-title">Đổi quà</h1>
          </div>
          <div className="row">
            <div className="col-12 col-xl-12">
              <div className="card">
                <div className="card-bar" style={{ padding: "10px" }}>
                  <div className="row ">
                    <div className="col-5 col-xl-5 ml-auto flex-end">
                      <input
                        className="form-control mr-3"
                        placeholder="SDT hoặc Tên"
                        type="text"
                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                          setFilter({
                            search: e.currentTarget.value,
                          } as GiftOrderFilter);
                        }}
                      />
                      <Button
                        style={{
                          backgroundColor: "#ff9800",
                          borderColor: "#ff9800",
                          color: "#fff",
                        }}
                        onClick={() => {
                          set_is_show_upload_file_popup(true);
                        }}
                      >
                        Gửi quà
                      </Button>
                      <Button
                        style={{ marginLeft: "15px" }}
                        type="primary"
                        onClick={export_excel}
                      >
                        Xuất Excel
                      </Button>
                    </div>
                  </div>
                </div>
                <table className="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Avatar</th>
                      <th>Thành viên</th>
                      <th>SDT</th>
                      <th>Thời gian</th>
                      <th>Giá trị</th>
                      <th>Status</th>
                      <th>Ngày cập nhập</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {gifts &&
                      gifts.list &&
                      gifts.list.map((giftOrder, key) => {
                        return (
                          <tr key={key}>
                            <td>{giftOrder.id}</td>
                            <td>
                              <img
                                alt=""
                                className="avatar-gift"
                                src={giftOrder.user.avatar}
                              />
                            </td>
                            <td>{giftOrder.user.name}</td>
                            <td>{giftOrder.user.phone}</td>
                            <td>
                              {DateTimeUtil.toString(
                                giftOrder.createdTime * 1000
                              )}
                            </td>
                            <td>
                              {giftOrder?.money.toLocaleString("it-IT", {
                                style: "currency",
                                currency: "VND",
                              })}
                            </td>
                            <td>
                              <span
                                style={{
                                  backgroundColor: GiftOrderStatus.findColor(
                                    giftOrder.status
                                  ),
                                }}
                                className="badge badge-info"
                              >
                                {GiftOrderStatus.findName(giftOrder.status)}
                              </span>
                            </td>
                            <td>
                              {DateTimeUtil.toString(
                                giftOrder.updatedTime * 1000
                              )}
                            </td>
                            <td className="table-action">
                              <Button
                                onClick={() => {
                                  navigate(
                                    `/${ConfigConstant.CTR_SCAN_QR_FORM_TYPE.key}/gift-order/${giftOrder.id}`
                                  );
                                }}
                                type="primary"
                              >
                                Chi tiết
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <div className="cart-paging">
                  {gifts && (
                    <Pagination
                      defaultCurrent={1}
                      current={filter.page + 1}
                      onChange={(pageNumber) => {
                        setFilter({ ...filter, page: pageNumber - 1 });
                      }}
                      total={gifts.totalPage * PAGE_SIZE}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {is_show_upload_file_popup && (
        <UploadFilePopup
          open={is_show_upload_file_popup}
          onCloseModal={() => {
            set_is_show_upload_file_popup(false);
          }}
          onAgree={(file) => {
            submitFileVoucher(file);
          }}
        />
      )}
      {is_show_confirm_content_popup && (
        <ConfirmContentPoup
          data={parsed_file_content}
          open={is_show_confirm_content_popup}
          onCloseModal={() => {
            set_is_show_confirm_content_popup(false);
          }}
          onAgree={(fileName) => {
            approvedContentFile(fileName);
          }}
        />
      )}
    </Layout>
  );
}

export default ListContractorGiftOrderPage;
