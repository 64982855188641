import APIUtil from "../utils/APIUtils";

const domain = process.env.REACT_APP_DOMAIN;

export default class TransactionWarehouseModel {

    static create(form) {
        return new Promise((resolve, reject) => {
            APIUtil.postJSONWithCredentials(
                domain +
                `/api/transaction-warehouse/create`,
                JSON.stringify(form),
                resolve,
                reject
            );
        });
    }

    static find(warehouseId) {
        return new Promise((resolve, reject) => {
            APIUtil.getJSONWithCredentials(domain + `/api/transaction-warehouse/find?warehouseId=${warehouseId}&pageSize=1000`, resolve, reject);
        });
    }


    static upload(data) {
        const formData = new FormData();
        formData.append("file", data);
        return new Promise((resolve, reject) => {
            APIUtil.uploadFile(process.env.REACT_APP_DOMAIN + `/api/transaction-warehouse/upload`, formData, resolve, reject);
        });
    }

}