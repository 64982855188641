import APIUtil from "../utils/APIUtils";

const domain = process.env.REACT_APP_DOMAIN;
// const domain = "http://localhost:9435"

export default class MatchModel {

    static find(page, pageSize) {
        var param = Object.fromEntries(
            Object.entries({
                page,
                pageSize,
            }).filter(([_, v]) => v != null && v != 0)
        );

        return new Promise((resolve, reject) => {
            APIUtil.getJSONWithCredentials(
                domain +
                `/api/match-football/find?${new URLSearchParams(param).toString()}`,
                resolve,
                reject
            );
        });
    }

    static get(matchId) {

        return new Promise((resolve, reject) => {
            APIUtil.getJSONWithCredentials(
                domain +
                `/api/match-football/get?id=${matchId}`,
                resolve,
                reject
            );
        });
    }

    static create(host, guest, timeStart) {
        return new Promise((resolve, reject) => {
            APIUtil.postJSONWithCredentials(
                domain +
                `/api/match-football/create`,
                JSON.stringify({ host, guest, timeStart }),
                resolve,
                reject
            );
        });
    }

    static approvedMatch(matchId) {
        return new Promise((resolve, reject) => {
            APIUtil.postJSONWithCredentials(
                domain +
                `/api/match-football/approved-match?matchId=${matchId}`,
                JSON.stringify({}),
                resolve,
                reject
            );
        });
    }

    static updateResult(matchId, goalHost, goalGuest) {
        return new Promise((resolve, reject) => {
            APIUtil.postJSONWithCredentials(
                domain +
                `/api/match-football/update-result?id=${matchId}`,
                JSON.stringify({ goalHost, goalGuest }),
                resolve,
                reject
            );
        });
    }

    static createBroadcast(matchId, promotionId, time) {
        return new Promise((resolve, reject) => {
            APIUtil.getJSONWithCredentials(
                domain +
                `/api/match-football/broadcast?matchId=${matchId}&promotionId=${promotionId}&time=${time}`,
                resolve,
                reject
            );
        });
    }
}