import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import "./styles.scss";
import { Input, Radio, Space } from "antd";
import DistributorModel from "../../model/DistributorModel";
import UserModel from "../../model/UserModel";
import AlertUtils from "../../utils/AlertUtils";

const owlClass = "popup";

export function AreYouSurePopup({ open, onCloseModal, onAgree }) {
  return (
    <Modal
      open={open}
      onClose={onCloseModal}
      center
      showCloseIcon={false}
      styles={{
        modal: {
          background: "rgba(242, 242, 242, 0.94)",
          backdropFilter: "blur(54.3656px)",
          borderRadius: "14px",
          padding: "0",
          maxWidth: "80%",
        },
      }}
    >
      <div className={owlClass} style={{ minWidth: "300px" }}>
        <div className={`${owlClass}__wrapper`}>
          <div className={`${owlClass}__wrapper__title`}>Anh/chị có chắc?</div>
        </div>
        <div className={`${owlClass}__group-btn`}>
          <div
            className={`${owlClass}__group-btn__item left`}
            onClick={onCloseModal}
          >
            Không
          </div>
          <div
            className={`${owlClass}__group-btn__item right`}
            onClick={onAgree}
          >
            Có
          </div>
        </div>
      </div>
    </Modal>
  );
}

export function AreYouSureWithNotePopup({ open, onCloseModal, onAgree }) {
  const [note, setNote] = useState(null);
  return (
    <Modal
      open={open}
      onClose={onCloseModal}
      center
      showCloseIcon={false}
      styles={{
        modal: {
          background: "rgba(242, 242, 242, 0.94)",
          backdropFilter: "blur(54.3656px)",
          borderRadius: "14px",
          padding: "0",
          maxWidth: "80%",
        },
      }}
    >
      <div className={owlClass} style={{ minWidth: "500px" }}>
        <div className={`${owlClass}__wrapper`}>
          <div className={`${owlClass}__wrapper__title`}>Anh/chị có chắc?</div>
        </div>
        <div style={{ padding: "0px 0px 30px 0" }} className="card-body">
          <div className="container-fluid">
            <label>Lý do:</label>
            <textarea
              onChange={(e) => setNote(e.target.value)}
              className="form-control"
              rows={2}
            ></textarea>
          </div>
        </div>
        <div className={`${owlClass}__group-btn`}>
          <div
            className={`${owlClass}__group-btn__item left`}
            onClick={onCloseModal}
          >
            Không
          </div>
          <div
            className={`${owlClass}__group-btn__item right`}
            onClick={() => {
              onAgree(note);
            }}
          >
            Có
          </div>
        </div>
      </div>
    </Modal>
  );
}

export function ConfirmPhonePopup({
  phone,
  storeName,
  open,
  onCloseModal,
  onAgree,
}) {
  return (
    <Modal
      open={open}
      onClose={onCloseModal}
      center
      showCloseIcon={false}
      styles={{
        modal: {
          background: "rgba(242, 242, 242, 0.94)",
          backdropFilter: "blur(54.3656px)",
          borderRadius: "14px",
          padding: "0",
          maxWidth: "80%",
        },
      }}
    >
      <div className={owlClass}>
        <div className={`${owlClass}__wrapper`}>
          <div className={`${owlClass}__wrapper__title`}>Anh/chị có chắc?</div>
          <p className={`${owlClass}__wrapper__desc`}>
            Số điện thoại ({phone}) là của cửa hàng {storeName} ?
          </p>
        </div>
        <div className={`${owlClass}__group-btn`}>
          <div
            className={`${owlClass}__group-btn__item left`}
            onClick={onCloseModal}
          >
            Không
          </div>
          <div
            className={`${owlClass}__group-btn__item right`}
            onClick={onAgree}
          >
            Có
          </div>
        </div>
      </div>
    </Modal>
  );
}

export function ErrorPopup({ open, onCloseModal }) {
  return (
    <Modal
      open={open}
      onClose={onCloseModal}
      center
      showCloseIcon={false}
      styles={{
        modal: {
          background: "rgba(242, 242, 242, 0.94)",
          backdropFilter: "blur(54.3656px)",
          borderRadius: "14px",
          padding: "0",
          maxWidth: "80%",
        },
      }}
    >
      <div className={owlClass}>
        <div className={`${owlClass}__wrapper`}>
          <div className={`${owlClass}__wrapper__title`}>Lỗi</div>
          <p className={`${owlClass}__wrapper__desc`}>
            Anh/chị vui lòng thử lại sau
          </p>
        </div>
        <div className={`${owlClass}__group-btn`}>
          <div
            className={`${owlClass}__group-btn__item right`}
            onClick={onCloseModal}
          >
            OK
          </div>
        </div>
      </div>
    </Modal>
  );
}

export function AreYouSureWithNoteDropdownPopup({
  list,
  open,
  onCloseModal,
  onAgree,
}) {
  const [note, setNote] = useState(list[0]);
  const [is_other, set_is_other] = useState(false);
  const onChange = (e) => {
    set_is_other(e.target.value == 0)
    setNote(e.target.value);
  };
  return (
    <Modal
      open={open}
      onClose={onCloseModal}
      center
      showCloseIcon={false}
      styles={{
        modal: {
          background: "rgba(242, 242, 242, 0.94)",
          backdropFilter: "blur(54.3656px)",
          borderRadius: "14px",
          padding: "0",
          maxWidth: "80%",
        },
      }}
    >
      <div className={owlClass} style={{ minWidth: "500px" }}>
        <div className={`${owlClass}__wrapper`}>
          <div className={`${owlClass}__wrapper__title`}>Anh/chị có chắc?</div>
        </div>
        <div style={{ padding: "0px 0px 30px 0" }} className="card-body">
          <div className="container-fluid">
            <label>Lý do:</label>
          </div>
          <div className="container-fluid">
            <Radio.Group onChange={onChange} value={note}>
              <Space direction="vertical">
                {list.map((item, index) => (
                  <Radio key={index} value={item}>
                    {item}
                  </Radio>
                ))}
                <Radio value={0}>Khác...</Radio>
              </Space>
            </Radio.Group>
            {note == 0 && (
              <textarea
                onChange={(e) => { set_is_other(e.target.value); }}
                className="form-control"
                rows={2}
              ></textarea>
            )}
          </div>
        </div>
        <div className={`${owlClass}__group-btn`}>
          <div
            className={`${owlClass}__group-btn__item left`}
            onClick={onCloseModal}
          >
            Không
          </div>
          <div
            className={`${owlClass}__group-btn__item right`}
            onClick={() => {
              onAgree(note == 0 ? is_other : note);
            }}
          >
            Có
          </div>
        </div>
      </div>
    </Modal>
  );
}


export function ShowOnFormPopup({
  userId,
  open,
  onCloseModal,
  onAgree,
}) {
  const [distributors, set_distributors] = useState()
  const [form, set_form] = useState({ uid: userId })

  useEffect(() => {
    DistributorModel.find(true)
      .then(resp => {
        set_distributors(resp.data)
      })
  }, [])

  const submit = () => {
    if (!form.distributorId || form.distributorId <= 0) {
      AlertUtils.showError("Vui lòng chọn tên chính thức")
      return;
    }

    UserModel.showOnForm(form)
      .then(resp => {
        if (resp.error < 0) {
          AlertUtils.showError(resp.msg);
          return;
        } else {
          AlertUtils.showSuccess("Cập nhật thành công");
          onAgree()
        }
      })
  }

  return (
    <Modal
      open={open}
      onClose={onCloseModal}
      center
      showCloseIcon={false}
      styles={{
        modal: {
          background: "rgba(242, 242, 242, 0.94)",
          backdropFilter: "blur(54.3656px)",
          borderRadius: "14px",
          padding: "0",
          maxWidth: "80%",
        },
      }}
    >
      <div className={owlClass} style={{ minWidth: "500px" }}>
        <div className={`${owlClass}__wrapper`}>
          <div className={`${owlClass}__wrapper__title`}>Anh/chị có chắc?</div>
        </div>
        <div style={{ padding: "0px 0px 30px 0" }} className="card-body">
          <div className="container-fluid">
            <label>Tên chính thức hiển thị ở trên form:</label>
          </div>
          <div className="container-fluid">
            <select value={form.distributorId | 0} onChange={(event) => {
              let distributorId = event.target.value;
              let distributor = distributors && distributors.filter(e => e.id == distributorId)[0];
              set_form({
                ...form, distributorId: distributorId, officialName: distributor.name
              })
            }} className="custom-select mb-3">
              <option value={0}>Chọn tên nhà phân phối</option>
              {distributors && distributors.map((distributor, index) => {
                return (
                  <option value={distributor.id}>{distributor.name}</option>
                )
              })}
            </select>
          </div>
        </div>
        <div className={`${owlClass}__group-btn`}>
          <div
            className={`${owlClass}__group-btn__item left`}
            onClick={onCloseModal}
          >
            Không
          </div>
          <div
            className={`${owlClass}__group-btn__item right`}
            onClick={() => {
              submit()
            }}
          >
            Có
          </div>
        </div>
      </div>
    </Modal>
  );
}
