import Layout from "../../../component/Layout";
import "react-responsive-modal/styles.css";
import "../../../component/popup/styles.scss";
import { useEffect, useState } from "react";
import { DraftProfile, GotitVoucher, Page } from "../../../interface";
import * as GotitVoucherStatus from "../../../constant/status/GotitVoucherStatus";
import { Button } from "antd";
import DateTimeUtil from "../../../utils/DateTimeUtil";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Pagination } from "antd";
import LinkUtil from "../../../utils/LinkUtil";
import AlertUtils from "../../../utils/AlertUtils";
import GotitVoucherModel from "../../../model/GotitVoucherModel";
import { UploadFilePopup } from "../../Promotion2023/gift-order/popup";
import DraftProfileModel from "../../../model/DraftProfileModel";
import { City } from "../../../utils/ProvinceUtil";
import DraftProfileStatus from "../../../constant/status/DraftProfileStatus";

const default_avatar = "http://cdn.onlinewebfonts.com/svg/img_264570.png";

interface DraftProfileFilter {
  page: number;
  pageSize: number;
}

const PAGE_SIZE = 10;
function ListDraftProfilePage(props: any) {
  let [searchParams, setSearchParams] = useSearchParams();
  const [isUploadPopup, setIsUploadPopup] = useState(false);
  const [draftProfiles, setDraftProfiles] = useState<Page<DraftProfile>>();
  const [filter, setFilter] = useState<DraftProfileFilter>(
    LinkUtil.initParam(searchParams)
  );
  const navigate = useNavigate();

  const fetchVoucher = () => {
    DraftProfileModel.find({
      page: filter.page,
      pageSize: PAGE_SIZE,
    }).then((resp) => {
      setDraftProfiles(resp.data);
    });
  };

  const serialize2ParamsUrl = () => {
    searchParams.set("q", JSON.stringify(filter));
    setSearchParams(searchParams);
  };

  const submitUploadVoucher = (file) => {
    setIsUploadPopup(false);
    if (file) {
      GotitVoucherModel.upload(file).then((resp) => {
        if (resp.error < 0) {
          AlertUtils.showError(resp.msg);
        } else {
          AlertUtils.showSuccess("Upload thành công");
          fetchVoucher();
        }
      });
    }
  };

  const export_excel = () => {
    window.location.href =
      process.env.REACT_APP_DOMAIN + `/api/draft-profile/export-excel`;
    return;
  };

  useEffect(() => {
    fetchVoucher();
    serialize2ParamsUrl();
  }, [filter]);

  return (
    <Layout>
      <main className="content">
        <div className="container-fluid">
          <div className="header">
            <h1 className="header-title">Danh sách cập nhật CMND/CCCD</h1>
          </div>
          <div className="row">
            <div className="col-12 col-xl-12">
              <div className="card">
                <div className="card-bar" style={{ padding: "10px" }}>
                  <div className="row flex-end">
                    <div className="mr-3">
                      <Button type="primary" onClick={export_excel}>
                        Xuất Excel
                      </Button>
                    </div>
                  </div>
                </div>
                <table className="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Avatar</th>
                      <th>Họ và tên</th>
                      <th>Tỉnh thành</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {draftProfiles &&
                      draftProfiles.list &&
                      draftProfiles.list.map((draftProfile, key) => {
                        return (
                          <tr key={key}>
                            <td>{draftProfile.id}</td>
                            <td>
                              <img
                                className="m-avatar"
                                alt=""
                                src={
                                  draftProfile.user.avatar
                                    ? draftProfile.user.avatar
                                    : default_avatar
                                }
                              />
                            </td>
                            <td>{draftProfile.user.fullName}</td>
                            <td>{City.getName(draftProfile.user.cityId)}</td>
                            <td>
                              <span
                                style={{
                                  backgroundColor: DraftProfileStatus.findColor(
                                    draftProfile.status
                                  ),
                                }}
                                className="badge badge-info"
                              >
                                {DraftProfileStatus.findName(
                                  draftProfile.status
                                )}
                              </span>
                            </td>
                            <td className="table-action">
                              <Button
                                onClick={() => {
                                  navigate(
                                    `/${props.config.key}/draft-profile/${draftProfile.id}`
                                  );
                                }}
                                type="primary"
                              >
                                Chi tiết
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <div className="cart-paging">
                  {draftProfiles && (
                    <Pagination
                      defaultCurrent={1}
                      current={filter.page + 1}
                      onChange={(pageNumber) => {
                        setFilter({ ...filter, page: pageNumber - 1 });
                      }}
                      total={draftProfiles.totalPage * PAGE_SIZE}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {isUploadPopup && (
        <UploadFilePopup
          open={isUploadPopup}
          onCloseModal={() => {
            setIsUploadPopup(false);
          }}
          onAgree={(file) => {
            submitUploadVoucher(file);
          }}
        />
      )}
    </Layout>
  );
}

export default ListDraftProfilePage;
