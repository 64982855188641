export const INIT = 1;
export const DISTRIBUTED = 2;

export function findName(value) {
    switch (value) {
        case INIT: return 'AVAILABELE'
        case DISTRIBUTED: return 'DISTRIBUTED'
    }
}

export function findColor(value) {
    switch (value) {
        case INIT: return '#ff5722'
        case DISTRIBUTED: return '#20c997'
    }
}