import { Select } from "antd";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import DistributorModel from "../../model/DistributorModel";

interface SelectDistributorProps {
  value: number;
  disabled: boolean
  onChange: (value: number) => void;
}

export const SelectDistributor: FunctionComponent<SelectDistributorProps> = ({
  value,
  onChange,
  disabled = false
}) => {
  const [distributors, set_distributors] = useState<Array<any>>();
  useMemo(() => {
    DistributorModel.find().then((resp) => {
      let tmp = resp.data.sort((a, b) =>
        a.nameStore > b.nameStore ? 1 : b.nameStore > a.nameStore ? -1 : 0
      );
      set_distributors(tmp);
    });
  }, []);

  const options =
    distributors &&
    distributors.map((distributor) => {
      return {
        value: distributor.id,
        label: distributor.nameStore,
      };
    });

  return (
    <Select
      className="form-control"
      showSearch
      disabled={disabled}
      style={{ border: "none", padding: 0 }}
      // defaultValue={value}
      value={value}
      loading={!distributors ? true : false}
      options={options}
      filterOption={(input, option) => (option?.label ?? "").includes(input)}
      onChange={onChange}
    />
  );
};
