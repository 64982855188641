import APIUtil from "../utils/APIUtils";

const domain = process.env.REACT_APP_DOMAIN;
// const domain = "http://localhost:9435"

export default class CountryModel {

    static find(page, pageSize) {
        var param = Object.fromEntries(
            Object.entries({
                page,
                pageSize,
            }).filter(([_, v]) => v != null && v != 0)
        );

        return new Promise((resolve, reject) => {
            APIUtil.getJSONWithCredentials(
                domain +
                `/api/country/find?${new URLSearchParams(param).toString()}`,
                resolve,
                reject
            );
        });
    }
}