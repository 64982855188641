import { Image } from "antd";
import { useMemo, useState } from "react";

interface LinkImageProps {
    image: string,
    name?: string
}


export function LinkImage({ image, name }: LinkImageProps) {
    const [visible, setVisible] = useState(false);
    const nameLink = useMemo(() => {
        if (!name) {
            return image && image.substring(image.lastIndexOf("/") + 1);
        }
        return name;
    }, [image, name]);

    return <>
        <span style={styleLink} onClick={() => {
            setVisible(true)
        }} className="link-img">{nameLink}</span>
        <Image
            style={{ display: 'none' }}
            src={image}
            preview={{
                visible,
                onVisibleChange: (value) => {
                    setVisible(value);
                },
            }}
        />
    </>
}

interface LinkImagesProps {
    images: Array<string>
}

export function LinkImages({ images }: LinkImagesProps) {
    return (
        <>
            {images && images.map((image, index) => {
                return <LinkImage name={`hình_${index + 1}.png`} key={index} image={image}/>
            })}
        </>
    )
}

const styleLink = {
    fontStyle: 'italic',
    color: '#096dd9',
    fontWeight: 600,
    cursor: 'pointer',
    paddingRight: '10px'
}