/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Layout from "../../../component/Layout";
import "react-responsive-modal/styles.css";
import { useEffect, useState } from "react";
import { Page } from "../../../interface";
import { useSearchParams } from "react-router-dom";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { Pagination } from "antd";
import NumberUtils from "../../../utils/NumberUtils";
import LinkUtil from "../../../utils/LinkUtil";
import { FormFilter } from "../../../interface/index";
import ConfigConstant from "../../../constant/config";
import WarehouseModel from "../../../model/WarehouseModel";
import { UploadFileWarehousePopup } from "./popup";
import TransactionWarehouseModel from "../../../model/TransactionWarehouseModel";
import AlertUtils from "../../../utils/AlertUtils";

const default_avatar = "http://cdn.onlinewebfonts.com/svg/img_264570.png";
const PAGE_SIZE = 10;

function ListWarehousePromotion2023H2Page() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [warehouse, set_warehouse] = useState<Page<any>>();
  const [isUploadPopup, setIsUploadPopup] = useState(false);
  const [filter, setFilter] = useState<FormFilter>(
    LinkUtil.initParamWithDefault(searchParams, {
      promotionId: ConfigConstant.INVOICE_PROMOTION_2023_H2.promotionId,
    })
  );

  const fetchWarehouse = () => {
    WarehouseModel.find(
      filter.search,
      filter.promotionId,
      filter.page,
      PAGE_SIZE
    ).then((resp) => {
      if (resp.error === 0) {
        set_warehouse(resp.data);
      }
    });
  };

  const submitUploadVoucher = (file) => {
    setIsUploadPopup(false);
    if (file) {
      TransactionWarehouseModel.upload(file).then((resp) => {
        if (resp.error < 0) {
          AlertUtils.showError(resp.msg);
        } else {
          AlertUtils.showSuccess("Upload thành công");
          fetchWarehouse();
        }
      });
    }
  };

  const serialize2Url = () => {
    var q = JSON.stringify(filter);
    if (q !== "{}") {
      searchParams.set("q", q);
      setSearchParams(searchParams);
    }
  };

  const parseParams = () => {
    var param = searchParams.get("q");
    if (param) {
      setFilter(JSON.parse(param));
    }
  };

  const export_excel = () => {
    let { promotionId } = filter;
    let param = Object.fromEntries(
      Object.entries({
        promotionId,
      }).filter(([_, v]) => v != null)
    ) as any;
    window.location.href =
      process.env.REACT_APP_DOMAIN +
      `/api/warehouse/export-excel?${new URLSearchParams(param).toString()}`;
    return;
  };

  useEffect(() => {
    parseParams();
  }, []);

  useEffect(() => {
    fetchWarehouse();
    serialize2Url();
  }, [filter]);

  return (
    <Layout>
      <main className="content">
        <div className="container-fluid">
          <div className="header">
            <h1 className="header-title">Danh sách kho</h1>
          </div>
          <div className="row">
            <div className="col-12 col-xl-12">
              <div className="card">
                <div className="card-bar" style={{ padding: "10px" }}>
                  <div className="row ">
                    <div className="col-4 ml-auto flex-end">
                      <input
                        className="form-control mr-3"
                        placeholder="SDT hoặc Tên"
                        type="text"
                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                          setFilter({
                            ...filter,
                            search: e.currentTarget.value,
                          } as FormFilter);
                        }}
                      />
                      <Button
                        type="primary"
                        className="mr-3"
                        onClick={() => {
                          navigate(
                            `/${ConfigConstant.INVOICE_PROMOTION_2023_H2.key}/warehouse/update`
                          );
                        }}
                      >
                        Cập nhật
                      </Button>
                      <Button
                        style={{
                          backgroundColor: "#ff9800",
                          borderColor: "#ff9800",
                          color: "#fff",
                        }}
                        onClick={() => {
                          setIsUploadPopup(true);
                        }}
                      >
                        Upload kho
                      </Button>
                    </div>
                    <div className="mr-3">
                      <Button type="primary" onClick={export_excel}>
                        Xuất Excel
                      </Button>
                    </div>
                  </div>
                </div>
                <table className="table">
                  <thead>
                    <tr>
                      <th></th>
                      <th>SDT</th>
                      <th>Cửa hàng / NPP</th>
                      <th>Số tấm</th>
                      <th>Số khung</th>
                      <th>Số bột</th>
                      <th>Số Tấm Mini 8mm</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {warehouse &&
                      warehouse.list &&
                      warehouse.list.map((item, key) => {
                        return (
                          <tr key={key}>
                            <td>
                              <img
                                className="m-avatar"
                                alt=""
                                src={
                                  item.user.avatar
                                    ? item.user.avatar
                                    : default_avatar
                                }
                              />
                            </td>
                            <td>{item.user.phone}</td>
                            <td>{item.user.nameStore}</td>
                            <td> {NumberUtils.formatNumber(item.plate)}</td>
                            <td> {NumberUtils.formatNumber(item.frame)}</td>
                            <td> {NumberUtils.formatNumber(item.compound)}</td>
                            <td> {NumberUtils.formatNumber(item.mini8Plate)}</td>
                            <td className="table-action">
                              <Button
                                onClick={() => {
                                  navigate(
                                    `/${ConfigConstant.INVOICE_PROMOTION_2023_H2.key}/warehouse/${item.user.id}`
                                  );
                                }}
                                type="primary"
                              >
                                Chi tiết
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <div className="cart-paging">
                  {warehouse && (
                    <Pagination
                      defaultCurrent={1}
                      current={filter.page + 1}
                      onChange={(pageNumber) => {
                        setFilter({ ...filter, page: pageNumber - 1 });
                      }}
                      total={warehouse.totalPage * PAGE_SIZE}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {isUploadPopup && (
        <UploadFileWarehousePopup
          open={isUploadPopup}
          onCloseModal={() => {
            setIsUploadPopup(false);
          }}
          onAgree={(file) => {
            submitUploadVoucher(file);
          }}
        />
      )}
    </Layout>
  );
}

export default ListWarehousePromotion2023H2Page;
