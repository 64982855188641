import Modal from "react-responsive-modal";
import "../../../component/popup/styles.scss";
import { useEffect, useState } from "react";
import { AllForm, ImgForm, RtlEp01Form } from "../../../interface";
import {
  AreYouSurePopup,
  AreYouSureWithNoteDropdownPopup,
  AreYouSureWithNotePopup,
} from "../../../component/popup";
import FormModel from "../../../model/FormModel";
import AlertUtils from "../../../utils/AlertUtils";
import * as StockFormStatus from "../../../constant/status/FormStatus";
import Layout from "../../../component/Layout";
import { useParams, useNavigate } from "react-router-dom";
import { Button, DatePicker } from "antd";
import moment from "moment";
import { Image } from "antd";
import QrModel from "../../../model/QrModel";

function DetailScanQRFormPage() {
  let { id } = useParams();
  const navigate = useNavigate();

  const [isShowConfirmPopup, setIsShowConfirmPopup] = useState(false);
  const [form, setForm] = useState<AllForm>();
  const [originForm, setOriginForm] = useState<AllForm>();
  const [selectedCode, setSelectedCode] = useState<string>("");

  const fetchForm = () => {
    FormModel.get(id).then((resp) => {
      if (resp.error === 0) {
        setForm(resp.data);
        setOriginForm(resp.data);
      }
    });
  };

  const removeQrCode = () => {
    setIsShowConfirmPopup(false);
    console.log(selectedCode);
    QrModel.removeQrCodeInForm(selectedCode).then(() => {
      fetchForm();
    });
  };

  const _isNeedSave = () => {
    return JSON.stringify(originForm) != JSON.stringify(form);
  };

  useEffect(() => {
    fetchForm();
  }, []);

  return (
    <Layout>
      <main className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-xl-12">
              <div className="tab-content">
                <div
                  className="tab-pane fade show active"
                  id="account"
                  role="tabpanel"
                >
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title mb-0">Chi tiết</h5>
                    </div>
                    <div className="card-body">
                      {form && (
                        <div className="row">
                          <div className="col-md-5">
                            <div className="form-group">
                              <label>Tên Zalo</label>
                              <input
                                type="text"
                                className="form-control"
                                value={form.user.name}
                              />
                            </div>
                            <div className="form-group">
                              <label>Họ và tên</label>
                              <input
                                type="text"
                                className="form-control"
                                value={form.user.fullName}
                              />
                            </div>
                            <div className="form-group">
                              <label>Số điện thoại</label>
                              <input
                                type="text"
                                className="form-control"
                                value={form.user.phone}
                              />
                            </div>
                            <div className="form-group">
                              <label>Số điểm</label>
                              <input
                                type="text"
                                className="form-control"
                                value={form.point}
                              />
                            </div>
                            <div className="form-group">
                              <label>Điểm</label>
                              <input
                                type="text"
                                className="form-control"
                                value={form.point}
                              />
                            </div>
                          </div>
                          <div className="col-md-7">
                            <h5>Danh sách QR trong đơn hàng</h5>
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>ID</th>
                                  <th>Code</th>
                                  <th>Số điểm</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {form.codes.map((item, index) => (
                                  <tr key={item}>
                                    <td>{index + 1}</td>
                                    <td>{item}</td>
                                    <td>1</td>

                                    <td className="table-action">
                                      <Button
                                        onClick={() => {
                                          setIsShowConfirmPopup(true);
                                          setSelectedCode(item);
                                        }}
                                        type="primary"
                                      >
                                        Gỡ QR code
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )}
                      {isShowConfirmPopup && (
                        <AreYouSurePopup
                          open={() => setIsShowConfirmPopup(true)}
                          onCloseModal={() => setIsShowConfirmPopup(false)}
                          onAgree={removeQrCode}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}

export default DetailScanQRFormPage;

const owlClass = "popup";
export function ImagePopup({ open, onCloseModal, url, time, location }: any) {
  return (
    <Modal
      open={open}
      onClose={onCloseModal}
      center
      showCloseIcon={false}
      styles={{
        modal: {
          background: "rgba(242, 242, 242, 0.94)",
          backdropFilter: "blur(54.3656px)",
          borderRadius: "14px",
          padding: "0",
          maxWidth: "80%",
        },
      }}
    >
      <div className={owlClass}>
        <div className={`${owlClass}__wrapper`}>
          <img
            style={{ maxHeight: "70vh", maxWidth: "70vw" }}
            src={url}
            alt=""
          />
        </div>
        <div className={`${owlClass}__group-btn`}>
          <div
            className={`${owlClass}__group-btn__item right`}
            onClick={onCloseModal}
          >
            Đóng
          </div>
        </div>
      </div>
    </Modal>
  );
}
