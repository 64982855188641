export default class PredictMatchFootballStatus {
    static WAITING = 1;
    static CORRECT_TEAM = 2;
    static WRONG = 3;

    static findName(value) {
        switch (value) {
            case this.WAITING: return 'WAITING'
            case this.CORRECT_TEAM: return 'CORRECT_TEAM'
            case this.WRONG: return 'WRONG'
        }
    }

    static findColor(value) {
        switch (value) {
            case this.WAITING: return '#999'
            case this.WRONG: return '#dc3545'
            case this.CORRECT_TEAM: return '#20c997'
        }
    }
}