import { useMemo } from "react"
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as TypeTransactionWarehouse from '../../../../constant/type/TypeTransactionWarehouse'
import DateTimeUtil from "../../../../utils/DateTimeUtil";

const useExportUserTransaction = () => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const excute = (csvData, userId) => {
        csvData = csvData && csvData.map((item) => {

            let actor;
            if (!item.form) {
                actor = {
                    avatar: "https://s160-ava-talk.zadn.vn/2/0/c/0/1/160/ed961026aa0cd1557f142d600a52c8cb.jpg",
                    name: "Admin Knauf"
                }
            } else {
                let form = item.form;
                if (form && item && userId == form.userId) {
                    actor = {
                        avatar: form.distributor.avatar,
                        name: form.distributor.nameStore
                    }
                }
                if (form && item && userId == form.distributorId) {
                    actor = {
                        avatar: form.user.avatar,
                        name: form.user.nameStore
                    }
                }
            }

         
            return {
                "ID": item.id,
                "Thời gian": DateTimeUtil.toString(
                    item.createdTime * 1000
                ),
                "Thành viên": actor.name,
                "Số tấm": item.plate,
                "Số khung": item.frame,
                "Số bột": item.compound,
                "Số tấm mini 8": item.mini8Plate,
                "Loại": TypeTransactionWarehouse.getName(item.type),
                "Note": item.note
            }
        })
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, new Date().getTime() + fileExtension);
    }
    return useMemo(() => ({ excute }), [])
}

export default useExportUserTransaction