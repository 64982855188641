import "../../../component/popup/styles.scss";
import { useEffect, useState } from "react";

import Layout from "../../../component/Layout";
import { useParams, useNavigate } from "react-router-dom";
import { DatePicker } from "antd";
import moment from "moment";
import CountryModel from "../../../model/CountryModel";
import MatchModel from "../../../model/MatchModel";
import ConfigConstant from "../../../constant/config";
import AlertUtils from "../../../utils/AlertUtils";

function CreateMatch() {
  const navigate = useNavigate();
  const [start_time, set_start_time] = useState(moment());
  const [country, setCountry] = useState<any>([]);
  const [form, setForm] = useState<any>({
    timeStart: start_time.toDate().getTime(),
  });

  const getCountry = () => {
    CountryModel.find(0, 100).then((res) => {
      setCountry(res.data.list);
    });
  };

  const changeCalendar = (data) => {
    set_start_time(data);
    setForm({ ...form, timeStart: data.toDate().getTime() });
  };

  const createMatch = () => {
    if (form.host == 0 || form.guest == 0) {
      AlertUtils.showError("Vui lòng chọn đội bóng");
      return;
    }
    MatchModel.create(form.host, form.guest, form.timeStart).then((res) => {
      if (res.error === 0) {
        navigate(`/${ConfigConstant.PREDICT_FOOTBALL.key}/match/list`);
      }
    });
  };

  useEffect(() => {
    getCountry();
  }, []);

  return (
    <Layout>
      <main className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-xl-12">
              <div className="tab-content">
                <div
                  className="tab-pane fade show active"
                  id="account"
                  role="tabpanel"
                >
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title mb-0">Tạo Trận đấu mới</h5>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-6">
                          <div className="form-group">
                            <label>Đội chủ nhà</label>
                            <select
                              id="host"
                              className="form-control"
                              value={form?.host}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  host: Number(e.target.value),
                                })
                              }
                            >
                              <option value={0}>Chọn ...</option>
                              {country.map((item) => (
                                <option value={item.id} key={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="form-group">
                            <label>Đội Khách</label>
                            <select
                              id="guest"
                              className="form-control"
                              value={form?.guest}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  guest: Number(e.target.value),
                                })
                              }
                            >
                              <option value={0}>Chọn ...</option>
                              {country.map((item) => (
                                <option value={item.id} key={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="form-group">
                            <label style={{ width: "100%" }}>
                              Thời gian thi đấu
                            </label>
                            <DatePicker
                              showTime={{ format: "HH:mm" }}
                              value={moment(start_time)}
                              onChange={changeCalendar}
                              format={"hh : mm - DD/MM/YYYY"}
                            />
                          </div>
                          <button
                            className="btn btn-primary m-btn-primary"
                            style={{ float: "right" }}
                            onClick={createMatch}
                          >
                            Tạo trận đấu
                          </button>
                        </div>
                        <div className="col-6"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}

export default CreateMatch;
