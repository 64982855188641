import APIUtil from "../utils/APIUtils";

export default class QrModel {

    static find(search, status, page, pageSize) {
        var param = Object.fromEntries(
            Object.entries({
                search,
                status,
                page,
                pageSize,
            }).filter(([_, v]) => v != null && v != 0)
        );

        return new Promise((resolve, reject) => {
            APIUtil.getJSONWithCredentials(
                process.env.REACT_APP_DOMAIN +
                `/api/qr-code/find?${new URLSearchParams(param).toString()}`,
                resolve,
                reject
            );
        });
    }

    static removeQrCodeInForm(code) {
        var param = Object.fromEntries(
            Object.entries({
                code
            }).filter(([_, v]) => v != null && v != 0)
        );

        return new Promise((resolve, reject) => {
            APIUtil.getJSONWithCredentials(
                process.env.REACT_APP_DOMAIN +
                `/api/qr-code/remove-qr-scan-form?${new URLSearchParams(param).toString()}`,
                resolve,
                reject
            );
        });
    }
}