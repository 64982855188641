import axios from "axios";


const http = axios.create({
  headers: {
    "Content-type": "application/json",
  },
  withCredentials: true,
});




export default class APIUtils {
  static getJSONWithCredentials(url, success, error) {
    http.get(url).then(resp => {
      success(resp.data);
    })
      .catch(ex => {
        listen_redirect_login(error)
        error(ex);
      })
  }


  static postJSONWithCredentials(url, formData, success, error) {
    http.post(url, formData).then(resp => {
      success(resp.data);
    })
      .catch(ex => {
        listen_redirect_login(error)
        error(ex);
      })
  }


  static uploadFile(url, formData, success, error) {
    axios({
      method: 'post',
      url: url,
      data: formData,
      withCredentials: true
    }).then(resp => {
      success(resp.data);
    })
      .catch(ex => {
        listen_redirect_login(error)
        error(ex);
      })
  }
}

function listen_redirect_login(error) {
  window.location.href = `${process.env.REACT_APP_DOMAIN}/login`
}