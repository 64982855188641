import APIUtil from "../utils/APIUtils";

const domain = process.env.REACT_APP_DOMAIN;

export default class DistributorModel {

    static find() {
        return new Promise((resolve, reject) => {
            APIUtil.getJSONWithCredentials(
                domain +
                `/api/distributor/find`,
                resolve,
                reject
            );
        });
    }
}