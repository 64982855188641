import "../../../component/popup/styles.scss";
import { useEffect, useState } from "react";
import { Page } from "../../../interface";
import Layout from "../../../component/Layout";
import { useParams } from "react-router-dom";
import DateTimeUtil from "../../../utils/DateTimeUtil";
import WarehouseModel from "../../../model/WarehouseModel";
import TransactionWarehouseModel from "../../../model/TransactionWarehouseModel";
import * as TypeTransactionWarehouse from '../../../constant/type/TypeTransactionWarehouse'

const default_avatar = "http://cdn.onlinewebfonts.com/svg/img_264570.png";
function DetailWarehousePage() {
  let { userId } = useParams();
  const [warehouse, setwarehouse] = useState<any>();
  const [transactions, setTransactions] = useState<Page<any>>()

  const fetchwarehouse = () => {
    WarehouseModel.get(userId).then((resp) => {
      if (resp.error === 0) {
        setwarehouse(resp.data);
        fetchListTransactionWarehouse(resp.data.id)
      }
    });
  };

  const fetchListTransactionWarehouse = (warehouseId) => {
    TransactionWarehouseModel.find(warehouseId)
      .then(resp => {
        if (resp.error == 0) {
          setTransactions(resp.data)
        }
      })
  }

  useEffect(() => {
    fetchwarehouse();
  }, []);

  console.log(transactions)

  return (
    <Layout>
      <main className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-xl-12">
              <div className="tab-content">
                <div
                  className="tab-pane fade show active"
                  id="account"
                  role="tabpanel"
                >
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title mb-0">Cửa hàng / NPP</h5>
                    </div>
                    <div className="card-body">
                      {warehouse && (
                        <form>
                          <div className="row">
                            <div className="col-md-8">
                              <div className="form-group">
                                <label>Tên</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={warehouse.user.name}
                                />
                              </div>
                              <div className="form-group">
                                <label>Số điện thoại</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={warehouse.user.phone}
                                />
                              </div>
                              <div className="form-group">
                                <label>Tên cửa hàng</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={warehouse.user.nameStore}
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="text-center">
                                <img
                                  alt=""
                                  src={
                                    warehouse.user.avatar
                                      ? warehouse.user.avatar
                                      : default_avatar
                                  }
                                  className="rounded-circle img-responsive mt-2"
                                  width={128}
                                  height={128}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <h4 style={{ paddingTop: '30px' }}>Chi tiết kho</h4>
                              <table className="table">
                                <tr>
                                  <th></th>
                                  <th>Tổng</th>
                                  <th>Trước 20/10</th>
                                  <th>Sau 20/10</th>
                                </tr>
                                <tr>
                                  <td>Số tấm</td>
                                  <td><b>{warehouse.plate}</b> / {warehouse.historyPlate}</td>
                                  <td>{warehouse?.before20Oct?.plate | 0} / {warehouse?.before20Oct?.historyPlate | 0}</td>
                                  <td>{warehouse?.after20Oct?.plate | 0} / {warehouse?.after20Oct?.historyPlate | 0}</td>
                                </tr>
                                <tr>
                                  <td>Số khung</td>
                                  <td><b>{warehouse.frame}</b> / {warehouse.historyFrame}</td>
                                  <td>{warehouse?.before20Oct?.frame | 0} / {warehouse?.before20Oct?.historyFrame | 0}</td>
                                  <td>{warehouse?.after20Oct?.frame | 0} / {warehouse?.after20Oct?.historyFrame | 0}</td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-12 col-xl-12">
                  <div className="header">
                    <h1
                      style={{ color: "#3e4676" }}
                      className="header-title"
                    >
                      {" "}
                      Lịch sử{" "}
                    </h1>
                  </div>
                </div>
                <div style={{ marginBottom: "120px" }} className="card">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Thời gian</th>
                        <th>Avatar</th>
                        <th>Thành viên</th>
                        <th>Số tấm</th>
                        <th>Số khung</th>
                        <th>Loại</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactions &&
                        transactions.list &&
                        transactions.list.map((transaction, key) => {
                          let actor;
                          if (transaction.form?.user) {
                            actor = transaction.form.user;
                          } else {
                            actor = {
                              avatar: "https://s160-ava-talk.zadn.vn/2/0/c/0/1/160/ed961026aa0cd1557f142d600a52c8cb.jpg",
                              name: "Admin Knauf"
                            }
                          }
                          return (
                            <tr key={key}>
                              <td>{transaction.id}</td>
                              {transaction.timeSnapshot &&
                                <td>
                                  {DateTimeUtil.toString(
                                    transaction.timeSnapshot * 1000
                                  )}
                                </td>
                              }
                              {!transaction.timeSnapshot && transaction.form &&
                                <td>
                                  {DateTimeUtil.toString(
                                    transaction.form.billCreatedTime * 1000
                                  )}
                                </td>
                              }
                              <td>
                                <img style={{ width: '70px', height: '70px', borderRadius: '50%' }} src={actor.avatar} />
                              </td>
                              <td>
                                {actor.name}
                              </td>
                              <td>{transaction.plate}</td>
                              <td>{transaction.frame}</td>
                              <td><span style={{ padding: '5px 15px', borderRadius: '5px', color: '#fff', backgroundColor: `${TypeTransactionWarehouse.getColor(transaction.type)}` }}>{TypeTransactionWarehouse.getName(transaction.type)}</span></td>
                              {/* <td>
                                <span
                                  style={{
                                    backgroundColor:
                                      StockFormStatus.findColor(
                                        form.status
                                      ),
                                  }}
                                  className="badge badge-info"
                                >
                                  {StockFormStatus.findName(form.status)}
                                </span>
                              </td> */}
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}

export default DetailWarehousePage;
