import React from "react";
import { Link } from "react-router-dom";
import StoreUtils from "../utils/StoreUtils";

export default class Layout extends React.PureComponent {

  isActive = (name) => {
    let path = window.location.pathname;
    return path.includes(name);
  };

  isActiveSubMenu = (name) => {
    let path = window.location.pathname;
    return path.includes(name);
  }

  render() {
    if (StoreUtils.getConfig() == null) {
      window.location.href = "/";
    }
    return (
      <div className="wrapper">
        <nav id="sidebar" className="sidebar">
          <a href="/" className="sidebar-brand">
            <p className="logo-knauf">KNAUF</p>
          </a>
          <div className="sidebar-content">
            <div className="sidebar-user">
              <img
                alt=""
                src="https://marketplace.magento.com/media/catalog/product/4/a/4acb_rsz_admin-logo.png"
                className="img-fluid  mb-2"
              />
              <div className="font-weight-bold">KNAUF ADMIN</div>
            </div>
            <ul className="sidebar-nav">
              <li className="sidebar-header">Main</li>
              {StoreUtils.getConfig() != null && StoreUtils.getConfig().menu.map(item => (
                <li
                  className={`sidebar-item ${this.isActive(item.path) && item.id !== 1 && "active"}`}
                  key={item.id}
                >
                  <Link
                    to={item.path}
                    data-toggle="collapse"
                    className="sidebar-link collapsed"
                  >
                    <i className="align-middle fas fa-fw fa-store" />{" "}
                    <span className="align-middle">{item.name}</span>
                  </Link>
                  {item.hasSubMenu && (
                    <ul id="forms" className={`sidebar-dropdown list-unstyled off collapse ${this.isActive(item.path) && " show"}`} >

                      {item.subMenu.map(item_1 => (
                        <li className={`sidebar-item ${this.isActiveSubMenu(item_1.path) && 'active'}`}>
                          <Link className={`sidebar-link`} to={item_1.path}>{item_1.name}</Link>
                        </li>))}
                    </ul>)}
                </li>
              ))}
            </ul>
          </div>
        </nav>
        <div className="main">
          <nav className="navbar navbar-expand navbar-theme">
            <a className="sidebar-toggle d-flex mr-2">
              <i className="hamburger align-self-center" />
            </a>
            <form className="form-inline d-none d-sm-inline-block">
              <input
                className="form-control form-control-lite"
                type="text"
                placeholder="Search projects..."
              />
            </form>
            <div className="navbar-collapse collapse">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item dropdown ml-lg-2 mt-2">
                  <Link
                    style={{ borderRadius: '50%' }}
                    type="button"
                    className="nav-link position-relative btn btn-primary  btn-sm"
                    to={`/?src=${StoreUtils.getConfig()?.key}`}
                  >
                    <i className="align-middle fas fa-cog"></i>
                  </Link>
                </li>
              </ul>

            </div>
          </nav>
          {this.props.children}
        </div>
      </div>
    );
  }
}
