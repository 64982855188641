export default class StoreUtils {

    static name_config = "config_knauf"

    static setConfig(config) {
        window.localStorage.setItem(this.name_config, JSON.stringify(config));
    }

    static getConfig() {
        let tmp = window.localStorage.getItem(this.name_config);
        return JSON.parse(tmp)
    }


}
