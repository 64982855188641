import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Alert from "react-s-alert";

import "../src/resource/css/modern.css";
import "../src/resource/css/index.css";
import reportWebVitals from "./reportWebVitals";
import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/slide.css";
import "antd/dist/antd.css";
import "../src/index.css";

// Promotion2022
import DashboardPage from "./page/Promotion2022/dashboard/DashboardPage";
import ListHistoryFormsPage from "./page/Promotion2022/form/ListHistoryFormsPage";
import DetailRTLEP01FormPage from "./page/Promotion2022/form/DetailRTLEP01FormPage";
import DetailGiftOrderPage from "./page/Promotion2022/gift-order/DetailGiftOrderPage";
import ListRetailerGiftOrderPage from "./page/Promotion2022/gift-order/ListRetailerGiftOrderPage";
import ListAccumulationRetailerPage from "./page/Promotion2022/accumulation/ListAccumulationRetailerPage";
import ListWarehousePage from "./page/Promotion2022/warehouse/ListWarehousePage";
import UpdateWarehousePage from "./page/Promotion2022/warehouse/update";
import DetailWarehousePage from "./page/Promotion2022/warehouse/DetailWarehousePage";
import ListWaitingFormsPage from "./page/Promotion2022/form/ListWaitingFormsPage";
import DetailAccumulationPage from "./page/Promotion2022/accumulation/DetailAccumulationPage";

// Promotion2023
import DashboardPromotion2023Page from "./page/Promotion2023/dashboard/DashboardPromotion2023Page";
import ListHistoryFormsPromotion2023Page from "./page/Promotion2023/form/ListHistoryFormsPromotion2023Page";
import DetailGiftOrderPromotion2023Page from "./page/Promotion2023/gift-order/DetailGiftOrderPromotion2023Page";
import ListRetailerGiftOrderPromotion2023Page from "./page/Promotion2023/gift-order/ListRetailerGiftOrderPromotion2023Page";
import ListAccumulationRetailerPromotion2023Page from "./page/Promotion2023/accumulation/ListAccumulationRetailerPromotion2023Page";
import ListWarehousePromotion2023Page from "./page/Promotion2023/warehouse/ListWarehousePromotion2023Page";
import UpdateWarehousePromotion2023Page from "./page/Promotion2023/warehouse/UpdateWarehousePromotion2023Page";
import DetailWarehousePromotion2023Page from "./page/Promotion2023/warehouse/DetailWarehousePromotion2023Page";
import ListWaitingFormsPromotion2023Page from "./page/Promotion2023/form/ListWaitingFormsPromotion2023Page";
import DetailAccumulationPromotion2023Page from "./page/Promotion2023/accumulation/DetailAccumulationPromotion2023Page";

// CTRQrScan
import ListQrCode from "./page/CTRQrScan/qr-code";
import ListHistoryScanQRFormsPage from "./page/CTRQrScan/form/ListHistoryScanQRFormsPage";
import DetailScanQRFormPage from "./page/CTRQrScan/form/DetailScanQRFormPage";
import DetailAccumulationCTRPage from "./page/CTRQrScan/accumulation/DetailAccumulationCTRPage";
import DetailGiftOrderCTRPage from "./page/CTRQrScan/gift-order/DetailGiftOrderCTRPage";
import DashboardCTRPage from "./page/CTRQrScan/dashboard/DashboardCTRPage";
import ListContractorGiftOrderPage from "./page/CTRQrScan/gift-order/ListContractorGiftOrderPage";
import ListAccumulationContractorPage from "./page/CTRQrScan/accumulation/ListAccumulationContractorPage";

//PredictFootball
import ListMatch from "./page/PredictFootball/match/index";
import CreateMatch from "./page/PredictFootball/match/create";
import ListFormOfMatch from "./page/PredictFootball/form";
import DetailPredictFormPage from "./page/PredictFootball/form/detail";
import DetailGiftOrderPredictPage from "./page/PredictFootball/gift-order/DetailGiftOrderPredictPage";
import ListPredictFootballGiftOrderPage from "./page/PredictFootball/gift-order/ListPredictFootballGiftOrderPage";
import ListAccumulationPredictFootballPage from "./page/PredictFootball/accumulation/ListAccumulationPredictFootballPage";
import DetailAccumulationPredictFootballPage from "./page/PredictFootball/accumulation/DetailAccumulationPredictFootballPage";
import DashboardPredictFootball from "./page/PredictFootball/dashboard/DashboardPredictFootball";
import ListGotitVoucherPage from "./page/Share/gotit-voucher/ListGotitVoucherPage";

import SelectFormTypePage from "./select-form-type";
import ConfigConstant from "./constant/config";

import * as UserType from "./constant/type/UserType";
import ListUserPage from "./page/Share/user/ListUserPage";
import DetailUserPage from "./page/Share/user/DetailUserPage";
import ListDraftProfilePage from "./page/Share/draft-profile/ListDraftProfilePage";
import DetailDraftProfilePage from "./page/Share/draft-profile/DetailDraftProfilePage";
import WarehouseNetworkGraph from "./page/Share/network-graph";
import ListTopicPage from "./page/Feedback/topic/ListTopicPage";
import ListMessageFeedbackPage from "./page/Feedback/message/ListMessageFeedbackPage";
import DetailMessageFeedbackPage from "./page/Feedback/message/DetailMessageFeedbackPage";
import DashboardFeedbackPage from "./page/Feedback/dashboard/DashboardFeedbackPage";

import DashboardPromotion2023H2Page from "./page/Promotion2023h2/dashboard/DashboardPromotion2023H2Page";
import ListRetailerGiftOrderPromotion2023H2Page from "./page/Promotion2023h2/gift-order/ListRetailerGiftOrderPromotion2023H2Page";
import DetailGiftOrderPromotion2023H2Page from "./page/Promotion2023h2/gift-order/DetailGiftOrderPromotion2023H2Page";
import ListHistoryFormsPromotion2023H2Page from "./page/Promotion2023h2/form/ListHistoryFormsPromotion2023H2Page";
import ListWaitingFormsPromotion2023H2Page from "./page/Promotion2023h2/form/ListWaitingFormsPromotion2023H2Page";
import InvoiceFormPromotion2023H2Page from "./page/Promotion2023h2/form/InvoiceFormPromotion2023H2Page";
import ListPointPage from "./page/Promotion2023h2/point/ListPointPage";
import DetailPointPage from "./page/Promotion2023h2/point/DetailPointPage";
import ListWarehousePromotion2023H2Page from "./page/Promotion2023h2/warehouse/ListWarehousePromotion2023H2Page";
import UpdateWarehousePromotion2023H2Page from "./page/Promotion2023h2/warehouse/UpdateWarehousePromotion2023H2Page";
import DetailWarehousePromotion2023H2Page from "./page/Promotion2023h2/warehouse/DetailWarehousePromotion2023H2Page";
import ListDepartmentPage from "./page/Feedback/department/ListDepartmentPage";
import ListEmployeePage from "./page/Feedback/employee/ListEmployeePage";
import ListRegistrationPage from "./page/Event/registration/ListRegistrationPage";
import ListPromotionPage from "./page/Share/promotion/ListPromotionPage";
import DetailPromotionPage from "./page/Share/promotion/DetailPromotionPage";
import ListGiftPage from "./page/Share/gifts/ListGiftPage";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SelectFormTypePage />} />
        {/* ctr - scan */}
        <Route path={`/${ConfigConstant.CTR_SCAN_QR_FORM_TYPE.key}`}>
          <Route index element={<DashboardCTRPage />} />
          <Route
            path={`/${ConfigConstant.CTR_SCAN_QR_FORM_TYPE.key}/user/:id`}
            element={
              <DetailUserPage config={ConfigConstant.CTR_SCAN_QR_FORM_TYPE} />
            }
          />
          <Route
            path={`/${ConfigConstant.CTR_SCAN_QR_FORM_TYPE.key}/user/list`}
            element={
              <ListUserPage
                roleId={UserType.CONSTRUCTOR}
                config={ConfigConstant.CTR_SCAN_QR_FORM_TYPE}
              />
            }
          />
          <Route
            path={`/${ConfigConstant.CTR_SCAN_QR_FORM_TYPE.key}/gift-order/list`}
            element={<ListContractorGiftOrderPage />}
          />
          <Route
            path={`/${ConfigConstant.CTR_SCAN_QR_FORM_TYPE.key}/gift-order/:id`}
            element={<DetailGiftOrderCTRPage />}
          />
          <Route
            path={`/${ConfigConstant.CTR_SCAN_QR_FORM_TYPE.key}/form/list/scanqr/history`}
            element={<ListHistoryScanQRFormsPage />}
          />
          <Route
            path={`/${ConfigConstant.CTR_SCAN_QR_FORM_TYPE.key}/form/:id/scan-qr`}
            element={<DetailScanQRFormPage />}
          />
          <Route
            path={`/${ConfigConstant.CTR_SCAN_QR_FORM_TYPE.key}/accumulation/list`}
            element={<ListAccumulationContractorPage />}
          />
          <Route
            path={`/${ConfigConstant.CTR_SCAN_QR_FORM_TYPE.key}/accumulation/:id`}
            element={<DetailAccumulationCTRPage />}
          />
          <Route
            path={`/${ConfigConstant.CTR_SCAN_QR_FORM_TYPE.key}/qr-code/list`}
            element={
              <ListQrCode config={ConfigConstant.CTR_SCAN_QR_FORM_TYPE} />
            }
          />
        </Route>

        {/* promotion 2022 */}
        <Route path={`/${ConfigConstant.RTL_EP01_FORM_TYPE.key}`}>
          <Route index element={<DashboardPage />} />
          <Route
            path={`/${ConfigConstant.RTL_EP01_FORM_TYPE.key}/user/:id`}
            element={
              <DetailUserPage config={ConfigConstant.RTL_EP01_FORM_TYPE} />
            }
          />
          <Route
            path={`/${ConfigConstant.RTL_EP01_FORM_TYPE.key}/user/list`}
            element={
              <ListUserPage
                roleId={[UserType.RETAILER, UserType.DISTRIBUTOR]}
                config={ConfigConstant.RTL_EP01_FORM_TYPE}
              />
            }
          />
          <Route
            path={`/${ConfigConstant.RTL_EP01_FORM_TYPE.key}/gift-order/list`}
            element={<ListRetailerGiftOrderPage />}
          />

          <Route
            path={`/${ConfigConstant.RTL_EP01_FORM_TYPE.key}/gift-order/:id`}
            element={<DetailGiftOrderPage />}
          />
          <Route
            path={`/${ConfigConstant.RTL_EP01_FORM_TYPE.key}/form/list/history`}
            element={<ListHistoryFormsPage />}
          />

          <Route
            path={`/${ConfigConstant.RTL_EP01_FORM_TYPE.key}/form/list/waiting`}
            element={<ListWaitingFormsPage />}
          />
          <Route
            path={`/${ConfigConstant.RTL_EP01_FORM_TYPE.key}/form/:id/rtl-ep01`}
            element={<DetailRTLEP01FormPage />}
          />

          <Route
            path={`/${ConfigConstant.RTL_EP01_FORM_TYPE.key}/accumulation/list`}
            element={<ListAccumulationRetailerPage />}
          />

          <Route
            path={`/${ConfigConstant.RTL_EP01_FORM_TYPE.key}/accumulation/:id`}
            element={<DetailAccumulationPage />}
          />
          <Route
            path={`/${ConfigConstant.RTL_EP01_FORM_TYPE.key}/warehouse/list`}
            element={<ListWarehousePage />}
          />
          <Route
            path={`/${ConfigConstant.RTL_EP01_FORM_TYPE.key}/warehouse/update`}
            element={<UpdateWarehousePage />}
          />
          <Route
            path={`/${ConfigConstant.RTL_EP01_FORM_TYPE.key}/warehouse/:userId`}
            element={<DetailWarehousePage />}
          />

          <Route
            path={`/${ConfigConstant.RTL_EP01_FORM_TYPE.key}/gotit-voucher/list`}
            element={<ListGotitVoucherPage />}
          />
        </Route>

        {/* promotion 2023 */}
        <Route path={`/${ConfigConstant.INVOICE_PROMOTION_2023.key}`}>
          <Route index element={<DashboardPromotion2023Page
            promotionId={ConfigConstant.INVOICE_PROMOTION_2023.promotionId}
          />} />
          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023.key}/dashboards`}
            element={
              <DetailUserPage config={ConfigConstant.INVOICE_PROMOTION_2023} />
            }
          />
          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023.key}/user/:id`}
            element={
              <DetailUserPage config={ConfigConstant.INVOICE_PROMOTION_2023} />
            }
          />
          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023.key}/user/list/retailer`}
            element={
              <ListUserPage
                roleId={[UserType.RETAILER]}
                config={ConfigConstant.INVOICE_PROMOTION_2023}
              />
            }
          />
          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023.key}/draft-profile/list`}
            element={
              <ListDraftProfilePage
                config={ConfigConstant.INVOICE_PROMOTION_2023}
              />
            }
          />

          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023.key}/draft-profile/:id`}
            element={<DetailDraftProfilePage />}
          />

          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023.key}/user/list/constructor`}
            element={
              <ListUserPage
                roleId={[UserType.CONSTRUCTOR]}
                config={ConfigConstant.INVOICE_PROMOTION_2023}
              />
            }
          />
          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023.key}/user/list/distributor`}
            element={
              <ListUserPage
                roleId={[UserType.DISTRIBUTOR]}
                config={ConfigConstant.INVOICE_PROMOTION_2023}
              />
            }
          />
          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023.key}/user/list/distributor-t2`}
            element={
              <ListUserPage
                roleId={[UserType.DISTRIBUTOR_T2]}
                config={ConfigConstant.INVOICE_PROMOTION_2023}
              />
            }
          />
          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023.key}/user/list`}
            element={
              <ListUserPage
                roleId={[
                  UserType.RETAILER,
                  UserType.DISTRIBUTOR,
                  UserType.CONSTRUCTOR,
                  UserType.DISTRIBUTOR_T2,
                ]}
                config={ConfigConstant.INVOICE_PROMOTION_2023}
              />
            }
          />
          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023.key}/gift-order/list`}
            element={<ListRetailerGiftOrderPromotion2023Page />}
          />

          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023.key}/gift-order/:id`}
            element={<DetailGiftOrderPromotion2023Page />}
          />
          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023.key}/form/list/history/retailer-distributor`}
            element={
              <ListHistoryFormsPromotion2023Page
                path={ConfigConstant.INVOICE_PROMOTION_2023.key}
                promotionId={ConfigConstant.INVOICE_PROMOTION_2023.promotionId}
                roleIds={[
                  UserType.RETAILER,
                  UserType.DISTRIBUTOR,
                  UserType.DISTRIBUTOR_T2,
                ]}
              />
            }
          />
          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023.key}/form/list/history/constructor`}
            element={
              <ListHistoryFormsPromotion2023Page
                path={ConfigConstant.INVOICE_PROMOTION_2023.key}
                promotionId={ConfigConstant.INVOICE_PROMOTION_2023.promotionId}
                roleIds={[UserType.CONSTRUCTOR]}
              />
            }
          />
          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023.key}/form/list/history`}
            element={
              <ListHistoryFormsPromotion2023Page
                path={ConfigConstant.INVOICE_PROMOTION_2023.key}
                promotionId={ConfigConstant.INVOICE_PROMOTION_2023.promotionId}
                roleIds={[
                  UserType.CONSTRUCTOR,
                  UserType.RETAILER,
                  UserType.DISTRIBUTOR,
                  UserType.DISTRIBUTOR_T2,
                ]}
              />
            }
          />
          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023.key}/form/list/waiting/retailer-distributor`}
            element={
              <ListWaitingFormsPromotion2023Page
                path={ConfigConstant.INVOICE_PROMOTION_2023.key}
                promotionId={ConfigConstant.INVOICE_PROMOTION_2023.promotionId}
                roleIds={[
                  UserType.RETAILER,
                  UserType.DISTRIBUTOR,
                  UserType.DISTRIBUTOR_T2,
                ]}
              />
            }
          />
          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023.key}/form/list/waiting/constructor`}
            element={
              <ListWaitingFormsPromotion2023Page
                path={ConfigConstant.INVOICE_PROMOTION_2023.key}
                promotionId={ConfigConstant.INVOICE_PROMOTION_2023.promotionId}
                roleIds={[UserType.CONSTRUCTOR]}
              />
            }
          />

          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023.key}/form/list/waiting`}
            element={
              <ListWaitingFormsPromotion2023Page
                path={ConfigConstant.INVOICE_PROMOTION_2023.key}
                promotionId={ConfigConstant.INVOICE_PROMOTION_2023.promotionId}
                roleIds={[
                  UserType.RETAILER,
                  UserType.DISTRIBUTOR,
                  UserType.DISTRIBUTOR_T2,
                  UserType.CONSTRUCTOR,
                ]}
              />
            }
          />
          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023.key}/form/:id/rtl-ep01`}
            element={<InvoiceFormPromotion2023H2Page />}
          />

          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023.key}/accumulation/list`}
            element={<ListAccumulationRetailerPromotion2023Page
              promotionId={ConfigConstant.INVOICE_PROMOTION_2023.promotionId}
              path={ConfigConstant.INVOICE_PROMOTION_2023.key}
            />}
          />

          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023.key}/accumulation/:id`}
            element={<DetailAccumulationPromotion2023Page />}
          />
          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023.key}/warehouse/list`}
            element={<ListWarehousePromotion2023Page />}
          />
          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023.key}/warehouse/update`}
            element={<UpdateWarehousePromotion2023Page />}
          />
          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023.key}/warehouse/:userId`}
            element={<DetailWarehousePromotion2023Page />}
          />
        </Route>

        {/* promotion mini 8 plate 2023 */}
        <Route path={`/${ConfigConstant.INVOICE_PROMOTION_2023_MINI_8_PLATE.key}`}>
          <Route index element={<DashboardPromotion2023Page
            promotionId={ConfigConstant.INVOICE_PROMOTION_2023_MINI_8_PLATE.promotionId}
          />} />
          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023_MINI_8_PLATE.key}/dashboards`}
            element={
              <DetailUserPage config={ConfigConstant.INVOICE_PROMOTION_2023_MINI_8_PLATE} />
            }
          />
          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023_MINI_8_PLATE.key}/user/:id`}
            element={
              <DetailUserPage config={ConfigConstant.INVOICE_PROMOTION_2023_MINI_8_PLATE} />
            }
          />
          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023_MINI_8_PLATE.key}/user/list/retailer`}
            element={
              <ListUserPage
                roleId={[UserType.RETAILER]}
                config={ConfigConstant.INVOICE_PROMOTION_2023_MINI_8_PLATE}
              />
            }
          />
          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023_MINI_8_PLATE.key}/draft-profile/list`}
            element={
              <ListDraftProfilePage
                config={ConfigConstant.INVOICE_PROMOTION_2023_MINI_8_PLATE}
              />
            }
          />

          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023_MINI_8_PLATE.key}/draft-profile/:id`}
            element={<DetailDraftProfilePage />}
          />

          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023_MINI_8_PLATE.key}/user/list/constructor`}
            element={
              <ListUserPage
                roleId={[UserType.CONSTRUCTOR]}
                config={ConfigConstant.INVOICE_PROMOTION_2023_MINI_8_PLATE}
              />
            }
          />
          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023_MINI_8_PLATE.key}/user/list/distributor`}
            element={
              <ListUserPage
                roleId={[UserType.DISTRIBUTOR]}
                config={ConfigConstant.INVOICE_PROMOTION_2023_MINI_8_PLATE}
              />
            }
          />
          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023_MINI_8_PLATE.key}/user/list/distributor-t2`}
            element={
              <ListUserPage
                roleId={[UserType.DISTRIBUTOR_T2]}
                config={ConfigConstant.INVOICE_PROMOTION_2023_MINI_8_PLATE}
              />
            }
          />


          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023_MINI_8_PLATE.key}/user/list`}
            element={
              <ListUserPage
                roleId={[
                  UserType.RETAILER,
                  UserType.DISTRIBUTOR,
                  UserType.CONSTRUCTOR,
                  UserType.DISTRIBUTOR_T2,
                ]}
                config={ConfigConstant.INVOICE_PROMOTION_2023_MINI_8_PLATE}
              />
            }
          />


          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023_MINI_8_PLATE.key}/form/list/history/retailer-distributor`}
            element={
              <ListHistoryFormsPromotion2023Page
                path={ConfigConstant.INVOICE_PROMOTION_2023_MINI_8_PLATE.key}
                promotionId={ConfigConstant.INVOICE_PROMOTION_2023_MINI_8_PLATE.promotionId}
                roleIds={[
                  UserType.RETAILER,
                  UserType.DISTRIBUTOR,
                  UserType.DISTRIBUTOR_T2,
                ]}
              />
            }
          />

          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023_MINI_8_PLATE.key}/form/list/history`}
            element={
              <ListHistoryFormsPromotion2023Page
                path={ConfigConstant.INVOICE_PROMOTION_2023_MINI_8_PLATE.key}
                promotionId={ConfigConstant.INVOICE_PROMOTION_2023_MINI_8_PLATE.promotionId}
                roleIds={[
                  UserType.CONSTRUCTOR,
                  UserType.RETAILER,
                  UserType.DISTRIBUTOR,
                  UserType.DISTRIBUTOR_T2,
                ]}
              />
            }
          />

          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023_MINI_8_PLATE.key}/form/list/waiting/retailer-distributor`}
            element={
              <ListWaitingFormsPromotion2023Page
                path={ConfigConstant.INVOICE_PROMOTION_2023_MINI_8_PLATE.key}
                promotionId={ConfigConstant.INVOICE_PROMOTION_2023_MINI_8_PLATE.promotionId}
                roleIds={[
                  UserType.RETAILER,
                  UserType.DISTRIBUTOR,
                  UserType.DISTRIBUTOR_T2,
                ]}
              />
            }
          />

          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023_MINI_8_PLATE.key}/form/list/waiting`}
            element={
              <ListWaitingFormsPromotion2023Page
                path={ConfigConstant.INVOICE_PROMOTION_2023_MINI_8_PLATE.key}
                promotionId={ConfigConstant.INVOICE_PROMOTION_2023_MINI_8_PLATE.promotionId}
                roleIds={[
                  UserType.RETAILER,
                  UserType.DISTRIBUTOR,
                  UserType.DISTRIBUTOR_T2,
                  UserType.CONSTRUCTOR,
                ]}
              />
            }
          />

          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023_MINI_8_PLATE.key}/form/:id/rtl-ep01`}
            element={<InvoiceFormPromotion2023H2Page />}
          />

          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023_MINI_8_PLATE.key}/accumulation/list`}
            element={<ListAccumulationRetailerPromotion2023Page
              promotionId={ConfigConstant.INVOICE_PROMOTION_2023_MINI_8_PLATE.promotionId}
              path={ConfigConstant.INVOICE_PROMOTION_2023_MINI_8_PLATE.key}
            />}
          />

          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023_MINI_8_PLATE.key}/accumulation/:id`}
            element={<DetailAccumulationPromotion2023Page />}
          />

          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023_MINI_8_PLATE.key}/warehouse/list`}
            element={<ListWarehousePromotion2023Page />}
          />

          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023_MINI_8_PLATE.key}/warehouse/update`}
            element={<UpdateWarehousePromotion2023Page />}
          />

          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023_MINI_8_PLATE.key}/warehouse/:userId`}
            element={<DetailWarehousePromotion2023Page />}
          />
        </Route>

        {/* predict football */}
        <Route path={`/${ConfigConstant.PREDICT_FOOTBALL.key}`}>
          <Route index element={<DashboardPredictFootball />} />
          <Route
            path={`/${ConfigConstant.PREDICT_FOOTBALL.key}/user/:id`}
            element={
              <DetailUserPage config={ConfigConstant.PREDICT_FOOTBALL} />
            }
          />
          <Route
            path={`/${ConfigConstant.PREDICT_FOOTBALL.key}/user/list`}
            element={
              <ListUserPage
                roleId={UserType.CONSTRUCTOR}
                config={ConfigConstant.PREDICT_FOOTBALL}
              />
            }
          />
          <Route
            path={`/${ConfigConstant.PREDICT_FOOTBALL.key}/match/list`}
            element={<ListMatch />}
          />
          <Route
            path={`/${ConfigConstant.PREDICT_FOOTBALL.key}/match/create`}
            element={<CreateMatch />}
          />
          <Route
            path={`/${ConfigConstant.PREDICT_FOOTBALL.key}/form/list/:matchId`}
            element={<ListFormOfMatch />}
          />
          <Route
            path={`/${ConfigConstant.PREDICT_FOOTBALL.key}/form/detail/:id`}
            element={<DetailPredictFormPage />}
          />
          <Route
            path={`/${ConfigConstant.PREDICT_FOOTBALL.key}/gift-order/list`}
            element={<ListPredictFootballGiftOrderPage />}
          />
          <Route
            path={`/${ConfigConstant.PREDICT_FOOTBALL.key}/gift-order/:id`}
            element={<DetailGiftOrderPredictPage />}
          />
          <Route
            path={`/${ConfigConstant.PREDICT_FOOTBALL.key}/accumulation/list`}
            element={<ListAccumulationPredictFootballPage />}
          />
          <Route
            path={`/${ConfigConstant.PREDICT_FOOTBALL.key}/accumulation/:id`}
            element={<DetailAccumulationPredictFootballPage />}
          />

          <Route
            path={`/${ConfigConstant.PREDICT_FOOTBALL.key}/accumulation/:id`}
            element={<DetailAccumulationPredictFootballPage />}
          />
        </Route>

        {/* Feedback */}
        <Route path={`/${ConfigConstant.FEEDBACK.key}`}>
          <Route
            path={`/${ConfigConstant.FEEDBACK.key}`}
            element={
              <DashboardFeedbackPage />
            }
          />
          <Route
            path={`/${ConfigConstant.FEEDBACK.key}/topic`}
            element={
              <ListTopicPage
                roleId={[
                  UserType.RETAILER,
                  UserType.DISTRIBUTOR,
                  UserType.DISTRIBUTOR,
                  UserType.DISTRIBUTOR_T2,
                ]}
                config={ConfigConstant.FEEDBACK}
              />
            }
          />
          <Route
            path={`/${ConfigConstant.FEEDBACK.key}/user/:id`}
            element={<DetailUserPage config={ConfigConstant.FEEDBACK} />}
          />
          <Route
            path={`/${ConfigConstant.FEEDBACK.key}/user/list/retailer`}
            element={
              <ListUserPage
                roleId={[UserType.RETAILER]}
                config={ConfigConstant.FEEDBACK}
              />
            }
          />
          <Route
            path={`/${ConfigConstant.FEEDBACK.key}/user/list/constructor`}
            element={
              <ListUserPage
                roleId={[UserType.CONSTRUCTOR]}
                config={ConfigConstant.FEEDBACK}
              />
            }
          />
          <Route
            path={`/${ConfigConstant.FEEDBACK.key}/user/list/distributor`}
            element={
              <ListUserPage
                roleId={[UserType.DISTRIBUTOR]}
                config={ConfigConstant.FEEDBACK}
              />
            }
          />
          <Route
            path={`/${ConfigConstant.FEEDBACK.key}/user/list/distributor-t2`}
            element={
              <ListUserPage
                roleId={[UserType.DISTRIBUTOR_T2]}
                config={ConfigConstant.FEEDBACK}
              />
            }
          />

          <Route
            path={`/${ConfigConstant.FEEDBACK.key}/user/list`}
            element={
              <ListUserPage
                roleId={[
                  UserType.RETAILER,
                  UserType.DISTRIBUTOR,
                  UserType.DISTRIBUTOR,
                  UserType.DISTRIBUTOR_T2,
                ]}
                config={ConfigConstant.FEEDBACK}
              />
            }
          />

          <Route
            path={`/${ConfigConstant.FEEDBACK.key}/message/:id`}
            element={<DetailMessageFeedbackPage />}
          />

          <Route
            path={`/${ConfigConstant.FEEDBACK.key}/message/list`}
            element={
              <ListMessageFeedbackPage
                roleId={[
                  UserType.RETAILER,
                  UserType.DISTRIBUTOR,
                  UserType.DISTRIBUTOR,
                  UserType.DISTRIBUTOR_T2,
                ]}
                config={ConfigConstant.FEEDBACK}
              />
            }
          />
          <Route
            path={`/${ConfigConstant.FEEDBACK.key}/department/list`}
            element={
              <ListDepartmentPage
                config={ConfigConstant.FEEDBACK}
              />
            }
          />
          <Route
            path={`/${ConfigConstant.FEEDBACK.key}/employee/list`}
            element={
              <ListEmployeePage
                config={ConfigConstant.FEEDBACK}
              />
            }
          />
        </Route>


        {/* promotion 2023 H2*/}
        <Route path={`/${ConfigConstant.INVOICE_PROMOTION_2023_H2.key}`}>
          <Route index element={<DashboardPromotion2023H2Page
            promotionId={ConfigConstant.INVOICE_PROMOTION_2023_H2.promotionId}
          />} />
          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023_H2.key}/dashboards`}
            element={
              <DetailUserPage config={ConfigConstant.INVOICE_PROMOTION_2023_H2} />
            }
          />
          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023_H2.key}/user/:id`}
            element={
              <DetailUserPage config={ConfigConstant.INVOICE_PROMOTION_2023_H2} />
            }
          />
          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023_H2.key}/user/list/retailer`}
            element={
              <ListUserPage
                roleId={[UserType.RETAILER]}
                config={ConfigConstant.INVOICE_PROMOTION_2023_H2}
              />
            }
          />
          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023_H2.key}/draft-profile/list`}
            element={
              <ListDraftProfilePage
                config={ConfigConstant.INVOICE_PROMOTION_2023_H2}
              />
            }
          />

          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023_H2.key}/draft-profile/:id`}
            element={<DetailDraftProfilePage />}
          />

          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023_H2.key}/user/list/constructor`}
            element={
              <ListUserPage
                roleId={[UserType.CONSTRUCTOR]}
                config={ConfigConstant.INVOICE_PROMOTION_2023_H2}
              />
            }
          />
          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023_H2.key}/user/list/distributor`}
            element={
              <ListUserPage
                roleId={[UserType.DISTRIBUTOR]}
                config={ConfigConstant.INVOICE_PROMOTION_2023_H2}
              />
            }
          />
          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023_H2.key}/user/list/distributor-t2`}
            element={
              <ListUserPage
                roleId={[UserType.DISTRIBUTOR_T2]}
                config={ConfigConstant.INVOICE_PROMOTION_2023_H2}
              />
            }
          />
          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023_H2.key}/user/list`}
            element={
              <ListUserPage
                roleId={[
                  UserType.RETAILER,
                  UserType.DISTRIBUTOR,
                  UserType.CONSTRUCTOR,
                  UserType.DISTRIBUTOR_T2,
                ]}
                config={ConfigConstant.INVOICE_PROMOTION_2023_H2}
              />
            }
          />
          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023_H2.key}/gift-order/list`}
            element={<ListRetailerGiftOrderPromotion2023H2Page />}
          />

          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023_H2.key}/gift-order/:id`}
            element={<DetailGiftOrderPromotion2023H2Page />}
          />
          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023_H2.key}/form/list/history/npp-t2`}
            element={
              <ListHistoryFormsPromotion2023H2Page
                path={ConfigConstant.INVOICE_PROMOTION_2023_H2.key}
                promotionId={ConfigConstant.INVOICE_PROMOTION_2023_H2.promotionId}
                roleIds={[
                  UserType.DISTRIBUTOR_T2,
                ]}
                searchByDistributor={false}
              />
            }
          />
          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023_H2.key}/form/list/history/retailer`}
            element={
              <ListHistoryFormsPromotion2023H2Page
                path={ConfigConstant.INVOICE_PROMOTION_2023_H2.key}
                promotionId={ConfigConstant.INVOICE_PROMOTION_2023_H2.promotionId}
                roleIds={[UserType.RETAILER]}
                searchByDistributor={false}
              />
            }
          />
          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023_H2.key}/form/list/history`}
            element={
              <ListHistoryFormsPromotion2023H2Page
                path={ConfigConstant.INVOICE_PROMOTION_2023_H2.key}
                promotionId={ConfigConstant.INVOICE_PROMOTION_2023_H2.promotionId}
                roleIds={[
                  UserType.RETAILER,
                  UserType.DISTRIBUTOR,
                  UserType.DISTRIBUTOR_T2,
                ]}
                searchByDistributor={false}
              />
            }
          />
          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023_H2.key}/form/list/waiting/npp`}
            element={
              <ListWaitingFormsPromotion2023H2Page
                path={ConfigConstant.INVOICE_PROMOTION_2023_H2.key}
                promotionId={ConfigConstant.INVOICE_PROMOTION_2023_H2.promotionId}
                roleIds={[
                  UserType.DISTRIBUTOR,
                ]}
                searchByDistributor={true}
              />
            }
          />
          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023_H2.key}/form/list/waiting/npp-t2`}
            element={
              <ListWaitingFormsPromotion2023H2Page
                path={ConfigConstant.INVOICE_PROMOTION_2023_H2.key}
                promotionId={ConfigConstant.INVOICE_PROMOTION_2023_H2.promotionId}
                roleIds={[
                  UserType.DISTRIBUTOR_T2,
                ]}
                searchByDistributor={true}
              />
            }
          />
          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023_H2.key}/form/list/waiting/knauf`}
            element={
              <ListWaitingFormsPromotion2023H2Page
                path={ConfigConstant.INVOICE_PROMOTION_2023_H2.key}
                promotionId={ConfigConstant.INVOICE_PROMOTION_2023_H2.promotionId}
                roleIds={[UserType.RETAILER]}
                searchByDistributor={true}
              />
            }
          />

          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023_H2.key}/form/list/waiting`}
            element={
              <ListWaitingFormsPromotion2023H2Page
                path={ConfigConstant.INVOICE_PROMOTION_2023_H2.key}
                promotionId={ConfigConstant.INVOICE_PROMOTION_2023_H2.promotionId}
                roleIds={[
                  UserType.RETAILER,
                  UserType.DISTRIBUTOR,
                  UserType.DISTRIBUTOR_T2,
                ]}
                searchByDistributor={false}
              />
            }
          />
          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023_H2.key}/form/:id/rtl-ep01`}
            element={<InvoiceFormPromotion2023H2Page />}
          />

          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023_H2.key}/point/list`}
            element={<ListPointPage
              promotionId={ConfigConstant.INVOICE_PROMOTION_2023_H2.promotionId}
              path={ConfigConstant.INVOICE_PROMOTION_2023_H2.key}
            />}
          />

          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023_H2.key}/point/:id`}
            element={<DetailPointPage promotionId={ConfigConstant.INVOICE_PROMOTION_2023_H2.promotionId} />}
          />
          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023_H2.key}/warehouse/list`}
            element={<ListWarehousePromotion2023H2Page />}
          />
          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023_H2.key}/warehouse/update`}
            element={<UpdateWarehousePromotion2023H2Page />}
          />
          <Route
            path={`/${ConfigConstant.INVOICE_PROMOTION_2023_H2.key}/warehouse/:userId`}
            element={<DetailWarehousePromotion2023H2Page />}
          />
        </Route>

        {/* Registration */}
        <Route path={`/${ConfigConstant.EVENT.key}`}>
          <Route
            path={`/${ConfigConstant.EVENT.key}`}
            element={
              <ListPromotionPage
                roleId={[UserType.RETAILER]}
                config={ConfigConstant.EVENT}
              />
            }
          />
          <Route
            path={`/${ConfigConstant.EVENT.key}/promotion/list`}
            element={
              <ListPromotionPage
                roleId={[UserType.RETAILER]}
                config={ConfigConstant.EVENT}
              />
            }
          />
          <Route
            path={`/${ConfigConstant.EVENT.key}/promotion/create`}
            element={
              <DetailPromotionPage />
            }
          />
          <Route
            path={`/${ConfigConstant.EVENT.key}/promotion/:id`}
            element={
              <DetailPromotionPage />
            }
          />
          <Route
            path={`/${ConfigConstant.EVENT.key}/registration/list`}
            element={
              <ListRegistrationPage
              />
            }
          />
          <Route
            path={`/${ConfigConstant.EVENT.key}/gift/list`}
            element={
              <ListGiftPage />
            }
          />
        </Route>

        {/* <Route path="/test" element={<WarehouseNetworkGraph />} /> */}
      </Routes>
    </BrowserRouter>
    <Alert stack={{ limit: 3 }} />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
