import Layout from "../../../component/Layout";
import { useEffect, useState } from "react";
import { Employee, EmployeeFilter, Page } from "../../../interface";
import { useSearchParams } from "react-router-dom";
import { Avatar, Button, Pagination } from "antd";
import { useNavigate } from "react-router-dom";
import LinkUtil from "../../../utils/LinkUtil";
import EmployeeModel from "../../../model/EmployeeModel";
const PAGE_SIZE = 10;


function ListEmployeePage(props: any) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [employeePage, setEmployeePage] = useState<Page<Employee>>();
  const [filter, setFilter] = useState<EmployeeFilter>({ ...LinkUtil.params2Object(searchParams), page: 0 });


  const fetchEmployees = () => {
    EmployeeModel.find(undefined,
      filter.page, filter.pageSize).then((resp) => {
        if (resp.error === 0) {
          setEmployeePage(resp.data);
        }
      });
  };


  useEffect(() => {
    setFilter(LinkUtil.params2Object(searchParams))
  }, [searchParams])

  useEffect(() => {
    fetchEmployees()
  }, [filter]);



  return (
    <Layout>
      <main className="content">
        <div className="container-fluid">
          <div className="header">
            <h1 className="header-title">Nhân viên</h1>
          </div>
          <div className="row">
            <div className="col-12 col-xl-12">
              <div className="card">
                <div className="card-bar" style={{ padding: "10px" }}>
                </div>
                <table className="table">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>SDT</th>
                      <th>Họ và tên</th>
                      <th>Phòng ban</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {employeePage &&
                      employeePage.list &&
                      employeePage.list.map((employee, key) => {
                        return (
                          <tr key={employee.id}>
                            <td>{employee.id}</td>
                            <td>{employee.phone}</td>
                            <td>
                              <Avatar size={32} src={employee.avatar} />
                              <span style={{ paddingLeft: '10px' }}>{employee?.name}</span>
                            </td>
                            <td>{employee?.department.name}</td>
                            <td className="table-action">
                              <Button
                                type="primary"
                              >
                                Chi tiết
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <div className="cart-paging">
                  {employeePage && (
                    <Pagination
                      defaultCurrent={1}
                      defaultPageSize={PAGE_SIZE}
                      current={(filter.page || 0) + 1}
                      onChange={(pageNumber) => {
                        setFilter({ ...filter, page: pageNumber - 1 });
                      }}
                      showSizeChanger={false}
                      total={employeePage.totalPage * PAGE_SIZE}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}

export default ListEmployeePage;
