import { useMemo } from "react"
import { MessageFeedback } from "../../../interface"
import * as  MessageFeedbackStatus from '../../../constant/status/MessageFeedbackStatus'
import DateTimeUtil from "../../../utils/DateTimeUtil"
import {
    ClockCircleOutlined,
} from '@ant-design/icons';
import { Divider, Space, Tag } from 'antd';

interface LeadTimeProp {
    message: MessageFeedback
}

const LeadTime = ({ message }: LeadTimeProp) => {
    const leadtime = useMemo(() => {
        if (message) {
            let status = message.status;
            if (status != MessageFeedbackStatus.CLOSE) {
                let createdTime = message.createdTime * 1000;
                let currentTime = new Date().getTime();
                let diff = DateTimeUtil.calculateDaysDifferenceWithoutWeekends(createdTime, currentTime);
                return diff;
            }
            return message.leadTime;
        }
        return -1;
    }, [message])

    const textLeadtime = useMemo(() => {
        if (leadtime == 0) {
            return '< 1 ngày';
        }
        if (leadtime > 0) {
            return leadtime + ' ngày';
        }
        return 'Không có số liệu'
    }, [leadtime])

    const color = useMemo(() => {
        if (leadtime == -1) {
            return 'default'
        }

        if (leadtime <= 2) {
            return 'success'
        }
        if (leadtime >= 2 && leadtime < 5) {
            return 'warning'
        }
        return 'error'
    }, [leadtime])

    return <Tag icon={<ClockCircleOutlined />} color={color}>{textLeadtime}</Tag>
}

export { LeadTime }