import APIUtil from "../utils/APIUtils";

export default class PromotionModel {

    static get(id) {
        return new Promise((resolve, reject) => {
            APIUtil.getJSONWithCredentials(process.env.REACT_APP_DOMAIN + `/api/promotion/get?id=${id}`, resolve, reject);
        });
    }

    static find({ types, page, pageSize }) {
        var param = Object.fromEntries(Object.entries({
            types, page, pageSize
        }).filter(([_, v]) => v != null && v !== 0));

        return new Promise((resolve, reject) => {
            APIUtil.getJSONWithCredentials(process.env.REACT_APP_DOMAIN + `/api/promotion/list?${new URLSearchParams(param).toString()}`, resolve, reject);
        });
    }

    static save(form) {
        return new Promise((resolve, reject) => {
            APIUtil.postJSONWithCredentials(
                process.env.REACT_APP_DOMAIN +
                `/api/promotion/save`,
                JSON.stringify(form),
                resolve,
                reject
            );
        });
    }

    static updateStatus(promotionId, status) {
        return new Promise((resolve, reject) => {
            APIUtil.getJSONWithCredentials(process.env.REACT_APP_DOMAIN + `/api/promotion/update-status?id=${promotionId}&status=${status}`, resolve, reject);
        });
    }

}