import APIUtil from "../utils/APIUtils";

export default class RegistrationEventModel {

    static find({ promotionId, page, pageSize }) {
        var param = Object.fromEntries(Object.entries({
            promotionId, page, pageSize
        }).filter(([_, v]) => v != null && v !== 0));

        return new Promise((resolve, reject) => {
            APIUtil.getJSONWithCredentials(process.env.REACT_APP_DOMAIN + `/api/registration-event/find?${new URLSearchParams(param).toString()}`, resolve, reject);
        });
    }

    static sendGiftLuckyDraw(form) {
        return new Promise((resolve, reject) => {
            APIUtil.postJSONWithCredentials(process.env.REACT_APP_DOMAIN + `/api/registration-event/send-gift`,
                JSON.stringify(form), resolve, reject);
        });
    }

    static sendLuckySpinAllRemaining(promotionId) {
        return new Promise((resolve, reject) => {
            APIUtil.getJSONWithCredentials(process.env.REACT_APP_DOMAIN + `/api/registration-event/send-lucky-spin-all-remaining?promotionId=${promotionId}`, resolve, reject);
        });
    }

}