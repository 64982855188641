import Layout from "../../../component/Layout";
import "react-responsive-modal/styles.css";
import "../../../component/popup/styles.scss";
import { useEffect, useState } from "react";
import { Page, FormFilter, RtlEp01Form } from "../../../interface";
import * as StockFormStatus from "../../../constant/status/FormStatus";
import FormModel from "../../../model/FormModel";
import DateTimeUtil from "../../../utils/DateTimeUtil";
import { Button } from "antd";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Pagination } from "antd";
import LinkUtil from "../../../utils/LinkUtil";
import ConfigConstant from "../../../constant/config";
import * as TypeUser from "../../../constant/type/UserType";

const default_avatar = "http://cdn.onlinewebfonts.com/svg/img_264570.png";
const PAGE_SIZE = 10;

interface PropListWaitingFormsPromotion2023Page {
  roleIds: number[] | null;
  promotionId: number;
  path: string
}

function ListWaitingFormsPromotion2023Page(
  props: PropListWaitingFormsPromotion2023Page,
) {
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [formPage, setFormPage] = useState<Page<RtlEp01Form>>();
  const [filter, setFilter] = useState<FormFilter>(
    LinkUtil.initParamWithDefault(searchParams, {
      promotionId: props.promotionId,
      status: [StockFormStatus.INIT],
      roleIds: props.roleIds,
    })
  );

  const findForms = () => {
    FormModel.find(
      filter.search,
      filter.status,
      filter.promotionId,
      filter.page,
      PAGE_SIZE,
      filter.roleIds
    ).then((resp) => {
      if (resp.error === 0) {
        setFormPage(resp.data);
      }
    });
  };

  const export_excel = () => {
    let { status, promotionId, roleIds } = filter;
    let param = Object.fromEntries(
      Object.entries({
        status,
        promotionId,
        roleIds,
      }).filter(([_, v]) => v != null)
    ) as any;
    // if (roleId != null && roleId !== 0) {
    //   if (roleId === TypeUser.CONSTRACTOR) {
    //     param["roleIds"] = [roleId];
    //   } else {
    //     param["roleIds"] = [
    //       TypeUser.RETAILER,
    //       TypeUser.DISTRIBUTOR,
    //       TypeUser.DISTRIBUTOR_T2,
    //     ];
    //   }
    // }
    window.location.href =
      process.env.REACT_APP_DOMAIN +
      `/api/form/export-excel?${new URLSearchParams(param).toString()}`;
    return;
  };

  const serialize2ParamsUrl = () => {
    searchParams.set("q", JSON.stringify(filter));
    setSearchParams(searchParams);
  };

  useEffect(() => {
    findForms();
    serialize2ParamsUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    if (props.roleIds) {
      setFilter({ ...filter, roleIds: props.roleIds });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.roleIds]);

  return (
    <Layout>
      <main className="content">
        <div className="container-fluid">
          <div className="header">
            <h1 className="header-title">Đơn khuyến mãi chờ duyệt</h1>
          </div>
          <div className="row">
            <div className="col-12 col-xl-12">
              <div className="card">
                <div className="card-bar" style={{ padding: "10px" }}>
                  <div className="row ">
                    <div className="col-2 col-xl-2 ml-auto">
                      <input
                        className="form-control"
                        placeholder="SDT hoặc Tên"
                        type="text"
                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                          setFilter({
                            ...filter,
                            search: e.currentTarget.value,
                            status: [StockFormStatus.INIT],
                          } as FormFilter);
                        }}
                      />
                    </div>

                    <div className="mr-3">
                      <Button type="primary" onClick={export_excel}>
                        Xuất Excel
                      </Button>
                    </div>
                  </div>
                </div>
                <table className="table">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Thời gian</th>
                      <th></th>
                      <th>Cửa hàng / Nhà thầu</th>
                      <th>Nhà cung cấp</th>
                      <th>Số tấm</th>
                      <th>Số khung</th>
                      <th>Số bột</th>
                      <th>Trạng thái</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formPage &&
                      formPage.list &&
                      formPage.list.map((form, key) => {
                        const user = form.user;
                        let name;
                        let avatar;
                        if (user.roleId === TypeUser.CONSTRUCTOR) {
                          name = user.fullName;
                          avatar = user.avatar;
                        } else {
                          name = user.nameStore;
                          avatar = user.imageStore;
                        }
                        return (
                          <tr key={key}>
                            <td>{form.id}</td>
                            <td>
                              {DateTimeUtil.toString(form.createdTime * 1000)}
                            </td>
                            <td>
                              <img
                                alt=""
                                className="m-avatar"
                                src={avatar ? avatar : default_avatar}
                              />
                            </td>
                            <td>{name}</td>
                            <td>
                              {form.distributor && form.distributor.nameStore}
                            </td>
                            <td>{form.plate}</td>
                            <td>{form.frame}</td>
                            <td>{form.compound}</td>
                            <td>
                              <span
                                style={{
                                  backgroundColor: StockFormStatus.findColor(
                                    form.status
                                  ),
                                }}
                                className="badge badge-info"
                              >
                                {StockFormStatus.findName(form.status)}
                              </span>
                            </td>
                            <td className="table-action">
                              <Button
                                onClick={() => {
                                  navigate(
                                    `/${props.path}/form/${form.id}/rtl-ep01`
                                  );
                                }}
                                type="primary"
                              >
                                Chi tiết
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <div className="cart-paging">
                  {formPage && (
                    <Pagination
                      defaultCurrent={1}
                      current={filter.page + 1}
                      onChange={(pageNumber) => {
                        setFilter({ ...filter, page: pageNumber - 1 });
                      }}
                      total={formPage.totalPage * PAGE_SIZE}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}

export default ListWaitingFormsPromotion2023Page;
