import Layout from "../../../component/Layout";
import "react-responsive-modal/styles.css";
import "../../../component/popup/styles.scss";
import { useEffect, useState } from "react";
import { GotitVoucher, Page } from "../../../interface";
import * as GotitVoucherStatus from "../../../constant/status/GotitVoucherStatus";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import DateTimeUtil from "../../../utils/DateTimeUtil";
import { useSearchParams } from "react-router-dom";
import { Pagination } from "antd";
import LinkUtil from "../../../utils/LinkUtil";
import AlertUtils from "../../../utils/AlertUtils";
import ConfigConstant from "../../../constant/config";
import GotitVoucherModel from "../../../model/GotitVoucherModel";
import { UploadFilePopup } from "../../Promotion2023/gift-order/popup";
interface GotitVoucherFilter {
  code: string;
  status: number;
  page: number;
  pageSize: number;
}

const PAGE_SIZE = 10;
function ListGotitVoucherPage() {
  let [searchParams, setSearchParams] = useSearchParams();
  const [isUploadPopup, setIsUploadPopup] = useState(false);
  const [vouchers, setVouchers] = useState<Page<GotitVoucher>>();
  const [filter, setFilter] = useState<GotitVoucherFilter>(
    LinkUtil.initParam(searchParams)
  );

  const fetchVoucher = () => {
    GotitVoucherModel.find({
      code: filter.code,
      status: filter.status,
      page: filter.page,
      pageSize: PAGE_SIZE,
    }).then((resp) => {
      if (resp.error === 0) {
        setVouchers(resp.data);
      }
    });
  };

  const serialize2ParamsUrl = () => {
    searchParams.set("q", JSON.stringify(filter));
    setSearchParams(searchParams);
  };

  const submitUploadVoucher = (file) => {
    setIsUploadPopup(false);
    if (file) {
      GotitVoucherModel.upload(file).then((resp) => {
        if (resp.error < 0) {
          AlertUtils.showError(resp.msg);
        } else {
          AlertUtils.showSuccess("Upload thành công");
          fetchVoucher();
        }
      });
    }
  };

  useEffect(() => {
    fetchVoucher();
    serialize2ParamsUrl();
  }, [filter]);

  return (
    <Layout>
      <main className="content">
        <div className="container-fluid">
          <div className="header">
            <h1 className="header-title">Gotit Voucher</h1>
          </div>
          <div className="row">
            <div className="col-12 col-xl-12">
              <div className="card">
                <div className="card-bar" style={{ padding: "10px" }}>
                  <div style={{ paddingRight: "15px" }} className="row ">
                    <div className="col-2 col-xl-2">
                      <input
                        className="form-control mr-3"
                        placeholder="Code"
                        type="text"
                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                          setFilter({
                            code: e.currentTarget.value,
                          } as GotitVoucherFilter);
                        }}
                      />
                    </div>
                    <div className="col-2 col-xl-2 ml-auto flex-end">
                      <select
                        value={filter.status ? filter.status : 0}
                        onChange={(e: React.FormEvent<HTMLSelectElement>) => {
                          setFilter({
                            ...filter,
                            status: Number(e.currentTarget.value),
                          });
                        }}
                        className="form-control"
                      >
                        <option value={0}>Trạng thái</option>
                        <option value={GotitVoucherStatus.INIT}>
                          Available
                        </option>
                        <option value={GotitVoucherStatus.DISTRIBUTED}>
                          Distributed
                        </option>
                      </select>
                    </div>

                    <Button
                      style={{
                        backgroundColor: "#ff9800",
                        borderColor: "#ff9800",
                        color: "#fff",
                      }}
                      onClick={() => {
                        setIsUploadPopup(true);
                      }}
                    >
                      Upload Voucher
                    </Button>

                    <Button
                      style={{
                        marginLeft: '10px',
                        backgroundColor: "#1890ff",
                        borderColor: "#1890ff",
                        color: "#fff",
                      }}
                      onClick={() => {
                        window.location.href =
                          process.env.REACT_APP_DOMAIN +
                          `/api/gotit-voucher/export`;
                      }}
                    >
                      Export
                    </Button>
                  </div>
                </div>
                <table className="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Code</th>
                      <th>Giá trị</th>
                      <th>Ngày phát hành</th>
                      <th>Ngày hết hạn</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {vouchers &&
                      vouchers.list &&
                      vouchers.list.map((voucher, key) => {
                        return (
                          <tr key={key}>
                            <td>{voucher.id}</td>
                            <td>{voucher.code}</td>
                            <td>
                              {voucher.price.toLocaleString("it-IT", {
                                style: "currency",
                                currency: "VND",
                              })}
                            </td>
                            <td>{DateTimeUtil.toString(voucher.issueDate)}</td>
                            <td>
                              {DateTimeUtil.toString(voucher.expiredDate)}
                            </td>
                            <td>
                              <span
                                style={{
                                  backgroundColor: GotitVoucherStatus.findColor(
                                    voucher.status
                                  ),
                                }}
                                className="badge badge-info"
                              >
                                {GotitVoucherStatus.findName(voucher.status)}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <div className="cart-paging">
                  {vouchers && (
                    <Pagination
                      defaultCurrent={1}
                      current={filter.page + 1}
                      onChange={(pageNumber) => {
                        setFilter({ ...filter, page: pageNumber - 1 });
                      }}
                      total={vouchers.totalPage * PAGE_SIZE}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {isUploadPopup && (
        <UploadFilePopup
          open={isUploadPopup}
          onCloseModal={() => {
            setIsUploadPopup(false);
          }}
          onAgree={(file) => {
            submitUploadVoucher(file);
          }}
        />
      )}
    </Layout>
  );
}

export default ListGotitVoucherPage;
