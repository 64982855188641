import Layout from "../../../component/Layout";
import "../../../component/popup/styles.scss";
import { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Department, Employee, MessageFeedback, TopicFeedback, User } from "../../../interface";
import * as UserType from '../../../constant/type/UserType'
import MessageFeedbackModel from "../../../model/MessageFeedbackModel";
import * as MessageFeedbackStatus from "../../../constant/status/MessageFeedbackStatus";
import AlertUtils from "../../../utils/AlertUtils";
import DateTimeUtil from "../../../utils/DateTimeUtil";
import { Button, Popconfirm, Rate, Select, Space } from "antd";
import TopicFeedbackModel from "../../../model/TopicFeedbackModel";
import { City } from "../../../utils/ProvinceUtil";
import { LinkImages } from "../../../component/LinkImage";
import { SaveOutlined } from '@ant-design/icons';
import DepartmentModel from "../../../model/DepartmentModel";
import EmployeeModel from "../../../model/EmployeeModel";

const default_avatar = "http://cdn.onlinewebfonts.com/svg/img_264570.png";


function DetailMessageFeedbackPage() {
  let { id } = useParams();
  const [departments, setDepartments] = useState<Array<Department>>()
  const [employees, setEmployees] = useState<Array<Employee>>();
  const [topics, setTopics] = useState<Array<TopicFeedback>>();
  const [message, setMessage] = useState<MessageFeedback>();
  const [isLoading, setIsLoading] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null)


  const fetchMessage = (id: any) => {
    setIsLoading(true);
    MessageFeedbackModel.get({ id })
      .then((resp) => {
        if (resp.error === 0) {
          setMessage(resp.data);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const fetchTopics = () => {
    TopicFeedbackModel.list()
      .then(resp => {
        if (resp.error == 0) {
          setTopics(resp.data)
        }
      })
  }

  const fetchDepartments = () => {
    DepartmentModel.getList()
      .then(resp => {
        setDepartments(resp.data)
      })
  }

  const fetchEmployees = (departmentId) => {
    EmployeeModel.search(undefined, departmentId)
      .then(resp => {
        if (resp.error == 0) {
          setEmployees(resp.data);
        }
      })
  }

  const updateStatus = ({ id, status, note }) => {
    MessageFeedbackModel.updateStatus({ id, status, note })
      .then(resp => {
        if (resp.error == 0) {
          AlertUtils.showSuccess('Thành công')
          fetchMessage(id)
        }
      })
      .catch(e => {
        AlertUtils.showError('Có lỗi xảy ra')
      })
  };

  const update = (id: number) => {
    MessageFeedbackModel.update(id, message)
      .then(resp => {
        if (resp.error == 0) {
          window.location.reload()
        }
      })
      .catch(e => {
        AlertUtils.showError('Có lỗi xảy ra')
      })
  }

  const findTopic = (topicId: number) => {
    return topics && topics.find(_topic => _topic.id === topicId);
  }

  const isValidUpdate = () => {
    if (!message?.topicId || message.topicId <= 0) {
      AlertUtils.showError('Vui lòng chọn chủ đề')
      return false;
    }

    if (subTopics && subTopics.length > 0 && (!message?.subTopicIds || message.subTopicIds.length <= 0)) {
      AlertUtils.showError('Vui lòng chọn ít nhất một vấn đề')
      return false;
    }

    if (!message?.departmentId || message?.departmentId <= 0) {
      AlertUtils.showError("Vui lòng chọn phòng ban");
      return false;
    }

    if (!message?.employeeId || message?.employeeId <= 0) {
      AlertUtils.showError("Vui lòng chọn nhân viên")
      return false;
    }

    return true;
  }

  useEffect(() => {
    fetchDepartments()
    fetchMessage(id);
    fetchTopics()
  }, []);

  useEffect(() => {
    fetchEmployees(message?.departmentId)
  }, [message?.departmentId])


  const subTopics = useMemo(() => {
    let topicId = message?.topicId;
    if (topicId && topics) {
      let _topic = findTopic(topicId);
      return _topic && _topic.subs;
    }
  }, [message, topics])


  if (isLoading) {
    return <div></div>;
  }

  return (
    <Layout>
      <main className="content">
        <div className="container-fluid card">
          {message && (
            <div className="row profile">
              <div className="col-3">
                <div className="profile-sidebar">
                  <div className="profile-userpic">
                    <div className="text-center">
                      <img
                        alt=""
                        src={message?.user.avatar || default_avatar}
                        className="rounded-circle img-responsive mt-2"
                        width={300}
                        height={300}
                      />
                    </div>
                  </div>
                  <div className="profile-usertitle">
                    <div className="profile-usertitle-name">{message?.user?.name}</div>
                    <div className="profile-usertitle-job">
                      {UserType.findName(message?.user?.roleId)}
                    </div>
                  </div>

                  <div className="portlet light bordered">
                    <div>
                      <h4 className="profile-desc-title">Thông tin</h4>
                      <span className="profile-desc-text">Tài khoản zalo</span>
                      <div
                        className="margin-top-20 profile-desc-link"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span
                          className="uppercase profile-desc-title"
                          style={{ fontSize: "14px" }}
                        >
                          ID:
                        </span>
                        {message?.user?.id}
                      </div>
                      <div
                        className="margin-top-20 profile-desc-link"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span
                          className="uppercase profile-desc-title"
                          style={{ fontSize: "14px" }}
                        >
                          {UserType.findName(message.user.roleId)}
                        </span>
                        {message.user.nameStore || message.user.name}
                      </div>
                      <div
                        className="margin-top-20 profile-desc-link"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span
                          className="uppercase profile-desc-title"
                          style={{ fontSize: "14px" }}
                        >
                          Tỉnh thành
                        </span>
                        {City.getName(message?.user?.cityId) || ''}
                      </div>
                      <div
                        className="margin-top-20 profile-desc-link"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span
                          className="uppercase profile-desc-title"
                          style={{ fontSize: "14px" }}
                        >
                          SDT:
                        </span>
                        {message?.user?.phone}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-8">
                <div className="profile-content">
                  <div className="card box-shadow-none">
                    <div className="card-body">
                      {message && (
                        <form>
                          <div className="row">
                            <div className="col-12">
                              <div className="form-group">
                                <label htmlFor="inputUsername">Thời gian</label>
                                <input
                                  disabled
                                  className="form-control"
                                  type="text"
                                  value={DateTimeUtil.toString(message.createdTime * 1000)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6">
                              <div className="form-group">
                                <label htmlFor="inputUsername">Chủ đề</label>
                                {topics &&
                                  <select
                                    className="form-control"
                                    value={message?.topicId}
                                    onChange={(e) => {
                                      let __topicId: number = Number(e.target.value);
                                      setMessage({ ...message, topicId: __topicId, subTopicIds: [] })
                                    }}
                                  >
                                    {topics.map((_topic) => {
                                      return (
                                        <option value={_topic.id}>{_topic.title}</option>
                                      )
                                    })}
                                  </select>
                                }
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="form-group">
                                {subTopics &&
                                  <>
                                    <label htmlFor="inputUsername">Vấn đề</label>
                                    <Select
                                      mode="multiple"
                                      placeholder="Please select"
                                      value={message?.subTopicIds}
                                      onChange={(subIds) => {
                                        setMessage({
                                          ...message,
                                          subTopicIds: subIds
                                        })
                                      }}
                                      style={{
                                        width: '100%',
                                      }}
                                    >
                                      {subTopics && subTopics.map((_subTopic) => {
                                        return (
                                          <Select.Option key={_subTopic.id} value={_subTopic.id} >{_subTopic.title}</Select.Option>
                                        )
                                      })}
                                    </Select>
                                  </>
                                }
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6">
                              <div className="form-group">
                                <label htmlFor="inputUsername">Phòng ban</label>
                                <Select
                                  onChange={(_departmentId) => {
                                    setMessage({ ...message, departmentId: _departmentId, employeeId: undefined })
                                  }}
                                  value={message?.departmentId || undefined}
                                  style={{ display: 'block' }}
                                >
                                  {departments && departments.map((department, index) => {
                                    return (
                                      <Select.Option key={department.id} value={department.id} >{department.name}</Select.Option>
                                    )
                                  })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="form-group">
                                <label htmlFor="inputUsername">Nhân viên phụ trách</label>
                                <Select
                                  value={message?.employeeId}
                                  style={{ display: 'block' }}
                                  onChange={(_employeeId) => {
                                    setMessage({ ...message, employeeId: _employeeId })
                                  }}
                                >
                                  {employees && employees.map((employee, index) => {
                                    return (
                                      <Select.Option disabled={employee.status === -1}  key={employee.id} value={employee.id} >{employee.name}</Select.Option>
                                    )
                                  })}
                                </Select>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <div className="form-group">
                                <label htmlFor="inputUsername">Nội dung</label>
                                <textarea
                                  disabled
                                  rows={7}
                                  className="form-control"
                                  value={message?.content}
                                />
                              </div>
                              {message.images && message.images.length > 0 &&
                                <div className="form-group">
                                  <div>Hình ảnh đính kèm: <LinkImages images={message.images} /></div>
                                </div>
                              }
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <div className="form-group">
                                <label htmlFor="inputUsername">Phản hồi</label> {message.timeResponse && message.timeResponse > 0 && <span>: Thời gian: {`${DateTimeUtil.toString(new Date(message.timeResponse * 1000))}`}</span>}
                                <textarea
                                  disabled={message.status != MessageFeedbackStatus.INIT}
                                  onChange={(e) => {
                                    setMessage({ ...message, reply: e.target.value })
                                  }}
                                  value={message.reply || undefined}
                                  ref={textAreaRef}
                                  className="form-control"
                                  rows={7}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <div className="form-group">
                                <label style={{ paddingRight: '10px' }} htmlFor="inputUsername">Đánh giá</label>
                                <Rate value={message?.rating || 0} />
                                {message.note &&
                                  <textarea
                                    disabled={true}
                                    defaultValue={message.note || undefined}
                                    className="form-control"
                                    rows={3}
                                  />
                                }
                              </div>
                            </div>
                          </div>
                        </form>
                      )}
                    </div>

                    <div className="card-footer">
                      <Space wrap>
                        <Popconfirm
                          title="Bạn có chắc muốn lưu thay đổi này?"
                          onConfirm={() => {
                            if (isValidUpdate()) {
                              message && update(message?.id)
                            }
                          }}
                          onCancel={() => { }}
                          okText="Có"
                          cancelText="Không"
                        >
                          <Button type="primary" color="green-6" icon={<SaveOutlined />}>
                            Lưu
                          </Button>
                        </Popconfirm>

                        {message?.status == MessageFeedbackStatus.INIT &&
                          <Popconfirm
                            title="Bạn có chắc muốn gửi thông điệp này tới khách hàng"
                            onConfirm={() => {
                              updateStatus({
                                id: id,
                                status: MessageFeedbackStatus.RECEVIED,
                                note: textAreaRef.current?.value
                              });
                            }}
                            onCancel={() => { }}
                            okText="Có"
                            cancelText="Không"
                          >
                            <Button type="primary" className="btn-green">
                              Phản hồi
                            </Button>
                          </Popconfirm>
                        }

                        {message?.status != MessageFeedbackStatus.CLOSE &&
                          <Popconfirm
                            title="Bạn có chắc muốn đóng phản hồi này?"
                            onConfirm={() => {
                              updateStatus({
                                id: id,
                                status: MessageFeedbackStatus.CLOSE,
                                note: textAreaRef.current?.value
                              });
                            }}
                            onCancel={() => { }}
                            okText="Có"
                            cancelText="Không"
                          >
                            <Button type="primary" danger >
                              Đóng
                            </Button>
                          </Popconfirm>
                        }

                        <Button type="dashed"
                          onClick={() => {
                            window.open(`https://oa.zalo.me/chatv2?uid=${message?.user?.followerId}&oaid=2643103354901065124`, '_blank')
                          }}
                        >
                          Mở tool chat OA
                        </Button>
                      </Space>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </main>
    </Layout>
  );
}

export default DetailMessageFeedbackPage;
