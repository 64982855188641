export default class MatchFootballStatus {
    static INIT = 1;
    static PROCESSING = 2;
    static DONE = 3;
    static APPROVED = 4;

    static findName(value) {
        switch (value) {
            case this.INIT: return 'INIT'
            case this.PROCESSING: return 'PROCESSING'
            case this.DONE: return 'DONE'
            case this.APPROVED: return 'APPROVED'
        }

    }

    static findColor(value) {
        switch (value) {
            case this.INIT: return '#999'
            case this.APPROVED: return '#20c997'
        }
    }
}