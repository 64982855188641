export const CONSTRUCTOR = 2;
export const RETAILER = 3;
export const DISTRIBUTOR = 4;
export const DISTRIBUTOR_T2 = 5;

export function findName(value) {
    // eslint-disable-next-line default-case
    switch (value) {
        case CONSTRUCTOR: return 'Nhà thầu'
        case RETAILER: return 'Cửa hàng'
        case DISTRIBUTOR: return "Nhà Phân Phối"
        case DISTRIBUTOR_T2: return "Cửa hàng T2"
    }
}

export function findColor(value) {
    // eslint-disable-next-line default-case
    switch (value) {
        case CONSTRUCTOR: return '#F97B22'
        case RETAILER: return '#22A699'
        case DISTRIBUTOR: return '#28a745'
        case DISTRIBUTOR_T2: return "#19A7CE"
    }
}