import APIUtil from '../utils/APIUtils'

export default class GiftOrderModel {

    static create(formId, gift) {
        return new Promise((resolve, reject) => {
            APIUtil.postJSONWithCredentials(process.env.REACT_APP_DOMAIN + `/api/gift/create?formId=${formId}`, JSON.stringify(gift), resolve, reject);
        });
    }

    static count(status) {
        return new Promise((resolve, reject) => {
            APIUtil.getJSONWithCredentials(process.env.REACT_APP_DOMAIN + `/api/gift/count`, resolve, reject);
        });
    }

    static find({ search, promotionId, status, page, pageSize }) {
        var param = Object.fromEntries(Object.entries({
            search, promotionId, status, page, pageSize
        }).filter(([_, v]) => v != null));
        return new Promise((resolve, reject) => {
            APIUtil.getJSONWithCredentials(process.env.REACT_APP_DOMAIN + `/api/gift-order/find?${new URLSearchParams(param).toString()}`, resolve, reject);
        });
    }

    static get(id) {
        return new Promise((resolve, reject) => {
            APIUtil.getJSONWithCredentials(process.env.REACT_APP_DOMAIN + `/api/gift-order/get?id=${id}`, resolve, reject);
        });
    }

    static updateStatus(id, status, note) {
        var form = {
            status, note
        }
        return new Promise((resolve, reject) => {
            APIUtil.postJSONWithCredentials(process.env.REACT_APP_DOMAIN + `/api/gift-order/update-status?id=${id}`,
                JSON.stringify(form), resolve, reject);
        });
    }

    static countQtyGiftOrder(status, giftId, promotionId) {
        var param = {
            status, giftId, promotionId
        }
        return new Promise((resolve, reject) => {
            APIUtil.getJSONWithCredentials(process.env.REACT_APP_DOMAIN + `/api/gift-order/count?${new URLSearchParams(param).toString()}`, resolve, reject);
        });
    }

    static uploadGotITVoucher(data, promotionId) {
        const formData = new FormData();
        formData.append("file", data);
        return new Promise((resolve, reject) => {
            APIUtil.uploadFile(process.env.REACT_APP_DOMAIN + `/api/gift-order/upload-gotit-voucher?promotionId=${promotionId}`, formData, resolve, reject);
        });
    }

    static approvalContentFile(fileName, promotionId) {
        return new Promise((resolve, reject) => {
            APIUtil.getJSONWithCredentials(process.env.REACT_APP_DOMAIN + `/api/gift-order/approval/file?fileName=${fileName}&promotionId=${promotionId}`, resolve, reject);
        });
    }

}