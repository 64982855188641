import "react-responsive-modal/styles.css";
import "../../../component/popup/styles.scss";
import { useEffect, useState } from "react";

import Layout from "../../../component/Layout";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "antd";
import moment from "moment";
import TransactionWarehouseModel from "../../../model/TransactionWarehouseModel";
import AlertUtils from "../../../utils/AlertUtils";
import ConfigConstant from "../../../constant/config";
import * as TypeTransactionWarehouse from '../../../constant/type/TypeTransactionWarehouse'


interface ErrorFormUpdateWarehouse {
  errorPhoneInput: string,
  errorPlateInput: string,
  errorFrameInput: string,
  errorNoteInput: string,
  errorTypeSelect: string
}

function UpdateWarehousePromotion2023Page() {
  const navigate = useNavigate();
  const [error, setError] = useState<ErrorFormUpdateWarehouse>()
  const [timeSnapshot, set_timeSnapshot] = useState(moment());
  const [form, setForm] = useState<any>({
    plate: 0,
    frame: 0,
    compound: 0,
    mini8Plate: 0,
    phone: '',
    type: 0,
  });

  const changeTime = (data) => {
    if (data) {
      set_timeSnapshot(data);
      setForm({ ...form, timeSnapshot: data.toDate().getTime() });
    }

  };

  const submit = () => {
    setError(undefined)
    if (!form.type || form.type <= 0) {
      setError({ ...form, errorTypeSelect: 'Vui lòng chọn loại transaction' })
      return;
    }

    if (form.type == TypeTransactionWarehouse.CREDIT_RECALL && !form.note) {
      setError({ ...form, errorNoteInput: 'Vui lòng cho biết lý do thu hồi' })
      return;
    }

    if (!form.phone || form.phone <= 0) {
      setError({ ...form, errorPhoneInput: 'Vui lòng nhập số điện thoại' })
      return;
    }

    if (form.plate <= 0 && form.frame <= 0 && form.compound <= 0 && form.mini8Plate <= 0) {
      setError({ ...form, errorPlateInput: 'Vui lòng nhập số  tấm, khung hoặc bột' })
      return;
    }

    TransactionWarehouseModel.create({
      type: form.type,
      note: form.note,
      phone: form.phone,
      plate: form.plate,
      frame: form.frame,
      compound: form.compound,
      mini8Plate: form.mini8Plate,
      promotionId: ConfigConstant.INVOICE_PROMOTION_2023.promotionId
    }).then(resp => {
      if (resp.error == 0) {
        AlertUtils.showSuccess("Thành công")
        navigate(-1)
      } else {
        AlertUtils.showError(resp.msg)
      }
    })

  }

  return (
    <Layout>
      <main className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-xl-12">
              <div className="tab-content">
                <div
                  className="tab-pane fade show active"
                  id="account"
                  role="tabpanel"
                >
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title mb-0">Cập nhật thêm kho</h5>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-6">
                          <div className="form-group">
                            <label>Loại Transaction</label>
                            <select value={form.type | 0} onChange={(event) => {
                              setForm({ ...form, type: event.target.value })
                            }} className="custom-select mb-3">
                              <option value={0}>Chọn Loại transaction</option>
                              <option value={TypeTransactionWarehouse.DEBIT}>Nhập kho</option>
                              <option value={TypeTransactionWarehouse.CREDIT_RECALL}>Xuất kho ( hoàn lại )</option>
                            </select>
                            {error?.errorTypeSelect && <p className="error">{error?.errorTypeSelect}</p>}
                          </div>
                          {form.type == TypeTransactionWarehouse.CREDIT_RECALL &&
                            <div className="form-group">
                              <label>Note</label>
                              <textarea
                                className="form-control"
                                value={form.note}
                                onChange={(event) => {
                                  setForm({ ...form, note: event.target.value })
                                }}
                              />
                              {error?.errorNoteInput && <p className="error">{error?.errorNoteInput}</p>}
                            </div>
                          }
                          <div className="form-group">
                            <label>Số điện thoại</label>
                            <input
                              type="text"
                              className="form-control"
                              value={form.phone}
                              onChange={(event) => {
                                setForm({ ...form, phone: event.target.value })
                              }}
                            />
                            {error?.errorPhoneInput && <p className="error">{error?.errorPhoneInput}</p>}
                          </div>
                          <div className="form-group">
                            <label>Số Tấm</label>
                            <input
                              type="number"
                              className="form-control"
                              value={form.plate}
                              onChange={(event) => {
                                setForm({ ...form, plate: Number(event.target.value) })
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <label>Số Khung</label>
                            <input
                              type="number"
                              className="form-control"
                              value={form.frame}
                              onChange={(event) => {
                                setForm({ ...form, frame: Number(event.target.value) })
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <label>Số Bột</label>
                            <input
                              type="number"
                              className="form-control"
                              value={form.compound}
                              onChange={(event) => {
                                setForm({ ...form, compound: Number(event.target.value) })
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <label>Số Tấm Mini 8mm</label>
                            <input
                              type="number"
                              className="form-control"
                              value={form.mini8Plate}
                              onChange={(event) => {
                                setForm({ ...form, mini8Plate: Number(event.target.value) })
                              }}
                            />
                          </div>
                          {error?.errorPlateInput && <p className="error">{error?.errorPlateInput}</p>}
                          <button
                            className="btn btn-primary m-btn-primary"
                            style={{ float: "right" }}
                            onClick={submit}
                          >
                            Save
                          </button>
                        </div>
                        <div className="col-6"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}

export default UpdateWarehousePromotion2023Page;
