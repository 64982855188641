import Layout from "../../../component/Layout";
import "react-responsive-modal/styles.css";
import "../../../component/popup/styles.scss";
import { useEffect, useState } from "react";
import {
  TopicStatistical,
} from "../../../interface";
import {
  RatingTopicBarPercentChart,
  RatingTopicMessageFeedbackDateMetricChart,
  StatusMessageFeedbackDateMetricChart,
  TopicMessageFeedbackDateMetricChart,
} from "../../../component/chart";
import MessageFeedbackModel from "../../../model/MessageFeedbackModel";

function DashboardFeedbackPage() {
  const [stats, setStats] = useState<TopicStatistical>();
  const [statusDateStats, setStatusDateStats] = useState<Array<TopicStatistical>>()
  const [statsByTopicDate, setStatsByTopicDate] = useState<Array<any>>()
  const [statsRatingByTopicMonth, setStatsRatingByTopicMonth] = useState<Array<any>>()
  const [statsRatingTopic, setStatsRatingTopic] = useState<Array<any>>()



  const fetchStats = () => {
    MessageFeedbackModel.stats({
      topicId: 0,
      subTopicId: 0
    }).then((resp) => {
      if (resp.error == 0) {
        setStats(resp.data)
      }
    })
  }

  const fetchStatsByStatusDate = () => {
    MessageFeedbackModel.statsByStatusDate()
      .then(resp => {
        setStatusDateStats(resp.data)
      })
  }

  const fetchStatsByTopicDate = () => {
    MessageFeedbackModel.statsByTopicDate()
      .then(resp => {
        setStatsByTopicDate(resp.data)
      })
  }

  const fetchStatsByRatingMonth = () => {
    MessageFeedbackModel.statsByRatingMonth()
      .then(resp => {
        setStatsRatingByTopicMonth(resp.data)
      })
  }

  const fetchStatsRatingTopic = () => {
    MessageFeedbackModel.statsByRating()
      .then(resp => {
        setStatsRatingTopic(resp.data)
      })
  }

  useEffect(() => {
    fetchStats();
    fetchStatsByStatusDate()
    fetchStatsByTopicDate()
    fetchStatsByRatingMonth()
    fetchStatsRatingTopic()
  }, []);


  return (
    <Layout>
      <main className="content">
        <div className="container-fluid">
          <div className="header row" style={{ marginBottom: "20px" }}>
            <div className="col-6">
              <h1 className="header-title">Tổng quan</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-3 col-xl">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col mt-0">
                      <h5 className="card-title">Đã tiếp nhận</h5>
                    </div>
                    <div className="col-auto">
                      <div className="avatar">
                        <div className="avatar-title rounded-circle bg-primary-dark">
                          <i className="align-middle fas fa-fw fa-store"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h1 className="display-5 mt-1 mb-3">
                    {stats?.total}
                  </h1>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-3 col-xl">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col mt-0">
                      <h5 className="card-title">Đang xử lý</h5>
                    </div>
                    <div className="col-auto">
                      <div className="avatar">
                        <div className="avatar-title rounded-circle bg-primary-dark">
                          <i className="align-middle fas fa-fw  fa-ad"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h1 className="display-5 mt-1 mb-3">
                    {stats?.init}
                  </h1>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-3 col-xl">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col mt-0">
                      <h5 className="card-title">Đã phản hồi</h5>
                    </div>
                    <div className="col-auto">
                      <div className="avatar">
                        <div className="avatar-title rounded-circle bg-primary-dark">
                          <i className="align-middle far fa-fw fa-heart"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h1 className="display-5 mt-1 mb-3">
                    {stats?.received}
                  </h1>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-3 col-xl">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col mt-0">
                      <h5 className="card-title">Đóng</h5>
                    </div>
                    <div className="col-auto">
                      <div className="avatar">
                        <div className="avatar-title rounded-circle bg-primary-dark">
                          <i className="align-middle fas fa-fw fa-gift"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h1 className="display-5 mt-1 mb-3">
                    {stats?.close}
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-12 col-xxl-12 d-flex">
              <div className="card flex-fill w-100">
                <div className="card-header">
                  <h5 className="card-title mb-0">
                    Phản hồi theo tháng
                  </h5>
                </div>
                <div className="card-body py-3">
                  {statusDateStats && <StatusMessageFeedbackDateMetricChart data={statusDateStats} />}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-12 col-md-12 col-xxl-12 d-flex">
              <div className="card flex-fill w-100">
                <div className="card-header">
                  <h5 className="card-title mb-0">
                    Số lượng phản hồi theo chủ đề
                  </h5>
                </div>
                <div className="card-body px-4">
                  {statsByTopicDate && <TopicMessageFeedbackDateMetricChart data={statsByTopicDate} />}
                </div>
              </div>
            </div>
          </div> */}
          <div className="row">
            <div className="col-12 col-md-12 col-xxl-12 d-flex">
              <div className="card flex-fill w-100">
                <div className="card-header">
                  <h5 className="card-title mb-0">
                    Đánh giá sự hài lòng
                  </h5>
                </div>
                <div className="card-body px-4">
                  {statsRatingTopic && <RatingTopicBarPercentChart content={statsRatingTopic} />}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-12 col-xxl-12 d-flex">
              <div className="card flex-fill w-100">
                <div className="card-header">
                  <h5 className="card-title mb-0">
                    Phân loại phản hồi
                  </h5>
                </div>
                <div className="card-body px-4">
                  {statsRatingByTopicMonth && <RatingTopicMessageFeedbackDateMetricChart data={statsRatingByTopicMonth} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}

export default DashboardFeedbackPage;
