export const CARD_PHONE = 1;
export const VOUCHER = 2;
export const POINT_REWARD = 3;
export const LUCKY_SPIN = 4;
export const LUCKY_DRAW = 5;


export const findByName = (value) => {
    switch(value) {
        case VOUCHER: {
            return 'Vocuher';
        }
        case POINT_REWARD: {
            return 'Đổi điểm';
        }
        case LUCKY_SPIN: {
            return 'Vòng quay may mắn';
        }
        case LUCKY_DRAW: {
            return 'Quay số  may mắn'
        }
    }
}