import Layout from "../../../component/Layout";
import { useEffect, useState } from "react";
import { Gift, GiftFilter, Promotion } from "../../../interface";
import { useSearchParams } from "react-router-dom";
import { List, Tag } from "antd";
import LinkUtil from "../../../utils/LinkUtil";
import GiftModel from "../../../model/GiftModel";
import PromotionModel from "../../../model/PromotionModel";
import * as StatusPromotion from '../../../constant/status/PromotionStatus'
import * as TypeGift from '../../../constant/type/GiftType'
import { RollbackOutlined } from "@ant-design/icons";

function ListGiftPage() {
  const [showSelectPromotion, setShowSelectPromotion] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams();
  const [gifts, setGifts] = useState<Array<Gift>>();
  const [promotion, setPromotion] = useState<Promotion>()
  const [filter, setFilter] = useState<GiftFilter>({ ...LinkUtil.params2Object(searchParams) });


  useEffect(() => {
    let _promotionId = window.localStorage.getItem('promotionId')
    if (_promotionId) {
      searchParams.set('promotionId', _promotionId);
      setSearchParams(searchParams)
    }
  }, [])

  const fetchData = () => {
    GiftModel.find(filter.promotionId).then(resp => {
      if (resp.error === 0) {
        setGifts(resp.data);
      }
    })
  };

  const fetchPromotion = (promotionId) => {
    PromotionModel.get(promotionId)
      .then(resp => {
        if (resp.error === 0) {
          setPromotion(resp.data)
        }
      })
  }

  useEffect(() => {
    setFilter({ ...LinkUtil.params2Object(searchParams) })
  }, [searchParams])


  useEffect(() => {
    if (filter.promotionId && filter.promotionId > 0) {
      fetchPromotion(filter.promotionId)
      fetchData();
      setShowSelectPromotion(false)
    } else {
      setShowSelectPromotion(true)
    }
  }, [filter]);


  return (
    <Layout>
      <main className="content">
        {showSelectPromotion &&
          <PromotionSelection onChange={(id) => {
            setShowSelectPromotion(false)
            searchParams.set('promotionId', id + '');
            setSearchParams(searchParams)
            window.localStorage.setItem('promotionId', id + '')
          }} promotionId={filter.promotionId || 0} />
        }
        {!showSelectPromotion &&
          <div className="container-fluid">
            <div className="header">
              <h1 className="header-title">{promotion?.title} <RollbackOutlined onClick={() => { setShowSelectPromotion(true) }} /></h1>
            </div>
            <div className="row">
              <div className="col-12 col-xl-12">
                <div className="card">
                  <div className="card-bar" style={{ padding: "10px" }}>
                    <div className="row" >
                      <div className="col-4" style={{ textAlign: 'left' }}>
                        <div className="mr-3">
                          <Tag color={StatusPromotion.findColor(promotion?.status)}>{StatusPromotion.findName(promotion?.status)}</Tag>
                        </div>
                      </div>
                      <div className="col-8" style={{ textAlign: 'right' }}>
                      </div>
                    </div>
                  </div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th></th>
                        <th>Tên</th>
                        <th>Số lượng</th>
                        <th>Số điểm</th>
                        <th>Loại</th>
                      </tr>
                    </thead>
                    <tbody>
                      {gifts &&
                        gifts &&
                        gifts.map((gift, key) => {
                          return (
                            <tr key={key}>
                              <td>{gift.id}</td>
                              <td> <img className="m-avatar" src={gift?.link} /> </td>
                              <td>{gift.name}</td>
                              <td>{gift?.cap || '---'}</td>
                              <td>{gift?.point || '---'}</td>
                              <td>{TypeGift.findByName(gift.type)}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        }
      </main>
    </Layout>
  );
}
export default ListGiftPage;


// ------------------

// ------------------
type PromotionSelectionProps = {
  promotionId: number;
  onChange: (id: number) => void;
}

const PromotionSelection = ({ promotionId, onChange }: PromotionSelectionProps) => {
  const [promotions, setPromotions] = useState<Array<Promotion>>()

  const fetchPromotions = () => {
    PromotionModel.find({
      types: [61],
      page: 0,
      pageSize: 100
    }).then((resp) => {
      if (resp.error === 0) {
        setPromotions(resp.data.list);
      }
    });
  }

  useEffect(() => {
    fetchPromotions()
  }, [])

  return <>
    <div className="container-fluid">
      <div className="header">
        <h1 className="header-title">Vui lòng chọn sự kiện</h1>
      </div>
      <div className="row">
        <div className="col-5 col-xl-5 mx-auto">
          <div className="card">
            <div className="card-bar" style={{ padding: "10px" }}>
              <div className="row" style={{ padding: '20px' }} >
                <List
                  style={{ width: '100%' }}
                  size="large"
                  bordered
                  dataSource={promotions}
                  renderItem={(item) => <List.Item>
                    <div onClick={() => {
                      onChange(item.id)
                    }} style={{ fontWeight: promotionId == item.id ? '600' : 'normal', display: 'flex', justifyContent: 'space-between', width: '100%', cursor: 'pointer' }}>
                      <span>
                        {item.title}
                      </span>
                      <span>{`>`}</span>
                    </div>
                  </List.Item>}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </>
}
