import React, { useEffect } from "react";
import "react-responsive-modal/styles.css";
import "../../../component/popup/styles.scss";
import { useState } from "react";

import Layout from "../../../component/Layout";
import { useNavigate, useParams } from "react-router-dom";
import { Promotion } from "../../../interface";
import DateTimeUtil from "../../../utils/DateTimeUtil";
import PromotionModel from "../../../model/PromotionModel";
import AlertUtils from "../../../utils/AlertUtils";
import { Button, DatePicker, DatePickerProps, Modal, Space } from "antd";
import QRCode from 'qrcode.react';
import 'dayjs/locale/vi'
import dayjs from 'dayjs';
import { RangePickerProps } from "antd/lib/date-picker";
import moment from "moment";

interface ErrorFormUpdateWarehouse {
    errorTitleInput: string,
    errorTimeStartInput: string,
    errorTimeEndInput: string,
    errorNameContentInput: string,
    errorPlaceContentInput: string
    errorTimeContentInput: string
}



function DetailPromotionPage() {
    const { id } = useParams()
    const navigate = useNavigate();
    const [error, setError] = useState<ErrorFormUpdateWarehouse>({} as ErrorFormUpdateWarehouse)
    const [form, setForm] = useState<Promotion>({ type: 61, content: { type: 61 } } as Promotion);
    const [qrModal, setQRModal] = useState(false)


    const submit = () => {
        if (!form.title) {
            setError({ ...error, errorTitleInput: 'Vui lòng nhập tên chương trình' })
            return;
        }

        if (!form.title) {
            setError({ ...error, errorTimeStartInput: 'Vui lòng nhập thời gian bắt đầu' })
            return;
        }

        if (!form.title) {
            setError({ ...error, errorTimeEndInput: 'Vui lòng nhập thời gian kết thúc' })
            return;
        }

        if (!form.title) {
            setError({ ...error, errorNameContentInput: 'Vui lòng nhập tên sự kiện' })
            return;
        }

        if (!form.title) {
            setError({ ...error, errorPlaceContentInput: 'Vui lòng nhập địa điểm tổ chức sự kiện' })
            return;
        }

        if (!form.title) {
            setError({ ...error, errorTimeContentInput: 'Vui lòng nhập thời gian tổ chức sự kiện' })
            return;
        }

        PromotionModel.save(form).then(resp => {
            if (resp.error == 0) {
                AlertUtils.showSuccess("Thành công")
                navigate(-1)
            } else {
                AlertUtils.showError(resp.msg)
            }
        })
    }

    const fetchById = (id) => {
        PromotionModel.get(id)
            .then(resp => {
                if (resp.error === 0) {
                    setForm(resp.data);
                }
            })
    }

    useEffect(() => {
        if (id && +id > 0) {
            fetchById(id)
        }
    }, [])


    return (
        <Layout>
            <main className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 col-xl-12">
                            <div className="tab-content">
                                <div
                                    className="tab-pane fade show active"
                                    id="account"
                                    role="tabpanel"
                                >
                                    <div className="card">
                                        <div className="card-header">
                                            <h5 className="card-title mb-0">Thêm chương trình mới</h5>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>Type</label>
                                                        <select value={form?.type || 61} onChange={(event) => {
                                                            setForm({ ...form, type: Number(event.target.value) })
                                                        }} className="custom-select">
                                                            <option value={61}>Sự kiện</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Title</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={form.title}
                                                            onChange={(event) => {
                                                                setForm({ ...form, title: event.target.value })
                                                            }}
                                                        />
                                                        {error?.errorTitleInput && <p className="error">{error?.errorTitleInput}</p>}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Thời gian bắt đầu</label>
                                                        <input
                                                            type="date"
                                                            min="2023-01-01" max="2030-12-30"
                                                            className="form-control"
                                                            value={DateTimeUtil.formatDateDefaultBrowser(DateTimeUtil.initDate(form.timeStart))}
                                                            onChange={(event) => {
                                                                setForm({ ...form, timeStart: event.target.valueAsNumber })
                                                            }}
                                                        />
                                                        {error?.errorTimeStartInput && <p className="error">{error?.errorTimeStartInput}</p>}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Thời gian kết thúc</label>
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            value={DateTimeUtil.formatDateDefaultBrowser(DateTimeUtil.initDate(form.timeEnd))}
                                                            onChange={(event) => {
                                                                setForm({ ...form, timeEnd: event.target.valueAsNumber })
                                                            }}
                                                        />
                                                        {error?.errorTimeEndInput && <p className="error">{error?.errorTimeEndInput}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>Tên chương trình</label>
                                                        <input
                                                            value={form.content?.["name"] || ''}
                                                            type="text"
                                                            className="form-control"
                                                            onChange={(event) => {
                                                                let content = form.content;
                                                                setForm({
                                                                    ...form,
                                                                    content: {
                                                                        ...content,
                                                                        name: event.target.value
                                                                    }
                                                                })
                                                            }}
                                                        />
                                                        {error?.errorNameContentInput && <p className="error">{error?.errorNameContentInput}</p>}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Địa điểm tổ chức</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={form.content?.["place"] || ''}
                                                            onChange={(event) => {
                                                                let content = form.content;
                                                                setForm({
                                                                    ...form,
                                                                    content: {
                                                                        ...content,
                                                                        place: event.target.value
                                                                    }
                                                                })
                                                            }}
                                                        />
                                                        {error?.errorPlaceContentInput && <p className="error">{error?.errorPlaceContentInput}</p>}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Thời gian tổ chức</label>
                                                        <div>
                                                            <DatePicker
                                                                style={{ width: '100%' }}
                                                                value={moment(form?.content?.["time"])}
                                                                format="YYYY-MM-DD HH:mm"
                                                                showTime={{ format: 'HH:mm' }}
                                                                onChange={(value: DatePickerProps['value'] | RangePickerProps['value']) => {
                                                                    let content = form.content;
                                                                    setForm({
                                                                        ...form,
                                                                        content: {
                                                                            ...content,
                                                                            time: value?.valueOf()
                                                                        }
                                                                    })
                                                                }}
                                                            />
                                                        </div>

                                                        {error?.errorTimeContentInput && <p className="error">{error?.errorTimeContentInput}</p>}
                                                    </div>
                                                </div>

                                            </div>
                                            <div style={{ float: 'right' }} className="row">
                                                {id && +id > 0 && form.title &&
                                                    <Button onClick={() => {
                                                        setQRModal(true)
                                                    }}>
                                                        QR CODE
                                                    </Button>
                                                }
                                                <button
                                                    className="btn btn-primary m-btn-primary"
                                                    style={{ float: "right", marginLeft: '30px' }}
                                                    onClick={submit}
                                                >
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {id && +id > 0 && form.title &&
                    <QRCodeModal
                        name={form.title}
                        promotionId={id}
                        open={qrModal}
                        onClose={() => {
                            setQRModal(false)
                        }} />
                }
            </main>

        </Layout>
    );
}

export default DetailPromotionPage;



const QRCodeModal = ({ open, onClose, promotionId, name }) => {

    const downloadQR = (id) => {
        const canvas = document.getElementById(id) as HTMLCanvasElement;
        const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
        let downloadLink = document.createElement('a');
        downloadLink.href = pngUrl;
        downloadLink.download = `${id}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    const genName = (type: string) => {
        if ("register" === type) {
            return `${name}-DANG-KY-${promotionId}`;
        }
        return `${name}-CHECKIN-${promotionId}`;

    }

    return (
        <>
            <Modal width={800} open={open} onCancel={onClose}>
                <div className="row" style={{ textAlign: 'center' }}>
                    <div className="col-6">
                        <h3>QR Đăng ký</h3>
                        <div style={{ display: 'none' }}>
                            <QRCode
                                id={genName("register")}
                                value={`https://zalo.me/s/1374515648362638464/event/${promotionId}/register`}
                                size={1800}
                                level={'H'}
                            />
                        </div>

                        <QRCode
                            value={`https://zalo.me/s/1374515648362638464/event/${promotionId}/register`}
                            size={300}
                            level={'H'}
                        />
                        <Button onClick={() => { downloadQR(genName('register')) }}>Download</Button>
                    </div>
                    <div className="col-6">
                        <h3>QR Checkin</h3>
                        <div style={{ display: 'none' }}>
                            <QRCode
                                id={genName("checkin")}
                                value={`https://zalo.me/s/1374515648362638464/event/${promotionId}/checkin`}
                                size={1800}
                                level={'H'}
                            />
                        </div>

                        <QRCode
                            value={`https://zalo.me/s/1374515648362638464/event/${promotionId}/checkin`}
                            size={300}
                            level={'H'}
                        />
                        <Button onClick={() => { downloadQR(genName('checkin')) }}>Download</Button>
                    </div>
                </div>


            </Modal>
        </>

    )
}

