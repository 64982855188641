export const DEBIT = 1;
export const CREDIT = 2;
export const DEBIT_RECALL = 20;
export const CREDIT_RECALL = 10;

export function getIcon(type) {
    switch (type) {
        case DEBIT:
            return 'https://udev.com.vn/static/upload/istockphoto-1225034279-170667a-(1)-1668307071193.jpeg';
        case CREDIT:
            return 'https://udev.com.vn/static/upload/istockphoto-1225034279-170667a-(2)-1668306664980.jpeg';
    }
}

export function getName(type) {
    switch (type) {
        case DEBIT:
            return "Nhập Kho";
        case CREDIT:
            return "Xuất Kho";
        case DEBIT_RECALL:
            return "Nhập kho (Hoàn lại)"
        case CREDIT_RECALL:
            return "Xuất kho (Hoàn lại)"
    }
}

export function getColor(type) {
    switch (type) {
        case DEBIT:
            return "#28a745";
        case CREDIT:
            return "#ff4d4f";
        case DEBIT_RECALL:
            return "#28a745";
        case CREDIT_RECALL:
            return "#ff4d4f";

    }
}