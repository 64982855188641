import Layout from "../../../component/Layout";
import { useEffect, useState } from "react";
import { MessageFeedback, MessageFeedbackFilter, Page, User, UserFilter } from "../../../interface";
import UserModel from "../../../model/UserModel";
import * as MessageFeedbackStatus from "../../../constant/status/MessageFeedbackStatus";
import { useSearchParams } from "react-router-dom";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { Pagination } from "antd";
import LinkUtil from "../../../utils/LinkUtil";
import MessageFeedbackModel from "../../../model/MessageFeedbackModel";
import ConfigConstant from "../../../constant/config";
import DateTimeUtil from "../../../utils/DateTimeUtil";
import { LeadTime } from "../component/LeadTime";

const default_avatar = "http://cdn.onlinewebfonts.com/svg/img_264570.png";
const PAGE_SIZE = 20;
function ListMessageFeedbackPage(props: any) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [messagePages, setMessagePages] = useState<Page<MessageFeedback>>();
  const [filter, setFilter] = useState<MessageFeedbackFilter>({ ...LinkUtil.params2Object(searchParams), page: 0 });


  const [count, setCount] = useState<any>({});

  const fetchListMessage = () => {
    MessageFeedbackModel.find({
      topicId: filter.topicId,
      subTopicId: filter.subTopicId,
      status: filter.status,
      page: filter.page,
      pageSize: PAGE_SIZE
    }).then((resp) => {
      if (resp.error === 0) {
        setMessagePages(resp.data);
      }
    });
  };

  useEffect(() => {
    setFilter({ ...LinkUtil.params2Object(searchParams) })
  }, [searchParams])


  const countUser = () => {
    UserModel.count().then((resp) => {
      if (resp.error === 0) {
        setCount(resp.data);
      }
    });
  };



  useEffect(() => {
    fetchListMessage();
  }, [filter]);


  useEffect(() => {
    countUser();
  }, []);

  return (
    <Layout>
      <main className="content">
        <div className="container-fluid">
          <div className="header">
            <h1 className="header-title">Khiếu nại</h1>
          </div>
          <div className="row">
            <div className="col-12 col-xl-12">
              <div className="card">
                <div className="card-bar" style={{ padding: "10px" }}>
                  <div className="row ">
                    <div className="ml-auto col-2 col-xl-2 ">
                      <select
                        value={filter.status ? filter.status : 0}
                        onChange={(e: React.FormEvent<HTMLSelectElement>) => {
                          setFilter({
                            ...filter,
                            status: Number(e.currentTarget.value),
                          });
                        }}
                        className="form-control"
                      >
                        <option value={0}>Đã tiếp nhận</option>
                        <option value={MessageFeedbackStatus.INIT}>Đang xử lý</option>
                        <option value={MessageFeedbackStatus.RECEVIED}>
                          Đã phản hồi
                        </option>
                        <option value={MessageFeedbackStatus.CLOSE}>
                          Đóng
                        </option>
                      </select>
                    </div>
                    <div className="mr-3">
                      <Button type={"primary"} onClick={() => {
                        window.location.href =
                          process.env.REACT_APP_DOMAIN +
                          `/api/feedback/message/export`;
                      }}>
                        Xuất Excel
                      </Button>
                    </div>
                  </div>
                </div>
                <table className="table">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th></th>
                      <th>Tên</th>
                      <th>SDT</th>
                      <th>Chủ đề</th>
                      <th>Phòng ban</th>
                      <th>Thời gian tiếp nhận</th>
                      <th>Chờ xử lý</th>
                      <th>Trạng thái</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {messagePages &&
                      messagePages.list &&
                      messagePages.list.map((message, key) => {
                        return (
                          <tr key={key}>
                            <td>{message.id}</td>
                            <td>
                              <img
                                className="m-avatar"
                                alt=""
                                src={message?.user?.avatar || default_avatar}
                              />
                            </td>
                            <td>{message?.user?.name}</td>
                            <td>{message?.user?.phone}</td>
                            <td>{message?.topic?.title}</td>
                            <td>{message?.department?.name}</td>
                            <td>{DateTimeUtil.toString(message?.createdTime * 1000)}</td>
                            <td><LeadTime message={message} /></td>
                            <td>
                              <span
                                style={{
                                  backgroundColor: MessageFeedbackStatus.findColor(
                                    message.status
                                  ),
                                }}
                                className="badge badge-info"
                              >
                                {MessageFeedbackStatus.findName(message.status)}
                              </span>
                            </td>
                            <td className="table-action">
                              <Button
                                onClick={() => {
                                  navigate(
                                    `/${ConfigConstant.FEEDBACK.key}/message/${message.id}`
                                  );
                                }}
                                type="primary"
                              >
                                Chi tiết
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <div className="cart-paging">
                  {messagePages && (
                    <Pagination
                      defaultCurrent={1}
                      defaultPageSize={PAGE_SIZE}
                      current={(filter.page || 0) + 1}
                      onChange={(pageNumber) => {
                        setFilter({ ...filter, page: pageNumber - 1 });
                      }}
                      showSizeChanger={false}
                      total={messagePages.totalPage * PAGE_SIZE}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}

export default ListMessageFeedbackPage;
