import APIUtil from "../utils/APIUtils";

const domain = process.env.REACT_APP_DOMAIN;

export default class WarehouseModel {

    static find(search, promotionId, page, pageSize) {
        var param = Object.fromEntries(Object.entries({
            search, promotionId, page, pageSize
        }).filter(([_, v]) => v != null && v != 0));

        return new Promise((resolve, reject) => {
            APIUtil.getJSONWithCredentials(domain + `/api/warehouse/find?${new URLSearchParams(param).toString()}`, resolve, reject);
        });
    }

    static get(userId, promotionId) {
        return new Promise((resolve, reject) => {
            APIUtil.getJSONWithCredentials(domain + `/api/warehouse/get?userId=${userId}&promotionId=${promotionId}`, resolve, reject);
        });
    }

    static statsRelationship(promotionId) {
        return new Promise((resolve, reject) => {
            APIUtil.getJSONWithCredentials(domain + `/api/warehouse/stats-relationship?promotionId=${promotionId}`, resolve, reject);
        });
    }

}