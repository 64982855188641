import APIUtil from "../utils/APIUtils";

export default class TopicFeedbackModel {

    static find({ topicId, subTopicId }) {
        var param = Object.fromEntries(
            Object.entries({
                topicId,
                subTopicId,
            }).filter(([_, v]) => v != null && v != 0)
        );

        return new Promise((resolve, reject) => {
            APIUtil.getJSONWithCredentials(
                process.env.REACT_APP_DOMAIN +
                `/api/feedback/topic/find?${new URLSearchParams(param).toString()}`,
                resolve,
                reject
            );
        });
    }

    static list() {
        return new Promise((resolve, reject) => {
            APIUtil.getJSONWithCredentials(
                process.env.REACT_APP_DOMAIN +
                `/api/feedback/topic/list`,
                resolve,
                reject
            );
        });
    }

}