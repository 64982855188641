import React, { useRef } from "react";
import "react-responsive-modal/styles.css";
import "../../../component/popup/styles.scss";
import { useEffect, useState } from "react";
import { RtlEp01Form } from "../../../interface";
import {
  AreYouSurePopup,
  AreYouSureWithNoteDropdownPopup,
  AreYouSureWithNotePopup,
} from "../../../component/popup";
import FormModel from "../../../model/FormModel";
import AlertUtils from "../../../utils/AlertUtils";
import * as StockFormStatus from "../../../constant/status/FormStatus";
import Layout from "../../../component/Layout";
import { useParams, useNavigate } from "react-router-dom";
import { DatePicker } from "antd";
import moment from "moment";
import { Image } from "antd";
import { SelectDistributor } from "../../../component/common/distributor_select";
import * as UserType from '../../../constant/type/UserType'
import "./form.css";
import { useNameUser } from "../../../component/profile/NameUser";
import { City, District } from "../../../utils/ProvinceUtil";
import domToPdf from 'dom-to-pdf'
import * as htmlToImage from "html-to-image";
import { LinkImage } from "../../../component/LinkImage";

let list_reason_form_reject = [
  "Hóa đơn của Qúy Khách bị mờ, vui lòng kiểm tra và đăng lên lại nhé",
  "Hóa đơn của Qúy Khách bị thiếu thông tin nhà phân phối, Qúy Khách vui lòng bổ sung lại đầy đủ nhé",
  "Hóa đơn của Qúy Khách đã bị trùng với hóa đơn trước đó",
  "Hóa đơn của Qúy Khách bị thiếu ngày, tháng mua hàng, Qúy Khách vui lòng bổ sung lại đầy đủ nhé",
  "Hình ảnh xe hàng của Qúy Khách không có định vị + thời gian, Qúy Khách vui lòng chụp ảnh bằng ứng dụng Timestamp để hiện thị định vị + thời gian nhé",
  "Hình ảnh xe hàng không hợp lệ, Qúy Khách vui lòng kiểm tra và bổ sung nhé",
  "Mặt hàng Qúy Khách mua trong hóa đơn không áp dụng trong CTKM này",
  "Nhà phân phối Qúy Khách chọn sai so với trên hóa đơn, Qúy Khách kiểm tra và chỉnh sửa lại nhé",
  "NPP trên hóa đơn của Qúy Khách là CH Chi Nhánh Đệ Nhất - Bình Tân, Qúy Khách vui lòng chỉnh sửa lại nhé",
  "NPP trên hóa đơn của Qúy Khách là CH Đệ nhất Bình Dương (Cty Việt Trường), Qúy Khách vui lòng chỉnh sửa lại nhé",
  "Quý CHDL vui lòng nhập đúng số lượng hàng hoá trên hoá đơn để được duyệt ngay",
];

function InvoiceFormPromotion2023H2Page() {
  let { id } = useParams();
  const navigate = useNavigate();

  const [isShowApporvedPopup, setIsShowApporvedPopup] = useState(false);
  const [isShowRecallPopup, setIsShowRecallPopup] = useState(false);
  const [isSaveButtonPopup, setIsSaveButtonPopup] = useState(false);
  const [isRejectPopup, setIsRejectPopup] = useState(false);
  const [form, setForm] = useState<RtlEp01Form>();
  const [originForm, setOriginForm] = useState<RtlEp01Form>();
  const [edit, setEdit] = useState(false)
  const ref = useRef(null)

  const updateStatus = (id: any, status: number, note: any) => {
    FormModel.updateStatus(id, status, note).then((resp) => {
      if (resp.error === 0) {
        navigate(-1);
        AlertUtils.showSuccess("Thành công!");
      } else {
        AlertUtils.showError(resp.msg);
      }
      fetchForm();
    });
  };

  const updateForm = () => {
    FormModel.updateForm(form?.id, form).then((resp) => {
      if (resp.error === 0) {
        AlertUtils.showSuccess("Thành công!");
      } else {
        AlertUtils.showError(resp.msg);
      }
      fetchForm();
    });
  };

  const fetchForm = () => {
    FormModel.get(id).then((resp) => {
      if (resp.error === 0) {
        setForm(resp.data);
        setOriginForm(resp.data);
      }
    });
  };

  const _isNeedSave = () => {
    return JSON.stringify(originForm) !== JSON.stringify(form);
  };

  const generatePdf = () => {
    const element = document.querySelector('.card.invoice')
    const options = {
      filename: `${form?.id}.pdf`
    }
    return domToPdf(element, options, () => {
      console.log("............")
    })
  }

  const takeScreenShot = async (node) => {
    const dataURI = await htmlToImage.toJpeg(node);
    return dataURI;
  };

  const download = (image) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = `${form?.id}.jpg`;
    a.click();
  };

  const downloadScreenshot = () => takeScreenShot(ref.current).then(download);


  useEffect(() => {
    fetchForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const nameBuyer = useNameUser(form?.user)

  return (
    <Layout>
      <main className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div ref={ref} className="card invoice">
                {form && (
                  <div className="card-body m-sm-3 m-md-5">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="text-muted">ID.</div>
                        <strong>{form.id}</strong>
                        <span className="invoice-status" style={{ backgroundColor: `${StockFormStatus.findColor(form.status)}` }}>
                          # {StockFormStatus.findName(form.status)}
                        </span>
                      </div>
                      <div className="col-md-6 text-md-right">
                        <div className="text-muted">
                          Ngày mua hàng ( Ghi Trên Hóa Đơn )
                        </div>
                        <DatePicker
                          value={
                            form.billCreatedTime
                              ? moment.unix(form.billCreatedTime)
                              : null
                          }
                          onChange={(date) => {
                            setForm({
                              ...form,
                              billCreatedTime: Number(date?.unix()),
                            });
                          }}
                          disabledDate={(current) => {
                            return (
                              current &&
                              current <
                              moment('2023-7-15', "YYYY-MM-DD")
                            );
                          }}
                        />
                      </div>
                    </div>

                    <hr className="my-4" />

                    <div className="row mb-4">
                      <div className="col-md-6 invoice-account">
                        <div className="text-muted">Bên Mua</div>
                        <div className="form-group">
                          <label>{UserType.findName(form.user.roleId)}: </label>
                          <input
                            disabled
                            type="text"
                            className="form-control"
                            value={nameBuyer}
                          />
                        </div>
                        <div className="form-group">
                          <label>Số điện thoại</label>
                          <input
                            disabled
                            type="text"
                            className="form-control"
                            value={form.user.phone}
                          />
                        </div>
                        <div className="form-group">
                          <label>Địa chỉ</label>
                          <input
                            disabled
                            type="text"
                            className="form-control"
                            value={`${form?.user?.address} - ${District.getName(form.user.districtId)} - ${City.getName(form.user.cityId)}`}
                          />
                        </div>


                      </div>
                      <div className="col-md-6 invoice-account">
                        <div className="text-muted">Bên Bán</div>
                        <div className="form-group">
                          <label>{UserType.findName(form.distributor.roleId)}</label>
                          <SelectDistributor
                            disabled={!edit}
                            value={form.distributorId}
                            onChange={(data) => {
                              setForm({ ...form, distributorId: data });
                            }}
                          />
                        </div>

                        <div className="form-group">
                          <label>Số điện thoại</label>
                          <input
                            disabled
                            type="text"
                            className="form-control"
                            value={form.distributor.phone}
                          />
                        </div>
                        <div className="form-group">
                          <label>Địa chỉ</label>
                          <input
                            disabled
                            type="text"
                            className="form-control"
                            value={`${form?.distributor?.address} - ${District.getName(form.distributor.districtId)} - ${City.getName(form.distributor.cityId)}`}
                          />
                        </div>
                      </div>

                    </div>

                    <table className="table table-sm invoice-table">
                      <thead>
                        <tr>
                          <th style={{ width: '50%' }}>Sản phẩm</th>
                          <th style={{ width: '50%' }}>Số lượng</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(edit || form.plate > 0) &&
                          <tr>
                            <td>Số tấm</td>
                            <td>
                              <input
                                onChange={(
                                  e: React.FormEvent<HTMLInputElement>
                                ) => {
                                  let value = Number(e.currentTarget.value);
                                  if (isNaN(value)) {
                                    return;
                                  }
                                  setForm({
                                    ...form,
                                    plate: value,
                                  });
                                }}
                                disabled={!edit}
                                type="text"
                                className="form-control"
                                value={form.plate}
                              />
                            </td>
                          </tr>
                        }
                        {(edit || form.frame > 0) &&

                          <tr>
                            <td>Số Khung</td>
                            <td>
                              <input
                                onChange={(
                                  e: React.FormEvent<HTMLInputElement>
                                ) => {
                                  let value = Number(e.currentTarget.value);
                                  if (isNaN(value)) {
                                    return;
                                  }
                                  setForm({
                                    ...form,
                                    frame: value,
                                  });
                                }}
                                disabled={!edit}
                                type="text"
                                className="form-control"
                                value={form.frame}
                              />
                            </td>
                          </tr>
                        }
                        {(edit || form.compound > 0) &&
                          <tr>
                            <td>Số bột</td>
                            <td>
                              <input
                                onChange={(
                                  e: React.FormEvent<HTMLInputElement>
                                ) => {
                                  let value = Number(e.currentTarget.value);
                                  if (isNaN(value)) {
                                    return;
                                  }
                                  setForm({
                                    ...form,
                                    compound: value,
                                  });
                                }}
                                disabled={!edit}
                                type="text"
                                className="form-control"
                                value={form.compound}
                              />
                            </td>
                          </tr>
                        }
                        {(edit || form.mini8Plate > 0) &&
                          <tr>
                            <td>Số tấm mini 8mm</td>
                            <td>
                              <input
                                onChange={(
                                  e: React.FormEvent<HTMLInputElement>
                                ) => {
                                  let value = Number(e.currentTarget.value);
                                  if (isNaN(value)) {
                                    return;
                                  }
                                  setForm({
                                    ...form,
                                    mini8Plate: value,
                                  });
                                }}
                                disabled={!edit}
                                type="text"
                                className="form-control"
                                value={form.mini8Plate}
                              />
                            </td>
                          </tr>
                        }
                        {(edit || form.standard9Plate > 0) &&
                          <tr>
                            <td>Số tấm tiêu chuẩn 9mm</td>
                            <td>
                              <input
                                onChange={(
                                  e: React.FormEvent<HTMLInputElement>
                                ) => {
                                  let value = Number(e.currentTarget.value);
                                  if (isNaN(value)) {
                                    return;
                                  }
                                  setForm({
                                    ...form,
                                    standard9Plate: value,
                                  });
                                }}
                                disabled={!edit}
                                type="text"
                                className="form-control"
                                value={form.standard9Plate}
                              />
                            </td>
                          </tr>
                        }
                        {(edit || form.moistureResistant9Plate > 0) &&
                          <tr>
                            <td>Số tấm chịu ẩm 9mm</td>
                            <td>
                              <input
                                onChange={(
                                  e: React.FormEvent<HTMLInputElement>
                                ) => {
                                  let value = Number(e.currentTarget.value);
                                  if (isNaN(value)) {
                                    return;
                                  }
                                  setForm({
                                    ...form,
                                    moistureResistant9Plate: value,
                                  });
                                }}
                                disabled={!edit}
                                type="text"
                                className="form-control"
                                value={form.moistureResistant9Plate}
                              />
                            </td>
                          </tr>
                        }
                        {(edit || form.fireProofPlate > 0) &&
                          <tr>
                            <td>Số tấm chống cháy 9.5mm</td>
                            <td>
                              <input
                                onChange={(
                                  e: React.FormEvent<HTMLInputElement>
                                ) => {
                                  let value = Number(e.currentTarget.value);
                                  if (isNaN(value)) {
                                    return;
                                  }
                                  setForm({
                                    ...form,
                                    fireProofPlate: value,
                                  });
                                }}
                                disabled={!edit}
                                type="text"
                                className="form-control"
                                value={form.fireProofPlate}
                              />
                            </td>
                          </tr>
                        }
                        {(edit || form.threeInOnePlate > 0) &&
                          <tr>
                            <td>Số tấm 3in1 9mm</td>
                            <td>
                              <input
                                onChange={(
                                  e: React.FormEvent<HTMLInputElement>
                                ) => {
                                  let value = Number(e.currentTarget.value);
                                  if (isNaN(value)) {
                                    return;
                                  }
                                  setForm({
                                    ...form,
                                    threeInOnePlate: value,
                                  });
                                }}
                                disabled={!edit}
                                type="text"
                                className="form-control"
                                value={form.threeInOnePlate}
                              />
                            </td>
                          </tr>
                        }
                        {(edit || form.ultraMoistPlate > 0) &&
                          <tr>
                            <td>Tấm chống ẩm UltraMoist</td>
                            <td>
                              <input
                                onChange={(
                                  e: React.FormEvent<HTMLInputElement>
                                ) => {
                                  let value = Number(e.currentTarget.value);
                                  if (isNaN(value)) {
                                    return;
                                  }
                                  setForm({
                                    ...form,
                                    ultraMoistPlate: value,
                                  });
                                }}
                                disabled={!edit}
                                type="text"
                                className="form-control"
                                value={form.ultraMoistPlate}
                              />
                            </td>
                          </tr>
                        }
                        <tr>
                          <th>Điểm</th>
                          <th>
                            <input
                              style={{ color: '#fd7e14', fontWeight: '600' }}
                              type="text"
                              className="form-control"
                              disabled
                              value={form.point > 0 ? form.point : '---'}
                            />
                          </th>
                        </tr>
                      </tbody>
                    </table>

                    <div className="text-left">
                      <p className="text-sm">
                        <strong>Ghi chú:</strong>
                        <div className="form-group">
                          <textarea
                            className="form-control"
                            value={form.note}
                          />
                        </div>
                      </p>

                    </div>

                    <div className="form-group">
                      <div>Hình ảnh hóa đơn: <LinkImage image={form.invoiceImage} /></div>
                    </div>

                    <div className="card-footer">
                      {_isNeedSave() &&
                        form?.status === StockFormStatus.INIT && (
                          <>
                            <button
                              onClick={() => setIsSaveButtonPopup(true)}
                              style={{ marginRight: "5px" }}
                              className="btn btn-green"
                            >
                              Lưu
                            </button>
                            <AreYouSurePopup
                              open={isSaveButtonPopup}
                              onCloseModal={() => {
                                setIsSaveButtonPopup(false);
                              }}
                              onAgree={() => {
                                updateForm();
                                setIsSaveButtonPopup(false);
                              }}
                            />
                          </>
                        )}
                      {!edit && !_isNeedSave() &&
                        form?.status === StockFormStatus.INIT && (
                          <>
                            <button
                              onClick={() => {
                                if (!form.distributorId) {
                                  AlertUtils.showWarning("Vui lòng chọn NPP");
                                  return;
                                }

                                if (!form.billCreatedTime) {
                                  AlertUtils.showWarning(
                                    "Vui lòng cập nhật ngày mua hàng"
                                  );
                                  return;
                                }

                                setIsShowApporvedPopup(true);
                              }}
                              className="btn btn-danger m-btn-danger"
                            >
                              Chấp nhận
                            </button>
                            <AreYouSurePopup
                              open={isShowApporvedPopup}
                              onCloseModal={() => {
                                setIsShowApporvedPopup(false);
                              }}
                              onAgree={() => {
                                updateStatus(
                                  form?.id,
                                  StockFormStatus.APPROVED,
                                  null
                                );
                                setIsShowApporvedPopup(false);
                              }}
                            />
                            <button
                              onClick={() => setIsRejectPopup(true)}
                              style={{
                                marginLeft: "5px",
                                padding: "5px 30px",
                              }}
                              className="btn btn-delete"
                            >
                              Từ chối
                            </button>
                            <AreYouSureWithNoteDropdownPopup
                              list={list_reason_form_reject}
                              open={isRejectPopup}
                              onCloseModal={() => {
                                setIsRejectPopup(false);
                              }}
                              onAgree={(note) => {
                                updateStatus(
                                  form?.id,
                                  StockFormStatus.REJECTED,
                                  note
                                );
                                setIsRejectPopup(false);
                              }}
                            />
                          </>
                        )}
                      {!edit && form?.status === StockFormStatus.APPROVED && (
                        <>
                          <button
                            onClick={() => setIsShowRecallPopup(true)}
                            className="btn btn-danger m-btn-danger"
                          >
                            Thu hồi
                          </button>
                          <AreYouSureWithNotePopup
                            open={isShowRecallPopup}
                            onCloseModal={() => {
                              setIsShowRecallPopup(false);
                            }}
                            onAgree={(note) => {
                              updateStatus(
                                form?.id,
                                StockFormStatus.INIT,
                                note
                              );
                              setIsShowRecallPopup(false);
                            }}
                          />
                        </>
                      )}
                      <button onClick={() => {
                        setEdit(!edit)
                      }} style={{ marginLeft: '5px', backgroundColor: '#40a9ff' }} className="btn">
                        <i style={{ color: '#fff' }} className={`align-middle fas ${edit ? 'fa-stop' : 'fa-edit'}`}></i>
                      </button>

                      {!edit && <button onClick={() => {
                        downloadScreenshot()
                      }} style={{ marginLeft: '5px', backgroundColor: '#40a9ff' }} className="btn">
                        <i style={{ color: '#fff' }} className="align-middle fas fa-download"></i>
                      </button>
                      }
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}

export default InvoiceFormPromotion2023H2Page;

