import React, { useState } from "react";
import Modal from "react-responsive-modal";
import "../../../../../node_modules/react-responsive-modal/styles.css";
import "./styles.scss";
const owlClass = "popup";

export function UploadFilePopup({ open, onCloseModal, onAgree }) {
    const [file, setFile] = useState();

    return (
        <Modal
            open={open}
            onClose={onCloseModal}
            center
            showCloseIcon={false}
            styles={{
                modal: {
                    background: "rgba(242, 242, 242, 0.94)",
                    backdropFilter: "blur(54.3656px)",
                    borderRadius: "14px",
                    padding: "0",
                    maxWidth: "80%",
                },
            }}
        >
            <div className={owlClass}>
                <div style={{ margin: '0' }} className="row">
                    <div style={{ padding: '0' }} className="col-12">
                        <div style={{ margin: '0' }} className="card">
                            <div className="card-header">
                                <h5 className="card-title">Quà tặng</h5>
                                <h6 className="card-subtitle text-muted">Vui lòng chọn file voucher được gửi từ GOT IT</h6>
                            </div>
                            <div className="card-body">
                                <div className="container-fluid">
                                    <div style={{ margin: '0' }} className="row">
                                        <div className="col-12 col-lg-12">
                                            <div className="form-group">
                                                <label className="form-label w-100">File input</label>
                                                <input style={{ padding: '15px 0px' }} onChange={(event) => {
                                                    setFile(event.target.files[0])
                                                }} type="file" />
                                                <small className="form-text text-muted">Vui lòng đảm bảo đúng format - Không có sai số</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ paddingRight: '20px' }} className="cart-btn-bar">
                                <button onClick={() => { onCloseModal() }} style={{ float: 'right', margin: '20px' }} className="btn btn-primary mr-1 btn-cancel">Hủy</button>
                                <button onClick={() => { file && onAgree(file) }} style={{ float: 'right', margin: '20px' }} className="btn btn-primary mr-1">Đồng ý</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}



export function ConfirmContentPoup({ data, open, onCloseModal, onAgree }) {
    console.log(data)
    return (
        <Modal
            open={open}
            onClose={onCloseModal}
            center
            showCloseIcon={false}
            styles={{
                modal: {
                    background: "rgba(242, 242, 242, 0.94)",
                    backdropFilter: "blur(54.3656px)",
                    borderRadius: "14px",
                    padding: "0",
                    width: "80%",
                },
            }}
        >
            <div className={owlClass}>
                <div style={{ margin: '0' }} className="row">
                    <div style={{ padding: '0' }} className="col-12">
                        <div style={{ margin: '0' }} className="card">
                            <div className="card-header">
                                <h5 className="card-title">Are you sure?</h5>
                                <h6 className="card-subtitle text-muted">Vui lòng kiểm tra tính chính xác</h6>
                            </div>
                            <div className="card-body">
                                <table className="table table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Avatar</th>
                                            <th>User ID</th>
                                            <th>SDT</th>
                                            <th>GotIt Code</th>
                                            <th>Link GotIt</th>
                                            <th>Giá trị</th>
                                            <th>Điểm quy đổi</th>
                                            <th>Điểm hiện có</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data && data.content && data.content.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        <img
                                                            alt=""
                                                            style={{ width: '30px', height: '30px' }}
                                                            className="avatar-gift"
                                                            src={item.user.avatar}
                                                        />
                                                    </td>
                                                    <td>{item.user.id}</td>
                                                    <td>{item.user.phone}</td>
                                                    <td>{item.gift.gotItCode}</td>
                                                    <td>{item.gift.linkGotIt}</td>
                                                    <td>{item.gift.money.toLocaleString('it-IT', { style: 'currency', currency: 'VND' })}</td>
                                                    <td>{item.gift.point}</td>
                                                    <td>{item.accumulation.point}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div style={{ paddingRight: '20px' }} className="cart-btn-bar">
                                <button onClick={() => { onCloseModal() }} style={{ float: 'right', margin: '20px' }} className="btn btn-primary mr-1 btn-cancel">Hủy</button>
                                <button onClick={() => { onAgree(data.fileName) }} style={{ float: 'right', margin: '20px' }} className="btn btn-primary mr-1">Đồng ý</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}