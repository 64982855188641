import Layout from "../../../component/Layout";
import "../../../component/popup/styles.scss";
import { useEffect, useState } from "react";
import { Page, FormFilter, AllForm } from "../../../interface";
import * as StockFormStatus from "../../../constant/status/FormStatus";
import FormModel from "../../../model/FormModel";
import DateTimeUtil from "../../../utils/DateTimeUtil";
import { Button } from "antd";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Pagination } from "antd";
import LinkUtil from "../../../utils/LinkUtil";
import { CTR_SCAN_QR_FORM_TYPE } from "../../../constant/type/FormType";
import ConfigConstant from "../../../constant/config";

const default_avatar = "http://cdn.onlinewebfonts.com/svg/img_264570.png";
const PAGE_SIZE = 10;
function ListHistoryScanQRFormsPage() {
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [formPage, setFormPage] = useState<Page<AllForm>>();
  const [filter, setFilter] = useState<FormFilter>(
    LinkUtil.initParamWithDefault(searchParams, {
      promotionId: ConfigConstant.CTR_SCAN_QR_FORM_TYPE.promotionId,
      status: [StockFormStatus.APPROVED, StockFormStatus.REJECTED],
    })
  );

  const findForms = () => {
    FormModel.find(
      filter.search,
      filter.status,
      ConfigConstant.CTR_SCAN_QR_FORM_TYPE.promotionId,
      filter.page,
      PAGE_SIZE
    ).then((resp) => {
      if (resp.error === 0) {
        setFormPage(resp.data);
      }
    });
  };

  const export_excel = () => {
    let { status, promotionId } = filter;
    let param = Object.fromEntries(
      Object.entries({
        status,
        promotionId,
      }).filter(([_, v]) => v != null)
    ) as any;
    window.location.href =
      process.env.REACT_APP_DOMAIN +
      `/api/form/export-excel?${new URLSearchParams(param).toString()}`;
    return;
  };

  const serialize2ParamsUrl = () => {
    searchParams.set("q", JSON.stringify(filter));
    setSearchParams(searchParams);
  };

  useEffect(() => {
    findForms();
    serialize2ParamsUrl();
  }, [filter]);

  const value_select_status = filter.status.length > 1 ? 0 : filter.status[0];

  return (
    <Layout>
      <main className="content">
        <div className="container-fluid">
          <div className="header">
            <h1 className="header-title">[Nhà thầu] Lịch sử scan QR</h1>
          </div>
          <div className="row">
            <div className="col-12 col-xl-12">
              <div className="card">
                <div className="card-bar" style={{ padding: "10px" }}>
                  <div className="row ">
                    <div className="col-2 col-xl-2 ml-auto">
                      <input
                        className="form-control"
                        placeholder="SDT hoặc Tên"
                        type="text"
                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                          setFilter({
                            search: e.currentTarget.value,
                            status: [
                              StockFormStatus.APPROVED,
                              StockFormStatus.REJECTED,
                            ],
                          } as FormFilter);
                        }}
                      />
                    </div>
                    <div className="col-2 col-xl-2">
                      <select
                        value={value_select_status}
                        onChange={(e: React.FormEvent<HTMLSelectElement>) => {
                          const value = Number(e.currentTarget.value);
                          if (value == 0) {
                            setFilter({
                              ...filter,
                              status: [
                                StockFormStatus.APPROVED,
                                StockFormStatus.REJECTED,
                              ],
                            });
                          } else {
                            setFilter({
                              ...filter,
                              status: [Number(e.currentTarget.value)],
                            });
                          }
                        }}
                        className="form-control"
                      >
                        <option value={0}>Trạng thái</option>
                        <option value={StockFormStatus.APPROVED}>
                          Đã duyệt
                        </option>
                        <option value={StockFormStatus.REJECTED}>
                          Đã từ chối
                        </option>
                      </select>
                    </div>
                    <div className="col-2 col-xl-2">
                      <Button type="primary" onClick={export_excel}>
                        Xuất Excel
                      </Button>
                    </div>
                  </div>
                </div>
                <table className="table">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th></th>
                      <th>Họ và Tên</th>
                      <th>Thời gian</th>
                      <th>Codes</th>
                      <th>Số điểm</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formPage &&
                      formPage.list &&
                      formPage.list.map((form, key) => {
                        return (
                          <tr key={key}>
                            <td>{form.id}</td>
                            <td>
                              <img
                                alt=""
                                className="m-avatar"
                                src={
                                  form.user.avatar
                                    ? form.user.avatar
                                    : default_avatar
                                }
                              />
                            </td>
                            <td>{form.user.fullName}</td>
                            <td>
                              {DateTimeUtil.toString(form.createdTime * 1000)}
                            </td>
                            <td>{form.codes.join(", ")}</td>
                            <td>{form.point}</td>
                            <td className="table-action">
                              <Button
                                onClick={() => {
                                  navigate(
                                    `/${ConfigConstant.CTR_SCAN_QR_FORM_TYPE.key}/form/${form.id}/scan-qr`
                                  );
                                }}
                                type="primary"
                              >
                                Chi tiết
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <div className="cart-paging">
                  {formPage && (
                    <Pagination
                      defaultCurrent={1}
                      current={filter.page + 1}
                      onChange={(pageNumber) => {
                        setFilter({ ...filter, page: pageNumber - 1 });
                      }}
                      total={formPage.totalPage * PAGE_SIZE}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}

export default ListHistoryScanQRFormsPage;
