import Layout from "../../../component/Layout";
import "../../../component/popup/styles.scss";
import { useEffect, useState } from "react";
import {
  CountUserDashboard,
  UserCityMetric,
  UserDateMetric,
} from "../../../interface";
import UserModel from "../../../model/UserModel";
import {
  UserCityMetricChartV2,
  UserDateMetricChart,
} from "../../../component/chart";
import FormModel from "../../../model/FormModel";
import { APPROVED, INIT, REJECTED } from "../../../constant/status/FormStatus";
import ConfigConstant from "../../../constant/config";

function DashboardCTRPage() {
  const [countUser, setCountUser] = useState<CountUserDashboard>();
  const [lstUserCity, setLstUserCity] = useState<Array<UserCityMetric>>();
  const [lstUserDate, setLstUserDate] = useState<Array<UserDateMetric>>();
  const [approvedForms, setApprovedForms] = useState(0);
  const [totalForms, setTotalForms] = useState(0);
  const [waitingForms, setWaitingForms] = useState(0);
  const [rejectForms, setRejectForms] = useState(0);

  const fetchCountUser = () => {
    UserModel.count().then((resp) => {
      if (resp.error === 0) {
        setCountUser(resp.data);
      }
    });
  };

  const fetchUserCityMetric = () => {
    UserModel.statsUserCity().then((resp) => {
      if (resp.error === 0) {
        let tmp = resp.data.map((item: { city: any; contractor: any }) => {
          return {
            city: item.city,
            total: item.contractor,
          };
        });
        setLstUserCity(tmp);
      }
    });
  };

  const fetchUserDateMetric = () => {
    UserModel.statsUserDate().then((resp) => {
      if (resp.error === 0) {
        setLstUserDate(resp.data);
      }
    });
  };

  const fetchCountByStatus = (status: any) => {
    FormModel.countByStatus(
      status,
      ConfigConstant.CTR_SCAN_QR_FORM_TYPE.promotionId
    ).then((resp) => {
      if (status === null) {
        setTotalForms(resp.data);
      }
      if (status === APPROVED) {
        setApprovedForms(resp.data);
      }
      if (status == INIT) {
        setWaitingForms(resp.data);
      }
      if (status == REJECTED) {
        setRejectForms(resp.data);
      }
    });
  };
  // const fetchAnalyticsAccumulationMetric = () => {
  //   AccumulationModel.analytics(1).then((resp) => {
  //     if (resp.error === 0) {
  //       setAnalyticsAccumulation(resp.data);
  //     }
  //   });
  // };

  useEffect(() => {
    fetchCountUser();
    fetchUserCityMetric();
    fetchUserDateMetric();
    fetchCountByStatus(null);
    fetchCountByStatus(APPROVED);
    fetchCountByStatus(INIT);
    fetchCountByStatus(REJECTED);
    // fetchAnalyticsAccumulationMetric();
  }, []);

  return (
    <Layout>
      <main className="content">
        <div className="container-fluid">
          <div className="header row" style={{ marginBottom: "20px" }}>
            <div className="col-6">
              <h1 className="header-title">Overview</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-4 col-xl">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col mt-0">
                      <h5 className="card-title">Nhà thầu</h5>
                    </div>
                    <div className="col-auto">
                      <div className="avatar">
                        <div className="avatar-title rounded-circle bg-primary-dark">
                          <i className="align-middle fas fa-fw fa-store"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h1 className="display-5 mt-1 mb-3">
                    {countUser?.numApprovedContractor}
                  </h1>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-4 col-xl">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col mt-0">
                      <h5 className="card-title">Đơn</h5>
                    </div>
                    <div className="col-auto">
                      <div className="avatar">
                        <div className="avatar-title rounded-circle bg-primary-dark">
                          <i className="align-middle fas fa-fw  fa-ad"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h1 className="display-5 mt-1 mb-3">
                    <span style={{ color: "rgb(40, 167, 69)" }}>
                      {approvedForms}
                    </span>{" "}
                    / {waitingForms}/ {totalForms - rejectForms}
                  </h1>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-4 col-xl">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col mt-0">
                      <h5 className="card-title">Quà tặng</h5>
                    </div>
                    <div className="col-auto">
                      <div className="avatar">
                        <div className="avatar-title rounded-circle bg-primary-dark">
                          <i className="align-middle fas fa-fw fa-gift"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h1 className="display-5 mt-1 mb-3">{0}</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 col-xxl-6 d-flex">
              <div className="card flex-fill w-100">
                <div className="card-header">
                  <h5 className="card-title mb-0">
                    Top 10 nhà thầu phân bố theo vùng
                  </h5>
                </div>
                <div className="card-body px-4">
                  {lstUserCity && <UserCityMetricChartV2 data={lstUserCity} />}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xxl-6 d-flex">
              <div className="card flex-fill w-100">
                <div className="card-header">
                  <h5 className="card-title mb-0">
                    Số cửa hàng đăng ký theo ngày
                  </h5>
                </div>
                <div className="card-body py-3">
                  {lstUserDate && <UserDateMetricChart data={lstUserDate} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}

export default DashboardCTRPage;
