export default class DraftProfileStatus {
    static INIT = 1;
    static APPROVED = 2;
    static REJECTED = 3;


    static findName(value) {
        switch (value) {
            case this.INIT: return 'Chờ duyệt'
            case this.APPROVED: return 'Đã duyệt'
            case this.REJECTED: return 'Từ chối'
        }

    }

    static findColor(value) {
        switch (value) {
            case this.INIT: return '#20c997'
            case this.APPROVED: return '#dc3545'
            case this.REJECTED: return '#dc3545'
        }
    }
}