import APIUtil from "../utils/APIUtils";

export default class PointModel {

  static get(id) {
    return new Promise((resolve, reject) => {
      APIUtil.getJSONWithCredentials(
        process.env.REACT_APP_DOMAIN +
        `/api/point/get?id=${id}`,
        resolve,
        reject
      );
    });
  }


  static find(promotionId, search, roleId, page, pageSize) {
    var param = Object.fromEntries(
      Object.entries({
        search,
        page,
        pageSize,
      }).filter(([_, v]) => v != null)

    );

    if (roleId !== 0 && roleId != null) {
      param['roleIds'] = [roleId]
    }

    return new Promise((resolve, reject) => {
      APIUtil.getJSONWithCredentials(
        process.env.REACT_APP_DOMAIN +
        `/api/point/find?${new URLSearchParams(
          param
        ).toString()}&promotionId=${promotionId}`,
        resolve,
        reject
      );
    });
  }
}
