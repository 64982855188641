export default class QrStatus {
    static ACTIVATE = 1;
    static DEACTIVATE = 2;
    static DELETED = 0;

    static findName(value) {
        switch (value) {
            case this.DEACTIVATE: return 'Đã kích hoạt'
            case this.ACTIVATE: return 'Chưa kích hoạt'
            case this.DELETED: return 'Đã xóa'
        }

    }

    static findColor(value) {
        switch (value) {
            case this.ACTIVATE: return '#20c997'
            case this.DEACTIVATE: return '#dc3545'
        }
    }
}