import Layout from "../../../component/Layout";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Page, Promotion } from "../../../interface";
import * as PromotionStatus from "../../../constant/status/PromotionStatus";
import * as UserType from "../../../constant/type/UserType";
import DateTimeUtil from "../../../utils/DateTimeUtil";
import { useSearchParams } from "react-router-dom";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { Pagination } from "antd";
import LinkUtil from "../../../utils/LinkUtil";
import PromotionModel from "../../../model/PromotionModel";
import * as PromotionType from '../../../constant/type/PromotionType'

const PAGE_SIZE = 20;
function ListPromotionPage(props: any) {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [promotionPage, setPromotionPage] = useState<Page<Promotion>>();
    const [filter, setFilter] = useState<any>({
        ...LinkUtil.initParam(searchParams),
    });


    const fetchPromotions = () => {
        PromotionModel.find({
            types: [61],
            page: filter.page,
            pageSize: PAGE_SIZE
        }).then((resp) => {
            if (resp.error === 0) {
                setPromotionPage(resp.data);
            }
        });
    }

    const serialize2Url = () => {
        var q = JSON.stringify(filter);
        if (q !== "{}") {
            searchParams.set("q", q);
            setSearchParams(searchParams);
        }
    };

    useEffect(() => {
        fetchPromotions();
        serialize2Url();
    }, [filter]);

    useEffect(() => {
        setFilter({ ...filter, roleId: props.roleId });
    }, [props.roleId]);


    const getContent = (promotion: Promotion) => {
        if (promotion.type === PromotionType.EVENT_PROMOTION) {
            return <table className="sm-table">
                <tr><td>Sự kiện:</td> <th>{promotion.content?.["name"]}</th></tr>
                <tr><td>Thời gian:</td> <th>{DateTimeUtil.toStringDetail(promotion.content?.["time"])}</th></tr>
                <tr><td>Địa điểm:</td> <th>{promotion.content?.["place"]}</th></tr>
            </table>
        }
        return ''
    }

    return (
        <Layout>
            <main className="content">
                <div className="container-fluid">
                    <div className="header">
                        <h1 className="header-title">Chương trình</h1>
                    </div>
                    <div className="row">
                        <div className="col-12 col-xl-12">
                            <div className="card">
                                <div className="card-bar" style={{ padding: "10px" }}>
                                    <div className="row " style={{ float: 'right' }}>
                                        <div className="mr-3">
                                            <Button type={"primary"} onClick={() => {
                                                navigate(
                                                    `/${props.config.key}/promotion/create`
                                                );
                                            }}>
                                                Chương trình mới
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Chương trình</th>
                                            <th>Bắt đầu</th>
                                            <th>Kết thúc</th>
                                            <th>Nội dung</th>
                                            <th>Trạng thái</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {promotionPage &&
                                            promotionPage.list &&
                                            promotionPage.list.map((promotion, key) => {
                                                let content = getContent(promotion)
                                                return (
                                                    <tr key={key}>
                                                        <td>{promotion.id}</td>
                                                        <td>{promotion.title}</td>
                                                        <td>{DateTimeUtil.toString(promotion.timeStart)}</td>
                                                        <td>{DateTimeUtil.toString(promotion.timeEnd)}</td>
                                                        <td>{content}</td>
                                                        <td>
                                                            <span
                                                                style={{
                                                                    backgroundColor: PromotionStatus.findColor(
                                                                        promotion.status
                                                                    ),
                                                                }}
                                                                className="badge badge-info"
                                                            >
                                                                {PromotionStatus.findName(promotion.status)}
                                                            </span>
                                                        </td>
                                                        <td className="table-action">
                                                            <Button
                                                                onClick={() => {
                                                                    navigate(
                                                                        `/${props.config.key}/promotion/${promotion.id}`
                                                                    );
                                                                }}
                                                                type="primary"
                                                            >
                                                                Chi tiết
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                                <div className="cart-paging">
                                    {promotionPage && (
                                        <Pagination
                                            defaultCurrent={1}
                                            defaultPageSize={PAGE_SIZE}
                                            current={filter.page + 1}
                                            onChange={(pageNumber) => {
                                                setFilter({ ...filter, page: pageNumber - 1 });
                                            }}
                                            showSizeChanger={false}
                                            total={promotionPage.totalPage * PAGE_SIZE}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </Layout>
    );
}

export default ListPromotionPage;
