import APIUtil from '../utils/APIUtils'

export default class GiftModel {
    static find(promotionId, type) {
        var param = Object.fromEntries(Object.entries({
            promotionId, type
        }).filter(([_, v]) => v != null));
        return new Promise((resolve, reject) => {
            APIUtil.getJSONWithCredentials(process.env.REACT_APP_DOMAIN + `/api/gift/find?${new URLSearchParams(param).toString()}`, resolve, reject);
        });
    }

}