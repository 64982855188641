import Layout from "../../../component/Layout";
import { useEffect, useMemo, useState } from "react";
import { Page, User, UserFilter } from "../../../interface";
import { City, Region } from "../../../utils/ProvinceUtil";
import UserModel from "../../../model/UserModel";
import * as UserStatus from "../../../constant/status/UserStatus";
import * as UserType from "../../../constant/type/UserType";
import DateTimeUtil from "../../../utils/DateTimeUtil";
import { useSearchParams } from "react-router-dom";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { Pagination } from "antd";
import LinkUtil from "../../../utils/LinkUtil";

const default_avatar = "http://cdn.onlinewebfonts.com/svg/img_264570.png";
const PAGE_SIZE = 20;
function ListUserPage(props: any) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [userPage, setUserPage] = useState<Page<User>>();
  const [filter, setFilter] = useState<UserFilter>({
    ...LinkUtil.initParam(searchParams),
    roleId: props.roleId,
  });
  const [count, setCount] = useState<any>({});

  const fetchUsers = () => {
    UserModel.find(
      filter.search,
      filter.cityId,
      filter.status,
      filter.roleId,
      filter.region,
      filter.page,
      PAGE_SIZE
    ).then((resp) => {
      if (resp.error === 0) {
        setUserPage(resp.data);
      }
    });
  };

  const countUser = () => {
    UserModel.count().then((resp) => {
      if (resp.error === 0) {
        setCount(resp.data);
      }
    });
  };

  const serialize2Url = () => {
    var q = JSON.stringify(filter);
    if (q !== "{}") {
      searchParams.set("q", q);
      setSearchParams(searchParams);
    }
  };

  const export_excel = () => {
    let { status, cityId, roleId, region } = filter;
    if (Array.isArray(roleId) && roleId.length == 4) {
      roleId = 0;
    }
    let param = Object.fromEntries(
      Object.entries({
        status,
        cityId,
        roleId,
      }).filter(([_, v]) => v != null && v !== 0)
    ) as any;
    if (region != null && region !== 0) {
      param["cityIds"] = Region.get(region).provinces;
    }
    if (cityId != null && cityId !== 0) {
      param["cityIds"] = [cityId];
    }
    window.location.href =
      process.env.REACT_APP_DOMAIN +
      `/api/user/export-excel?${new URLSearchParams(param).toString()}`;
    return;
  };

  useEffect(() => {
    fetchUsers();
    serialize2Url();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    setFilter({ ...filter, roleId: props.roleId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.roleId]);

  useEffect(() => {
    countUser();
  }, []);

  const showNameUser = useMemo(() => {
    return props.roleId.includes(UserType.CONSTRUCTOR)
  }, [props])

  const showNameStore = useMemo(() => {
    return (props.roleId.includes(UserType.DISTRIBUTOR)
      || props.roleId.includes(UserType.DISTRIBUTOR_T2)
      || props.roleId.includes(UserType.RETAILER))
  }, [props])

  return (
    <Layout>
      <main className="content">
        <div className="container-fluid">
          <div className="header">
            <h1 className="header-title">Thành viên</h1>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-3 col-xl">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col mt-0">
                      <h5 className="card-title">Cửa hàng/ đại lý</h5>
                    </div>
                    <div className="col-auto">
                      <div className="avatar">
                        <div className="avatar-title rounded-circle bg-primary-dark">
                          <i className="align-middle fas fa-fw fa-store"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h1 className="display-5 mt-1 mb-3">
                    {count?.numApprovedRetailer}
                  </h1>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-3 col-xl">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col mt-0">
                      <h5 className="card-title">Nhà thầu</h5>
                    </div>
                    <div className="col-auto">
                      <div className="avatar">
                        <div className="avatar-title rounded-circle bg-primary-dark">
                          <i className="align-middle fas fa-fw  fa-ad"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h1 className="display-5 mt-1 mb-3">
                    {count?.numApprovedContractor}
                  </h1>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-3 col-xl">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col mt-0">
                      <h5 className="card-title">Nhà Phân phối</h5>
                    </div>
                    <div className="col-auto">
                      <div className="avatar">
                        <div className="avatar-title rounded-circle bg-primary-dark">
                          <i className="align-middle far fa-fw fa-heart"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h1 className="display-5 mt-1 mb-3">
                    {count?.numApprovedDistributor}
                  </h1>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-3 col-xl">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col mt-0">
                      <h5 className="card-title">Nhà phân phối T2</h5>
                    </div>
                    <div className="col-auto">
                      <div className="avatar">
                        <div className="avatar-title rounded-circle bg-primary-dark">
                          <i className="align-middle fas fa-fw fa-gift"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h1 className="display-5 mt-1 mb-3">
                    {count?.numApprovedDistributorT2}
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-xl-12">
              <div className="card">
                <div className="card-bar" style={{ padding: "10px" }}>
                  <div className="row ">
                    <div className="col-2 col-xl-2 ml-auto">
                      <input
                        className="form-control"
                        placeholder="SDT hoặc Tên"
                        type="text"
                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                          setFilter({
                            search: e.currentTarget.value,
                          } as UserFilter);
                        }}
                      />
                    </div>

                    <div className="col-2 col-xl-2">
                      <select
                        value={filter.cityId ? filter.cityId : 0}
                        onChange={(e: React.FormEvent<HTMLSelectElement>) => {
                          setFilter({
                            ...filter,
                            cityId: Number(e.currentTarget.value),
                          });
                        }}
                        className="form-control"
                      >
                        <option value={0}>Thành phố</option>
                        {Region.getListCity(filter.region).map((value) => {
                          return (
                            <option key={value.key} value={value.key}>
                              {value.value}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-2 col-xl-2">
                      <select
                        value={filter.region ? filter.region : 0}
                        onChange={(e: React.FormEvent<HTMLSelectElement>) => {
                          setFilter({
                            ...filter,
                            region: Number(e.currentTarget.value),
                          });
                        }}
                        className="form-control"
                      >
                        <option value={0}>Vùng miền</option>
                        {Region.getList().map((item) => {
                          return (
                            <option key={item.key} value={item.key}>
                              {item.value.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-2 col-xl-2 ">
                      <select
                        value={filter.status ? filter.status : 0}
                        onChange={(e: React.FormEvent<HTMLSelectElement>) => {
                          setFilter({
                            ...filter,
                            status: Number(e.currentTarget.value),
                          });
                        }}
                        className="form-control"
                      >
                        <option value={0}>Trạng thái</option>
                        <option value={UserStatus.APPROVED}>Đã duyệt</option>
                        <option value={UserStatus.WAITING_ACTIVE}>
                          Chờ active
                        </option>
                        <option value={UserStatus.WAIT_APPROVAL}>
                          Chờ duyệt
                        </option>
                        <option value={UserStatus.WAIT_COMPLETE_PROFILE}>
                          Chờ hoàn thành hồ sơ
                        </option>
                        <option value={UserStatus.REJECTED}>Từ chối</option>
                      </select>
                    </div>
                    <div className="mr-3">
                      <Button type={"primary"} onClick={export_excel}>
                        Xuất Excel
                      </Button>
                    </div>
                  </div>
                </div>
                <table className="table">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Image</th>
                      <th>SDT</th>
                      {showNameUser &&
                        <th>Họ Tên</th>
                      }
                      {showNameStore &&
                        <th>Cửa hàng</th>
                      }
                      <th>Loại tài khoản</th>
                      <th>Thành phố / Tỉnh</th>
                      <th>Miền</th>
                      <th>Thời gian</th>
                      <th>Trạng thái</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userPage &&
                      userPage.list &&
                      userPage.list.map((user, key) => {
                        return (
                          <tr key={key}>
                            <td>{user.id}</td>
                            <td>
                              <img
                                className="m-avatar"
                                alt=""
                                src={user.avatar ? user.avatar : default_avatar}
                              />
                            </td>
                            <td>{user.phone}</td>
                            {showNameUser && <td>{user.fullName}</td>}
                            {showNameStore && <td>{user.nameStore}</td>}
                            <td>{UserType.findName(user.roleId)}</td>
                            <td>{City.getName(user.cityId)}</td>
                            <td>{Region.getNameByCityId(user.cityId)}</td>
                            <td>
                              {DateTimeUtil.toString(user.createdTime * 1000)}
                            </td>
                            <td>
                              <span
                                style={{
                                  backgroundColor: UserStatus.findColor(
                                    user.status
                                  ),
                                }}
                                className="badge badge-info"
                              >
                                {UserStatus.findName(user.status)}
                              </span>
                            </td>
                            <td className="table-action">
                              <Button
                                onClick={() => {
                                  navigate(
                                    `/${props.config.key}/user/${user.id}`
                                  );
                                }}
                                type="primary"
                              >
                                Chi tiết
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <div className="cart-paging">
                  {userPage && (
                    <Pagination
                      defaultCurrent={1}
                      defaultPageSize={PAGE_SIZE}
                      current={filter.page + 1}
                      onChange={(pageNumber) => {
                        setFilter({ ...filter, page: pageNumber - 1 });
                      }}
                      showSizeChanger={false}
                      total={userPage.totalPage * PAGE_SIZE}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}

export default ListUserPage;
