import Layout from "../../../component/Layout";
import { useEffect, useState } from "react";
import { Page, TopicFeedback, TopicFitler, TopicStatistical, TypeTopic } from "../../../interface";
import UserModel from "../../../model/UserModel";
import { useSearchParams } from "react-router-dom";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import LinkUtil from "../../../utils/LinkUtil";
import TopicFeedbackModel from "../../../model/TopicFeedbackModel";
import ConfigConstant from "../../../constant/config";
import MessageFeedbackModel from "../../../model/MessageFeedbackModel";

const default_avatar = "http://cdn.onlinewebfonts.com/svg/img_264570.png";
function ListTopicPage(props: any) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [topics, setTopics] = useState<Array<TopicFeedback>>();
  const [filter, setFilter] = useState<TopicFitler>({});
  const [stats, setStats] = useState<TopicStatistical>();


  const fetchTopics = () => {
    TopicFeedbackModel.find({
      topicId: filter.topicId,
      subTopicId: filter.subTopicId
    }).then((resp) => {
      if (resp.error === 0) {
        setTopics(resp.data);
      }
    });
  };

  const fetchStats = () => {
    MessageFeedbackModel.stats({
      topicId: filter.topicId,
      subTopicId: filter.subTopicId
    }).then((resp) => {
      if (resp.error == 0) {
        setStats(resp.data)
      }
    })
  }

  useEffect(() => {
    setFilter(LinkUtil.params2Object(searchParams))
  }, [searchParams])

  useEffect(() => {
    fetchTopics();
    fetchStats()
  }, [filter]);



  return (
    <Layout>
      <main className="content">
        <div className="container-fluid">
          <div className="header">
            <h1 className="header-title">Chủ đề</h1>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-3 col-xl">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col mt-0">
                      <h5 className="card-title">Đã tiếp nhận</h5>
                    </div>
                    <div className="col-auto">
                      <div className="avatar">
                        <div className="avatar-title rounded-circle bg-primary-dark">
                          <i className="align-middle fas fa-fw fa-store"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h1 className="display-5 mt-1 mb-3">
                    {stats?.total}
                  </h1>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-3 col-xl">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col mt-0">
                      <h5 className="card-title">Đang xử lý</h5>
                    </div>
                    <div className="col-auto">
                      <div className="avatar">
                        <div className="avatar-title rounded-circle bg-primary-dark">
                          <i className="align-middle fas fa-fw  fa-ad"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h1 className="display-5 mt-1 mb-3">
                    {stats?.init}
                  </h1>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-3 col-xl">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col mt-0">
                      <h5 className="card-title">Đã phẩn hồi</h5>
                    </div>
                    <div className="col-auto">
                      <div className="avatar">
                        <div className="avatar-title rounded-circle bg-primary-dark">
                          <i className="align-middle far fa-fw fa-heart"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h1 className="display-5 mt-1 mb-3">
                    {stats?.received}
                  </h1>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-3 col-xl">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col mt-0">
                      <h5 className="card-title">Đóng</h5>
                    </div>
                    <div className="col-auto">
                      <div className="avatar">
                        <div className="avatar-title rounded-circle bg-primary-dark">
                          <i className="align-middle fas fa-fw fa-gift"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h1 className="display-5 mt-1 mb-3">
                    {stats?.close}
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-xl-12">
              <div className="card">
                <div className="card-bar" style={{ padding: "10px" }}>
                </div>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Phòng ban</th>
                      <th>Đang xử lý</th>
                      <th>Đã tiếp nhận</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {topics &&
                      topics.map((topic, key) => {
                        return (
                          <tr key={topic.id}>
                            <td>{topic.title}</td>
                            <td>
                              {topic.holders && topic.holders.map((holder, key) => {
                                return (
                                  <>
                                    <span>{holder.name || 'Knauf'}</span>
                                  </>
                                )
                              })}
                              {!topic.holders || topic.holders.length <= 0 && 'N/A'}
                            </td>
                            <td>
                              <span
                                style={{
                                  backgroundColor: 'red'
                                }}
                                className="badge badge-info"
                              >
                                {topic.statistical.init}
                              </span>
                            </td>
                            <td>{topic.statistical.total}</td>
                            <td className="table-action">
                              <Button
                                onClick={() => {
                                  if (topic.topicIds?.length > 0) {
                                    navigate(
                                      `/${props.config.key}/topic?topicId=${topic.id}`
                                    );
                                  } else {
                                    if (topic.type == TypeTopic.PARENT) {
                                      navigate(
                                        `/${ConfigConstant.FEEDBACK.key}/message/list?topicId=${topic.id}`
                                      );
                                    } else {
                                      navigate(
                                        `/${ConfigConstant.FEEDBACK.key}/message/list?topicId=${filter.topicId}&subTopicId=${topic.id}`
                                      );
                                    }

                                  }
                                }}
                                type="primary"
                              >
                                Chi tiết
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}

export default ListTopicPage;
