import Layout from "../../../component/Layout";
import { useCallback, useEffect, useState } from "react";
import { Gift, Page, Promotion, RegistrationEvent, RegistrationEventFilter } from "../../../interface";
import { useSearchParams } from "react-router-dom";
import { Button, Form, Input, List, Modal, Pagination, Popconfirm, Select, Tag } from "antd";
import LinkUtil from "../../../utils/LinkUtil";
import RegistrationEventModel from "../../../model/RegistrationEventModel";
import PromotionModel from "../../../model/PromotionModel";
import * as StatusPromotion from '../../../constant/status/PromotionStatus'
import AlertUtils from "../../../utils/AlertUtils";
import GiftModel from "../../../model/GiftModel";
import * as TypeGift from '../../../constant/type/GiftType'
import DateTimeUtil from "../../../utils/DateTimeUtil";
import * as StatusRegistrationEvent from '../../../constant/status/StatusRegistrationEvent';
import { RollbackOutlined, DownloadOutlined } from "@ant-design/icons";
import { City } from "../../../utils/ProvinceUtil";

const default_avatar = "http://cdn.onlinewebfonts.com/svg/img_264570.png";
const PAGE_SIZE = 20;
function ListRegistrationPage() {
  const [showSelectPromotion, setShowSelectPromotion] = useState(false)
  const [openGiftModal, setOpenGiftModal] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams();
  const [registrationEvents, setRegistrationEvents] = useState<Page<RegistrationEvent>>();
  const [promotion, setPromotion] = useState<Promotion>()
  const [filter, setFilter] = useState<RegistrationEventFilter>({ ...LinkUtil.params2Object(searchParams), page: 0 });


  useEffect(() => {
    let _promotionId = window.localStorage.getItem('promotionId')
    if (_promotionId) {
      searchParams.set('promotionId', _promotionId);
      setSearchParams(searchParams)
    }
  }, [])

  const fetchData = () => {
    RegistrationEventModel.find({
      promotionId: filter.promotionId || 2423,
      page: filter.page,
      pageSize: PAGE_SIZE
    }).then((resp) => {
      if (resp.error === 0) {
        setRegistrationEvents(resp.data);
      }
    });
  };

  const fetchPromotion = (promotionId) => {
    PromotionModel.get(promotionId)
      .then(resp => {
        if (resp.error === 0) {
          setPromotion(resp.data)
        }
      })
  }

  useEffect(() => {
    setFilter({ ...LinkUtil.params2Object(searchParams) })
  }, [searchParams])


  useEffect(() => {
    if (filter.promotionId && filter.promotionId > 0) {
      fetchPromotion(filter.promotionId)
      fetchData();
      setShowSelectPromotion(false)
    } else {
      setShowSelectPromotion(true)
    }
  }, [filter]);


  const closeCheckin = useCallback(() => {
    let promotionId = filter.promotionId;
    if (promotionId && promotionId > 0) {
      PromotionModel.updateStatus(promotionId, StatusPromotion.CLOSE_CHECKIN)
        .then(resp => {
          fetchPromotion(promotionId);
          if (resp.error === 0) {
            AlertUtils.showSuccess("Đã đóng checkin")
          }
        })
    }
  }, [filter.promotionId])


  const sendLuckySpinAllRemaining = () => {
    RegistrationEventModel.sendLuckySpinAllRemaining(filter.promotionId)
      .then(resp => {
        if (resp.error == 0) {
          fetchData()
          AlertUtils.showSuccess("Đã gửi vòng quay thanh công")
        } else {
          AlertUtils.showError(resp.msg)
        }
      })
  }

  return (
    <Layout>
      <main className="content">
        {showSelectPromotion &&
          <PromotionSelection onChange={(id) => {
            setShowSelectPromotion(false)
            searchParams.set('promotionId', id + '');
            setSearchParams(searchParams)
            window.localStorage.setItem('promotionId', id + '')
          }} promotionId={filter.promotionId || 0} />
        }
        {!showSelectPromotion &&
          <div className="container-fluid">
            <div className="header">
              <h1 className="header-title">{promotion?.title} <RollbackOutlined onClick={() => { setShowSelectPromotion(true) }} /></h1>
            </div>
            <div className="row">
              <div className="col-12 col-xl-12">
                <div className="card">
                  <div className="card-bar" style={{ padding: "10px" }}>
                    <div className="row" >
                      <div className="col-4" style={{ textAlign: 'left' }}>
                        <div className="mr-3">
                          <Tag color={StatusPromotion.findColor(promotion?.status)}>{StatusPromotion.findName(promotion?.status)}</Tag>
                        </div>
                      </div>
                      <div className="col-8" style={{ textAlign: 'right' }}>
                        {promotion?.status === StatusPromotion.CLOSE_CHECKIN &&
                          <div className="mr-3 inline-block">
                            <Popconfirm onConfirm={() => {
                              sendLuckySpinAllRemaining()
                            }} title="Bạn có chắc đã quay lucky draw ?">
                              <Button type={"primary"}>
                                Send Lucky Spin
                              </Button>
                            </Popconfirm>

                          </div>
                        }
                        {promotion?.status === StatusPromotion.CLOSE_CHECKIN &&
                          <div className="mr-3 inline-block">
                            <Button type={"primary"} onClick={() => { setOpenGiftModal(true) }}>
                              Xác nhận trúng
                            </Button>
                          </div>
                        }
                        {promotion?.status === StatusPromotion.APPROVED &&
                          <div className="mr-3 inline-block">
                            <Popconfirm
                              title="Are you sure?"
                              onConfirm={() => { closeCheckin() }}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Button type={"primary"}>
                                Đóng Checkin
                              </Button>
                            </Popconfirm>
                          </div>
                        }
                        <div className="mr-3 inline-block">
                          <Button type="primary" onClick={() => {
                            window.location.href =
                              process.env.REACT_APP_DOMAIN +
                              `/api/registration-event/export-txt?promotionId=${filter.promotionId}`;
                          }} shape="round" icon={<DownloadOutlined />}>
                            Codes
                          </Button>
                        </div>
                        <div className="mr-3 inline-block">
                          <Button onClick={() => {
                            window.location.href =
                              process.env.REACT_APP_DOMAIN +
                              `/api/registration-event/export-excel?promotionId=${filter.promotionId}`;
                          }} type="primary" shape="round" icon={<DownloadOutlined />} >Excel</Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th></th>
                        <th>Tên</th>
                        <th>SDT</th>
                        <th>Tỉnh thành</th>
                        <th>Mã tham gia</th>
                        <th>Thời gian đăng ký</th>
                        <th>Thời gian check-in</th>
                        <th>Phần thưởng</th>
                        <th>Trạng thái</th>
                      </tr>
                    </thead>
                    <tbody>
                      {registrationEvents &&
                        registrationEvents.list &&
                        registrationEvents.list.map((registrationEvent, key) => {
                          return (
                            <tr key={key}>
                              <td>{registrationEvent.id}</td>
                              <td>
                                <img
                                  className="m-avatar"
                                  alt=""
                                  src={registrationEvent?.user?.avatar || default_avatar}
                                />
                              </td>
                              <td>{registrationEvent?.user?.name}</td>
                              <td>{registrationEvent?.user?.phone}</td>
                              <td>{City.getName(registrationEvent?.user?.cityId)}</td>
                              <td>{registrationEvent?.code}</td>
                              <td>{DateTimeUtil.toString(registrationEvent?.createdTime * 1000)}</td>
                              <td>{registrationEvent?.timeCheckIn > 0 ? DateTimeUtil.toString(registrationEvent?.timeCheckIn) : ''}</td>
                              <td>{registrationEvent?.giftName}</td>
                              <td>
                                <span
                                  style={{
                                    backgroundColor: StatusRegistrationEvent.findColor(
                                      registrationEvent.status
                                    ),
                                  }}
                                  className="badge badge-info"
                                >
                                  {StatusRegistrationEvent.findName(registrationEvent.status)}
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  <div className="cart-paging">
                    {registrationEvents && (
                      <Pagination
                        defaultCurrent={1}
                        defaultPageSize={PAGE_SIZE}
                        current={(filter.page || 0) + 1}
                        onChange={(pageNumber) => {
                          setFilter({ ...filter, page: pageNumber - 1 });
                        }}
                        showSizeChanger={false}
                        total={registrationEvents.totalPage * PAGE_SIZE}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </main>
      <UpdateCodeLuckyDrawModal promotionId={filter.promotionId}
        open={openGiftModal} onOk={() => { fetchData(); setOpenGiftModal(false) }} onCancel={() => {
          setOpenGiftModal(false)
        }} />
    </Layout>
  );
}
export default ListRegistrationPage;


// ------------------

type LayoutType = Parameters<typeof Form>[0]['layout'];

const UpdateCodeLuckyDrawModal = ({ promotionId, open, onOk, onCancel }) => {
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState<LayoutType>('horizontal');
  const [gifts, setGifts] = useState<Array<Gift>>()

  const onFormLayoutChange = ({ layout }: { layout: LayoutType }) => {
    setFormLayout(layout);
  };

  useEffect(() => {
    GiftModel.find(promotionId, TypeGift.LUCKY_DRAW)
      .then(resp => {
        if (resp.error === 0) {
          setGifts(resp.data);
        }
      })
  }, [promotionId])

  const submit = () => {
    RegistrationEventModel.sendGiftLuckyDraw(form.getFieldsValue())
      .then(resp => {
        if (resp.error == 0) {
          AlertUtils.showSuccess("Thành cồng")
          onOk()
        } else {
          AlertUtils.showError(resp.msg)
        }
      })
  }

  return (
    <Modal open={open} onCancel={onCancel} onOk={() => {
      let data = form.getFieldsValue();
      if (data.giftId > 0 && data.code) {
        submit()
      }
    }} title="Kết quả Lucky Draw">
      <Form
        layout={formLayout}
        form={form}
        initialValues={{ layout: formLayout }}
        onValuesChange={onFormLayoutChange}
        style={{ maxWidth: formLayout === 'inline' ? 'none' : 600 }}
      >
        <Form.Item name="code" label="Code">
          <Input datatype="number" placeholder="123456" />
        </Form.Item>
        <Form.Item name="giftId" label="Quà">
          <Select
            options={gifts ? gifts.map(gift => {
              return {
                value: gift.id,
                label: gift.name
              }
            }) : []}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

// ------------------
type PromotionSelectionProps = {
  promotionId: number;
  onChange: (id: number) => void;
}

const PromotionSelection = ({ promotionId, onChange }: PromotionSelectionProps) => {
  const [promotions, setPromotions] = useState<Array<Promotion>>()

  const fetchPromotions = () => {
    PromotionModel.find({
      types: [61],
      page: 0,
      pageSize: 100
    }).then((resp) => {
      if (resp.error === 0) {
        setPromotions(resp.data.list);
      }
    });
  }

  useEffect(() => {
    fetchPromotions()
  }, [])

  return <>
    <div className="container-fluid">
      <div className="header">
        <h1 className="header-title">Vui lòng chọn sự kiện</h1>
      </div>
      <div className="row">
        <div className="col-5 col-xl-5 mx-auto">
          <div className="card">
            <div className="card-bar" style={{ padding: "10px" }}>
              <div className="row" style={{ padding: '20px' }} >
                <List
                  style={{ width: '100%' }}
                  size="large"
                  bordered
                  dataSource={promotions}
                  renderItem={(item) => <List.Item>
                    <div onClick={() => {
                      onChange(item.id)
                    }} style={{ fontWeight: promotionId == item.id ? '600' : 'normal', display: 'flex', justifyContent: 'space-between', width: '100%', cursor: 'pointer' }}>
                      <span>
                        {item.title}
                      </span>
                      <span>{`>`}</span>
                    </div>
                  </List.Item>}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </>
}
