import Layout from "../../../component/Layout";
import "../../../component/popup/styles.scss";
import { useEffect, useState } from "react";
import { Page } from "../../../interface";
import DateTimeUtil from "../../../utils/DateTimeUtil";
import { Button } from "antd";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Pagination } from "antd";
import LinkUtil from "../../../utils/LinkUtil";
import QrStatus from "../../../constant/status/QrStatus";
import QrModel from "../../../model/QrModel";

const PAGE_SIZE = 10;
function ListQrCode(props: any) {
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [qrPage, setQrPage] = useState<Page<any>>();
  const [filter, setFilter] = useState<any>(
    LinkUtil.initParamWithDefault(searchParams, {
      status: 0,
    })
  );

  const findQrs = () => {
    QrModel.find(filter.search, filter.status, filter.page, PAGE_SIZE).then(
      (resp) => {
        if (resp.error === 0) {
          setQrPage(resp.data);
        }
      }
    );
  };

  const export_excel = () => {
    let { status } = filter;
    let param = Object.fromEntries(
      Object.entries({
        status,
      }).filter(([_, v]) => v != null)
    ) as any;
    window.location.href =
      process.env.REACT_APP_DOMAIN +
      `/api/qr-code/export-excel?${new URLSearchParams(param).toString()}`;
    return;
  };

  const serialize2ParamsUrl = () => {
    searchParams.set("q", JSON.stringify(filter));
    setSearchParams(searchParams);
  };

  useEffect(() => {
    findQrs();
    serialize2ParamsUrl();
  }, [filter]);

  return (
    <Layout>
      <main className="content">
        <div className="container-fluid">
          <div className="header">
            <h1 className="header-title">Danh sách QR code</h1>
          </div>
          <div className="row">
            <div className="col-12 col-xl-12">
              <div className="card">
                <div className="card-bar" style={{ padding: "10px" }}>
                  <div className="row ">
                    <div className="col-2 col-xl-2 ml-auto">
                      <input
                        className="form-control"
                        placeholder="QR code"
                        type="text"
                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                          setFilter({
                            ...filter,
                            search: e.currentTarget.value,
                          });
                        }}
                      />
                    </div>
                    <div className="col-2 col-xl-2">
                      <select
                        value={filter?.status}
                        onChange={(e: React.FormEvent<HTMLSelectElement>) => {
                          setFilter({
                            ...filter,
                            status: Number(e.currentTarget.value),
                          });
                        }}
                        className="form-control"
                      >
                        <option value={0}>Trạng thái</option>
                        <option value={QrStatus.ACTIVATE}>
                          {QrStatus.findName(QrStatus.ACTIVATE)}
                        </option>
                        <option value={QrStatus.DEACTIVATE}>
                          {QrStatus.findName(QrStatus.DEACTIVATE)}
                        </option>
                      </select>
                    </div>
                    <div className="col-2 col-xl-2">
                      <Button type="primary" onClick={export_excel}>
                        Xuất Excel
                      </Button>
                    </div>
                  </div>
                </div>
                <table className="table">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Code</th>
                      <th>Thời gian</th>
                      <th>Trạng thái</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {qrPage &&
                      qrPage?.list.map((item) => (
                        <tr key={item.id}>
                          <td>{item.id}</td>
                          <td>{item.code}</td>
                          <td>
                            {DateTimeUtil.toStringDetail(
                              item.createdTime * 1000
                            )}
                          </td>
                          <td>
                            <span
                              style={{
                                backgroundColor: QrStatus.findColor(
                                  item.status
                                ),
                              }}
                              className="badge badge-info"
                            >
                              {QrStatus.findName(item.status)}
                            </span>
                          </td>
                          <td className="table-action">
                            {item.isUsedFormId != 0 ? (
                              <Button
                                onClick={() => {
                                  navigate(
                                    `/${props.config.key}/form/${item.isUsedFormId}/scan-qr`
                                  );
                                }}
                                type="primary"
                              >
                                Chi tiết
                              </Button>
                            ) : (
                              "None"
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <div className="cart-paging">
                  {qrPage && (
                    <Pagination
                      defaultCurrent={1}
                      current={filter.page + 1}
                      onChange={(pageNumber) => {
                        setFilter({ ...filter, page: pageNumber - 1 });
                      }}
                      total={qrPage.totalPage * PAGE_SIZE}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}

export default ListQrCode;
