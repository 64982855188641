export default class LinkUtil {

    static initParam(searchParams) {
        var q = searchParams.get("q");
        return JSON.parse(q ? q : '{}')
    }

    static initParamWithDefault(searchParams, dataDefault) {
        var q = searchParams.get("q");
        return JSON.parse(q ? q : JSON.stringify(dataDefault))
    }

    static params2Object(searchParams) {
        const params = {};
        for (let [key, value] of searchParams.entries()) {
            params[key] = value;
        }
        return params;
    }

}