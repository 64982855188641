export const INIT = 1;
export const CHECKIN = 2;

export function findName(value) {
    switch (value) {
        case INIT: return 'Đăng ký'
        case CHECKIN: return 'Đã checkin'
    }
}

export function findColor(value) {
    switch (value) {
        case INIT: return '#dc3535'
        case CHECKIN: return '#28a745'
    }
}