import Layout from "../../../component/Layout";
import "../../../component/popup/styles.scss";
import { Button, Col, Input, Modal, Row, Pagination } from "antd";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import LinkUtil from "../../../utils/LinkUtil";
import MatchModel from "../../../model/MatchModel";
import ConfigConstant from "../../../constant/config";
import FormModel from "../../../model/FormModel";
import { City } from "../../../utils/ProvinceUtil";

import AlertUtils from "../../../utils/AlertUtils";
import PredictMatchFootballStatus from "../../../constant/status/PredictMatchFootballStatus";
import * as FormStatus from "../../../constant/status/FormStatus";
import { useEffect, useState } from "react";

const PAGE_SIZE = 10;
function ListFormOfMatch(props: any) {
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { matchId } = useParams();
  const [forms, setForms] = useState<any>(null);
  const [match, setMatch] = useState<any>(null);
  const [filter, setFilter] = useState<any>(
    LinkUtil.initParamWithDefault(searchParams, {
      status: 0,
    })
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [matchResult, setMatchResult] = useState<any>(null);

  const submitModal = () => {
    MatchModel.updateResult(
      matchId,
      matchResult.goalHost || 0,
      matchResult.goalGuest || 0
    )
      .then((res) => {
        setIsModalOpen(false);
        if (res.error === 0) {
          AlertUtils.showSuccess("Cập nhập kêt quả thành công");
        } else {
          AlertUtils.showError(res.msg);
        }
      })
      .finally(() => {
        findForm();
      });
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const findForm = () => {
    FormModel.findV2(
      ConfigConstant.PREDICT_FOOTBALL.promotionId,
      matchId,
      filter.search,
      filter.status,
      filter.type,
      filter.page,
      PAGE_SIZE
    ).then((result) => {
      console.log(result.data);
      setForms(result.data);
    });
  };

  const getMatch = () => {
    MatchModel.get(matchId).then((res) => {
      setMatch(res.data);
      setMatchResult({
        goalHost: res.data.goalHost || 0,
        goalGuest: res.data.goalGuest || 0,
      });
    });
  };

  const serialize2ParamsUrl = () => {
    searchParams.set("q", JSON.stringify(filter));
    setSearchParams(searchParams);
  };

  const getResult = (item) => {
    let winner = item.winner;
    if (winner === item.match.oHost.id) {
      return item.match.oHost.name + " thắng";
    }
    if (winner === item.match.oGuest.id) {
      return item.match.oGuest.name + " thắng";
    }
    return "Hòa";
  };

  useEffect(() => {
    console.log(matchResult);
  }, [matchResult]);

  useEffect(() => {
    getMatch();
    findForm();
    serialize2ParamsUrl();
  }, [filter]);

  return (
    <Layout>
      <main className="content">
        <div className="container-fluid">
          <div className="header">
            <h1 className="header-title">Danh sách dự đoán trận đấu</h1>
          </div>
          <div className="row">
            <div className="col-12 col-xl-12">
              <div className="card">
                <div className="card-bar" style={{ padding: "10px" }}>
                  <div style={{ float: "right" }}>
                    <Button type="primary" onClick={() => setIsModalOpen(true)}>
                      Kêt quả trận đấu
                    </Button>
                  </div>
                </div>
                <table className="table">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Họ tên</th>
                      <th>Khu vực</th>
                      <th>Trận đấu</th>
                      <th>Dự đoán</th>
                      <th>Trạng thái</th>
                      <th>Kết quả</th>
                      <th>Hành động</th>
                    </tr>
                  </thead>
                  <tbody>
                    {forms &&
                      forms?.list.map((item) => (
                        <tr key={item.id}>
                          <td>{item.id}</td>
                          <td>{item.user.name}</td>
                          <td>{City.getName(item.user.cityId)}</td>
                          <td>{`${item.match.oHost.name} - ${item.match.oGuest.name}`}</td>
                          <td>{getResult(item)}</td>
                          <td>
                            <span
                              style={{
                                backgroundColor: FormStatus.findColor(
                                  item.status
                                ),
                              }}
                              className="badge badge-info"
                            >
                              {FormStatus.findName(item.status)}
                            </span>
                          </td>
                          <td>
                            <span
                              style={{
                                backgroundColor:
                                  PredictMatchFootballStatus.findColor(
                                    item.predictStatus
                                  ),
                              }}
                              className="badge badge-info"
                            >
                              {PredictMatchFootballStatus.findName(
                                item.predictStatus
                              )}
                            </span>
                          </td>
                          <td className="table-action">
                            <Button
                              type="primary"
                              onClick={() =>
                                navigate(
                                  `/${ConfigConstant.PREDICT_FOOTBALL.key}/form/detail/${item.id}`
                                )
                              }
                            >
                              Chi tiết
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <div className="cart-paging">
                  {forms && (
                    <Pagination
                      defaultCurrent={1}
                      current={filter.page + 1}
                      onChange={(pageNumber) => {
                        setFilter({ ...filter, page: pageNumber - 1 });
                      }}
                      total={forms.totalPage * PAGE_SIZE}
                    />
                  )}
                </div>
                <Modal
                  title={"Cập nhập kết quả"}
                  visible={isModalOpen}
                  onOk={submitModal}
                  onCancel={closeModal}
                >
                  <Row>
                    <Col sm={10} className={`text-center`}>
                      <p>{match?.cHost?.name}</p>
                    </Col>
                    <Col sm={4} className={`text-center`}></Col>
                    <Col sm={10} className={`text-center`}>
                      <p>{match?.cGuest?.name}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={10} className={`text-center`}>
                      <Input
                        type="number"
                        min={0}
                        value={matchResult?.goalHost || 0}
                        onChange={(e) =>
                          setMatchResult({
                            ...matchResult,
                            goalHost: Number(e.target.value),
                          })
                        }
                      />
                    </Col>
                    <Col sm={4} className={`text-center`}></Col>
                    <Col sm={10} className={`text-center`}>
                      <Input
                        type="number"
                        min={0}
                        value={matchResult?.goalGuest || 0}
                        onChange={(e) =>
                          setMatchResult({
                            ...matchResult,
                            goalGuest: Number(e.target.value),
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}

export default ListFormOfMatch;
