const month_to_vi = {
    1: 'tháng 1',
    2: 'tháng 2',
    3: 'tháng 3',
    4: 'tháng 4',
    5: 'tháng 5',
    6: 'tháng 6',
    7: 'tháng 7',
    8: 'tháng 8',
    9: 'tháng 9',
    10: 'tháng 10',
    11: 'tháng 11',
    12: 'tháng 12'
}
export default class DateTimeUtil {
    static toString(timestamp) {
        let date = new Date(timestamp);
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        return day + ' ' + month_to_vi[month] + ', ' + year;
    }

    static toStringDetail(timestamp) {
        let date = new Date(timestamp);
        let hour = date.getHours();
        let minutes = date.getMinutes()
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        return hour + "h" + minutes + " - " + day + ' ' + month_to_vi[month] + ', ' + year;

    }

    static toStringForSysten(timestamp) {
        let date = new Date(timestamp);
        return date.getFullYear() + "-" + date.getMonth() + 1 + "-" + date.getDate();
    }

    static parseDate(str) {
        let arr = str.split("-");
        return new Date(arr[0], arr[1] - 1, arr[2]);
    }

    static toStringNotYear(date) {
        let mon = date.getMonth() + 1;
        let day = date.getDate();
        return day + ', tháng' + mon;
    }

    static calculateDaysDifferenceWithoutWeekends(timestamp1, timestamp2) {
        const date1 = new Date(timestamp1);
        const date2 = new Date(timestamp2);

        const startDate = date1 < date2 ? date1 : date2;
        const endDate = date1 < date2 ? date2 : date1;

        let daysDifference = 0;

        let currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
                daysDifference++;
            }

            currentDate.setDate(currentDate.getDate() + 1);
        }

        return daysDifference;
    }

    static initDate(timestamp) {
        return timestamp > 0 ? new Date(timestamp) : new Date()
    }

    static formatDateDefaultBrowser(date) {
        const yyyy = date.getFullYear();
        let mm = date.getMonth() + 1; // month is zero-based
        let dd = date.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        return yyyy + '-' + mm + '-' + dd;
    }

}

