import APIUtil from "../utils/APIUtils";

export default class DraftProfileModel {

    static find({ page, pageSize }) {
        var param = Object.fromEntries(
            Object.entries({
                page,
                pageSize,
            }).filter(([_, v]) => v != null && v != 0)
        );

        return new Promise((resolve, reject) => {
            APIUtil.getJSONWithCredentials(
                process.env.REACT_APP_DOMAIN +
                `/api/draft-profile/find?${new URLSearchParams(param).toString()}`,
                resolve,
                reject
            );
        });
    }

    static updateStatus(id, status, note) {
        var form = {
            id,
            status,
            note
        }
        return new Promise((resolve, reject) => {
            APIUtil.postJSONWithCredentials(process.env.REACT_APP_DOMAIN
                + `/api/draft-profile/update-status?id=${id}`,
                JSON.stringify(form), resolve, reject);
        });
    }

    static get(id) {
        return new Promise((resolve, reject) => {
            APIUtil.getJSONWithCredentials(
                process.env.REACT_APP_DOMAIN +
                `/api/draft-profile/get?id=${id}`,
                resolve,
                reject
            );
        });
    }

}