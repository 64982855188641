import Layout from "../../../component/Layout";
import "../../../component/popup/styles.scss";
import { useEffect, useState } from "react";
import {
  CountUserDashboard,
  UserCityMetric,
  UserDateMetric,
} from "../../../interface";
import UserModel from "../../../model/UserModel";
import {
  UserCityMetricChart,
  UserCityMetricChartV2,
  UserDateMetricChart,
} from "../../../component/chart";
import FormModel from "../../../model/FormModel";
import { APPROVED, INIT, REJECTED } from "../../../constant/status/FormStatus";
import * as GiftOrderStatus from "../../../constant/status/GiftOrderStatus";
import AccumulationModel from "../../../model/AccumulationModel";
import NumberUtils from "../../../utils/NumberUtils";
import ConfigConstant from "../../../constant/config";
import GiftOrderModel from "../../../model/GiftOrderModel";

function DashboardPage() {
  const [countUser, setCountUser] = useState<CountUserDashboard>();
  const [lstUserCity, setLstUserCity] = useState<Array<UserCityMetric>>();
  const [lstUserDate, setLstUserDate] = useState<Array<UserDateMetric>>();
  const [approvedForms, setApprovedForms] = useState(0);
  const [totalForms, setTotalForms] = useState(0);
  const [waitingForms, setWaitingForms] = useState(0);
  const [rejectForms, setRejectForms] = useState(0);
  const [analyticsAccumulation, setAnalyticsAccumulation] = useState<any>({});
  // const [analyticsGift, setAnalyticsGift] = useState({
  //   totalGift: 0,
  //   qtySend: 0,
  // });
  const [totalQtyGift, setTotalQtyGift] = useState(0);
  const [qtySendGift, setQtySendGift] = useState(0);
  const GIFT_ID = 1;

  const getQtySendGift = () => {
    GiftOrderModel.countQtyGiftOrder(
      GiftOrderStatus.SEND,
      GIFT_ID,
      ConfigConstant.RTL_EP01_FORM_TYPE.promotionId
    ).then((res) => setQtySendGift(res.data));
  };

  const getQtyTotalGift = () => {
    GiftOrderModel.countQtyGiftOrder(
      GiftOrderStatus.INIT,
      GIFT_ID,
      ConfigConstant.RTL_EP01_FORM_TYPE.promotionId
    ).then((res) => setTotalQtyGift(res.data));
  };

  const fetchCountUser = () => {
    UserModel.count().then((resp) => {
      if (resp.error === 0) {
        setCountUser(resp.data);
      }
    });
  };

  const fetchUserCityMetric = () => {
    UserModel.statsUserCity().then((resp) => {
      if (resp.error === 0) {
        let tmp = resp.data.map((item) => {
          return {
            city: item.city,
            total: item.retailer,
          };
        });
        setLstUserCity(tmp);
      }
    });
  };

  const fetchUserDateMetric = () => {
    UserModel.statsUserDate().then((resp) => {
      if (resp.error === 0) {
        setLstUserDate(resp.data);
      }
    });
  };

  const fetchCountByStatus = (status: any) => {
    FormModel.countByStatus(
      status,
      ConfigConstant.RTL_EP01_FORM_TYPE.promotionId
    ).then((resp) => {
      if (status === null) {
        setTotalForms(resp.data);
      }
      if (status === APPROVED) {
        setApprovedForms(resp.data);
      }
      if (status === INIT) {
        setWaitingForms(resp.data);
      }
      if (status === REJECTED) {
        setRejectForms(resp.data);
      }
    });
  };

  const fetchAnalyticsAccumulationMetric = () => {
    AccumulationModel.analytics(
      ConfigConstant.RTL_EP01_FORM_TYPE.promotionId
    ).then((resp) => {
      if (resp.error === 0) {
        setAnalyticsAccumulation(resp.data);
      }
    });
  };

  useEffect(() => {
    fetchCountUser();
    fetchUserCityMetric();
    fetchUserDateMetric();
    fetchCountByStatus(null);
    fetchCountByStatus(APPROVED);
    fetchCountByStatus(INIT);
    fetchCountByStatus(REJECTED);
    fetchAnalyticsAccumulationMetric();
    getQtyTotalGift();
    getQtySendGift();
  }, []);

  return (
    <Layout>
      <main className="content">
        <div className="container-fluid">
          <div className="header row" style={{ marginBottom: "20px" }}>
            <div className="col-6">
              <h1 className="header-title">Tổng quan</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-3 col-xl">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col mt-0">
                      <h5 className="card-title">Cửa hàng/ đại lý</h5>
                    </div>
                    <div className="col-auto">
                      <div className="avatar">
                        <div className="avatar-title rounded-circle bg-primary-dark">
                          <i className="align-middle fas fa-fw fa-store"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h1 className="display-5 mt-1 mb-3">
                    {countUser?.numApprovedRetailer}
                  </h1>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-3 col-xl">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col mt-0">
                      <h5 className="card-title">Đơn</h5>
                    </div>
                    <div className="col-auto">
                      <div className="avatar">
                        <div className="avatar-title rounded-circle bg-primary-dark">
                          <i className="align-middle fas fa-fw  fa-ad"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h1 className="display-5 mt-1 mb-3">
                    <span style={{ color: "rgb(40, 167, 69)" }}>
                      {approvedForms}
                    </span>{" "}
                    / {waitingForms}/ {totalForms - rejectForms}
                  </h1>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-3 col-xl">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col mt-0">
                      <h5 className="card-title">Boards / Metals</h5>
                    </div>
                    <div className="col-auto">
                      <div className="avatar">
                        <div className="avatar-title rounded-circle bg-primary-dark">
                          <i className="align-middle far fa-fw fa-heart"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h1 className="display-5 mt-1 mb-3">
                    {analyticsAccumulation?.historyFrame
                      ? NumberUtils.formatNumber(
                          analyticsAccumulation?.historyFrame
                        )
                      : 0}

                    {" / "}
                    {analyticsAccumulation?.historyPlate
                      ? NumberUtils.formatNumber(
                          analyticsAccumulation?.historyPlate
                        )
                      : 0}
                  </h1>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-3 col-xl">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col mt-0">
                      <h5 className="card-title">Quà tặng</h5>
                    </div>
                    <div className="col-auto">
                      <div className="avatar">
                        <div className="avatar-title rounded-circle bg-primary-dark">
                          <i className="align-middle fas fa-fw fa-gift"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h1 className="display-5 mt-1 mb-3">
                    {qtySendGift} / {totalQtyGift}
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 col-xxl-6 d-flex">
              <div className="card flex-fill w-100">
                <div className="card-header">
                  <h5 className="card-title mb-0">
                    Top 10 cửa hàng phân bố theo vùng
                  </h5>
                </div>
                <div className="card-body px-4">
                  {lstUserCity && <UserCityMetricChartV2 data={lstUserCity} />}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xxl-6 d-flex">
              <div className="card flex-fill w-100">
                <div className="card-header">
                  <h5 className="card-title mb-0">
                    Số cửa hàng đăng ký theo ngày
                  </h5>
                </div>
                <div className="card-body py-3">
                  {lstUserDate && <UserDateMetricChart data={lstUserDate} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}

export default DashboardPage;
