import Modal from "react-responsive-modal";
import "../../../component/popup/styles.scss";
import { useEffect, useState } from "react";
import { ImgForm, RtlEp01Form } from "../../../interface";
import {
  AreYouSurePopup,
  AreYouSureWithNoteDropdownPopup,
  AreYouSureWithNotePopup,
} from "../../../component/popup";
import FormModel from "../../../model/FormModel";
import AlertUtils from "../../../utils/AlertUtils";
import * as StockFormStatus from "../../../constant/status/FormStatus";
import Layout from "../../../component/Layout";
import { useParams, useNavigate } from "react-router-dom";
import { DatePicker } from "antd";
import moment from "moment";
import { Image } from "antd";
import DistributorModel from "../../../model/DistributorModel";
import { Select } from 'antd';
import { useFindStore } from "../../../hooks/user";
import { SelectDistributor } from "../../../component/common/distributor_select";

let list_reason_form_reject = [
  "Hóa đơn của Qúy Khách bị mờ, vui lòng kiểm tra và đăng lên lại nhé",
  "Hóa đơn của Qúy Khách bị thiếu thông tin nhà phân phối, Qúy Khách vui lòng bổ sung lại đầy đủ nhé",
  "Hóa đơn của Qúy Khách đã bị trùng với hóa đơn trước đó",
  "Hóa đơn của Qúy Khách bị thiếu ngày, tháng mua hàng, Qúy Khách vui lòng bổ sung lại đầy đủ nhé",
  "Hình ảnh xe hàng của Qúy Khách không có định vị + thời gian, Qúy Khách vui lòng chụp ảnh bằng ứng dụng Timestamp để hiện thị định vị + thời gian nhé",
  "Hình ảnh xe hàng không hợp lệ, Qúy Khách vui lòng kiểm tra và bổ sung nhé",
  "Mặt hàng Qúy Khách mua trong hóa đơn không áp dụng trong CTKM này",
  "Nhà phân phối Qúy Khách chọn sai so với trên hóa đơn, Qúy Khách kiểm tra và chỉnh sửa lại nhé",
  "NPP trên hóa đơn của Qúy Khách là CH Chi Nhánh Đệ Nhất - Bình Tân, Qúy Khách vui lòng chỉnh sửa lại nhé",
  "NPP trên hóa đơn của Qúy Khách là CH Đệ nhất Bình Dương (Cty Việt Trường), Qúy Khách vui lòng chỉnh sửa lại nhé",
  "Quý CHDL vui lòng nhập đúng số lượng hàng hoá trên hoá đơn để được duyệt ngay"
];

function DetailRTLEP01FormPage() {
  let { id } = useParams();
  const navigate = useNavigate();

  const [isShowApporvedPopup, setIsShowApporvedPopup] = useState(false);
  const [isShowRecallPopup, setIsShowRecallPopup] = useState(false);
  const [isSaveButtonPopup, setIsSaveButtonPopup] = useState(false);
  const [isRejectPopup, setIsRejectPopup] = useState(false);
  const [form, setForm] = useState<RtlEp01Form>();
  const [originForm, setOriginForm] = useState<RtlEp01Form>();


  const updateStatus = (id: any, status: number, note: any) => {
    FormModel.updateStatus(id, status, note).then((resp) => {
      if (resp.error === 0) {
        navigate(-1);
        AlertUtils.showSuccess("Thành công!");
      } else {
        AlertUtils.showError(resp.msg);
      }
      fetchForm();
    });
  };

  const recallForm = (id: any, note: any) => {
    FormModel.recallForm(id, note).then((resp) => {
      if (resp.error === 0) {
        navigate(-1);
        AlertUtils.showSuccess("Thành công!");
      } else {
        AlertUtils.showError(resp.msg);
      }
      fetchForm();
    });
  };

  const updateForm = () => {
    FormModel.updateForm(form?.id, form).then((resp) => {
      if (resp.error == 0) {
        AlertUtils.showSuccess("Thành công!");
      } else {
        AlertUtils.showError(resp.msg);
      }
      fetchForm();
    });
  };

  const fetchForm = () => {
    FormModel.get(id).then((resp) => {
      if (resp.error === 0) {
        setForm(resp.data);
        setOriginForm(resp.data);
      }
    });
  };

  const _isNeedSave = () => {
    return JSON.stringify(originForm) != JSON.stringify(form);
  };

  useEffect(() => {
    fetchForm();
  }, []);


  return (
    <Layout>
      <main className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-xl-12">
              <div className="tab-content">
                <div
                  className="tab-pane fade show active"
                  id="account"
                  role="tabpanel"
                >
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title mb-0">Chi tiết</h5>
                    </div>
                    <div className="card-body">
                      {form && (
                        <form>
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Tên</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={form.user.name}
                                />
                              </div>
                              <div className="form-group">
                                <label>Cửa hàng</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={form.user.nameStore}
                                />
                              </div>
                              <div className="form-group">
                                <label>Số điện thoại</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={form.user.phone}
                                />
                              </div>
                              {!form.distributorId &&
                                <div className="form-group">
                                  <label>Nhà Phân Phối</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={form.distributorName}
                                  />
                                </div>
                              }
                              {form.distributorId &&
                                <div className="form-group">
                                  <label>Nhà Phân Phối</label>
                                  <SelectDistributor disabled={false} value={form.distributorId} onChange={(data) => {
                                    setForm({ ...form, distributorId: data })
                                  }} />
                                </div>
                              }
                              <div className="form-group">
                                <label>Số tấm</label>
                                <input
                                  onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                    setForm({ ...form, plate: Number(e.currentTarget.value) })
                                  }}
                                  type="text"
                                  className="form-control"
                                  value={form.plate}
                                />
                              </div>
                              <div className="form-group">
                                <label>Số khung</label>
                                <input
                                  onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                    setForm({ ...form, frame: Number(e.currentTarget.value) })
                                  }}
                                  type="text"
                                  className="form-control"
                                  value={form.frame}
                                />
                              </div>
                              <div className="form-group">
                                <label>Số Bột</label>
                                <input
                                  onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                    setForm({ ...form, compound: Number(e.currentTarget.value) })
                                  }}
                                  type="text"
                                  className="form-control"
                                  value={form.compound}
                                />
                              </div>
                              <div className="form-group">
                                <label>Điểm</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={form.point}
                                />
                              </div>
                              {form && form.secondPoint != 0 &&
                                <div className="form-group">
                                  <label>Điểm Đổi Bia</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={form.secondPoint}
                                  />
                                </div>
                              }
                              <div className="form-group">
                                <label>
                                  Ngày mua hàng ( Ghi Trên Hóa Đơn )
                                </label>
                                <DatePicker
                                  className="form-control"
                                  value={
                                    form.billCreatedTime
                                      ? moment.unix(form.billCreatedTime)
                                      : null
                                  }
                                  onChange={(date) => {
                                    setForm({
                                      ...form,
                                      billCreatedTime: Number(date?.unix()),
                                    });
                                  }}
                                  disabledDate={(current) => {
                                    let customDate = moment(
                                      form.createdTime * 1000
                                    ).format("YYYY-MM-DD");
                                    return (
                                      current &&
                                      current >
                                      moment(customDate, "YYYY-MM-DD").add(
                                        1,
                                        "days"
                                      )
                                    );
                                  }}
                                />
                              </div>
                              <div className="form-group">
                                <label>Note</label>
                                <textarea
                                  className="form-control"
                                  value={form.note}
                                />
                              </div>
                              <div className="form-group">
                                <label>Kho</label>
                                <input
                                  disabled
                                  style={{
                                    backgroundColor: `${form.distributorId ? '#1890ff' : '#bfbfbf'}`
                                  }}
                                  type="text"
                                  className="form-control input-detail-status"
                                  value={form.distributorId ? 'Đã liên kết' : 'Chưa liên kết'}
                                />
                              </div>
                              <div className="form-group">
                                <label>Trạng thái</label>
                                <input
                                  disabled
                                  style={{
                                    backgroundColor: StockFormStatus.findColor(
                                      form.status
                                    ),
                                  }}
                                  type="text"
                                  className="form-control input-detail-status"
                                  value={StockFormStatus.findName(form.status)}
                                />
                              </div>
                            </div>
                            {form && form.jsonImage &&
                              <div className="col-md-8">
                                <div className="text-center">
                                  <div className="previews-img-container">
                                    {form.jsonImage.map((jsonImg: ImgForm, index: number) => {
                                      return (
                                        <div key={index} className="item">
                                          <Image src={jsonImg?.url} />
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            }
                            {form && form.invoiceImage &&
                              <div className="col-md-8">
                                <div className="text-center">
                                  <div className="previews-img-container">
                                    <div className="item">
                                      <Image src={form.invoiceImage} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }
                          </div>
                        </form>
                      )}
                    </div>
                    <div className="card-footer">
                      {_isNeedSave() && form?.status == StockFormStatus.INIT && (
                        <>
                          <button
                            onClick={() => setIsSaveButtonPopup(true)}
                            style={{ marginRight: "20px" }}
                            className="btn btn-green"
                          >
                            Lưu
                          </button>
                          <AreYouSurePopup
                            open={isSaveButtonPopup}
                            onCloseModal={() => {
                              setIsSaveButtonPopup(false);
                            }}
                            onAgree={() => {
                              updateForm();
                              setIsSaveButtonPopup(false);
                            }}
                          />
                        </>
                      )}
                      {!_isNeedSave() && form?.status === StockFormStatus.INIT && (
                        <>
                          <button
                            onClick={() => {
                              if (!form.distributorId) {
                                AlertUtils.showWarning("Vui lòng chọn NPP")
                                return;
                              }

                              if (!form.billCreatedTime) {
                                AlertUtils.showWarning("Vui lòng cập nhật ngày mua hàng")
                                return;
                              }

                              setIsShowApporvedPopup(true)
                            }}
                            className="btn btn-danger m-btn-danger"
                          >
                            Chấp nhận
                          </button>
                          <AreYouSurePopup
                            open={isShowApporvedPopup}
                            onCloseModal={() => {
                              setIsShowApporvedPopup(false);
                            }}
                            onAgree={() => {
                              updateStatus(
                                form?.id,
                                StockFormStatus.APPROVED,
                                null
                              );
                              setIsShowApporvedPopup(false);
                            }}
                          />
                          <button
                            onClick={() => setIsRejectPopup(true)}
                            style={{ marginLeft: "30px", padding: "5px 30px" }}
                            className="btn btn-delete"
                          >
                            Từ chối
                          </button>
                          <AreYouSureWithNoteDropdownPopup
                            list={list_reason_form_reject}
                            open={isRejectPopup}
                            onCloseModal={() => {
                              setIsRejectPopup(false);
                            }}
                            onAgree={(note) => {
                              updateStatus(
                                form?.id,
                                StockFormStatus.REJECTED,
                                note
                              );
                              setIsRejectPopup(false);
                            }}
                          />
                        </>
                      )}
                      {form?.status === StockFormStatus.APPROVED && (
                        <>
                          <button
                            onClick={() => setIsShowRecallPopup(true)}
                            className="btn btn-danger m-btn-danger"
                          >
                            Thu hồi
                          </button>
                          <AreYouSureWithNotePopup
                            open={isShowRecallPopup}
                            onCloseModal={() => {
                              setIsShowRecallPopup(false);
                            }}
                            onAgree={(note) => {
                              recallForm(form?.id, note);
                              setIsShowRecallPopup(false);
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}

export default DetailRTLEP01FormPage;

const owlClass = "popup";
export function ImagePopup({ open, onCloseModal, url, time, location }: any) {
  return (
    <Modal
      open={open}
      onClose={onCloseModal}
      center
      showCloseIcon={false}
      styles={{
        modal: {
          background: "rgba(242, 242, 242, 0.94)",
          backdropFilter: "blur(54.3656px)",
          borderRadius: "14px",
          padding: "0",
          maxWidth: "80%",
        },
      }}
    >
      <div className={owlClass}>
        <div className={`${owlClass}__wrapper`}>
          <img
            style={{ maxHeight: "70vh", maxWidth: "70vw" }}
            src={url}
            alt=""
          />
        </div>
        <div className={`${owlClass}__group-btn`}>
          <div
            className={`${owlClass}__group-btn__item right`}
            onClick={onCloseModal}
          >
            Đóng
          </div>
        </div>
      </div>
    </Modal>
  );
}
