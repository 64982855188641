import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ConfigConstant from "../constant/config";
import StoreUtils from "../utils/StoreUtils";

function SelectFormTypePage(props: any) {
  const [config, setConfig] = useState<any>(ConfigConstant.RTL_EP01_FORM_TYPE);
  const navigate = useNavigate();
  useEffect(() => {
    let tmp = StoreUtils.getConfig();
    if (tmp) {
      setConfig(tmp);
      const queryString = new Proxy(
        new URLSearchParams(window.location.search),
        {
          get: (searchParams, prop: string) => searchParams.get(prop),
        }
      );
      console.log(queryString["src"]);
      if (!queryString["src"]) {
        navigate(`/${tmp.key}`);
      }
    }
  }, []);

  return (
    <main className="main h-100 w-100">
      <div className="container h-100">
        <div className="row h-100">
          <div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
            <div className="d-table-cell align-middle">
              <div className="text-center mt-4">
                <h1 className="h2">Welcome back, Knauf Admin </h1>
                <p className="lead">Chọn chương trình quản lý</p>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="m-sm-4">
                    <div className="text-center">
                      <img
                        src="https://marketplace.magento.com/media/catalog/product/4/a/4acb_rsz_admin-logo.png"
                        alt="Linda Miller"
                        className="img-fluid rounded-circle"
                        width={132}
                        height={132}
                      />
                    </div>
                    <form>
                      <div className="form-group">
                        <label>Chọn chương trình</label>
                        <select
                          className="form-control mb-3"
                          value={config?.key}
                          onChange={(e) => {
                            let tmp = ConfigConstant.getValue(e.target.value);
                            setConfig(tmp);
                          }}
                        >
                          <option value={ConfigConstant.RTL_EP01_FORM_TYPE.key}>
                            {ConfigConstant.RTL_EP01_FORM_TYPE.name}
                          </option>

                          <option
                            value={ConfigConstant.INVOICE_PROMOTION_2023.key}
                          >
                            {ConfigConstant.INVOICE_PROMOTION_2023.name}
                          </option>

                           <option
                            value={ConfigConstant.INVOICE_PROMOTION_2023_H2.key}
                          >
                            {ConfigConstant.INVOICE_PROMOTION_2023_H2.name}
                          </option>

                          <option
                            value={ConfigConstant.CTR_SCAN_QR_FORM_TYPE.key}
                          >
                            {ConfigConstant.CTR_SCAN_QR_FORM_TYPE.name}
                          </option>
                          <option value={ConfigConstant.PREDICT_FOOTBALL.key}>
                            {ConfigConstant.PREDICT_FOOTBALL.name}
                          </option>
                          <option value={ConfigConstant.FEEDBACK.key}>
                            {ConfigConstant.FEEDBACK.name}
                          </option>

                          <option value={ConfigConstant.INVOICE_PROMOTION_2023_MINI_8_PLATE.key}>
                            {ConfigConstant.INVOICE_PROMOTION_2023_MINI_8_PLATE.name}
                          </option>

                          <option value={ConfigConstant.EVENT.key}>
                            {ConfigConstant.EVENT.name}
                          </option>
                        </select>
                      </div>
                      <div className="text-center mt-3">
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            StoreUtils.setConfig(config);
                            navigate(`/${config?.key}`);
                          }}
                          className="btn btn-lg btn-primary"
                        >
                          Tiếp theo
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default SelectFormTypePage;
