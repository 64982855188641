import APIUtil from "../utils/APIUtils";
import { Region } from "../utils/ProvinceUtil";

export default class UserModel {
  static get(id) {
    return new Promise((resolve, reject) => {
      APIUtil.getJSONWithCredentials(
        process.env.REACT_APP_DOMAIN + `/api/user/get?id=${id}`,
        resolve,
        reject
      );
    });
  }

  static count() {
    let url = process.env.REACT_APP_DOMAIN + `/api/user/count`;
    return new Promise((resolve, reject) => {
      APIUtil.getJSONWithCredentials(
        url,
        resolve,
        reject
      );
    });
  }

  static statsUserCity() {
    return new Promise((resolve, reject) => {
      APIUtil.getJSONWithCredentials(
        process.env.REACT_APP_DOMAIN + `/api/user/stats-user-city`,
        resolve,
        reject
      );
    });
  }

  static statsUserDate() {
    return new Promise((resolve, reject) => {
      APIUtil.getJSONWithCredentials(
        process.env.REACT_APP_DOMAIN + `/api/user/stats-user-date`,
        resolve,
        reject
      );
    });
  }

  static find(search, city, status, roleId, region, page, pageSize) {
    var param = Object.fromEntries(
      Object.entries({
        search,
        status,
        roleId,
        page,
        pageSize,
      }).filter(([_, v]) => v != null && v !== 0)
    );
    if (region != null && region !== 0) {
      param['cityIds'] = Region.get(region).provinces;
    }
    if (city != null && city !== 0) {
      param['cityIds'] = [city]
    }

    return new Promise((resolve, reject) => {
      APIUtil.getJSONWithCredentials(
        process.env.REACT_APP_DOMAIN +
        `/api/user/find?${new URLSearchParams(param).toString()}`,
        resolve,
        reject
      );
    });
  }

  static exportExcel(city, status, roleId,) {
    var param = Object.fromEntries(
      Object.entries({
        status,
        city,
        roleId,
      }).filter(([_, v]) => v != null && v !== 0)
    );

    return new Promise((resolve, reject) => {
      APIUtil.getJSONWithCredentials(
        process.env.REACT_APP_DOMAIN +
        `/api/user/export-excel?${new URLSearchParams(param).toString()}`,
        resolve,
        reject
      );
    });
  }

  static updateStatus(id, status, note) {
    var form = {
      status,
      note,
    };
    return new Promise((resolve, reject) => {
      APIUtil.postJSONWithCredentials(
        process.env.REACT_APP_DOMAIN + `/api/user/update-status?id=${id}`,
        JSON.stringify(form),
        resolve,
        reject
      );
    });
  }

  static delete(id) {
    return new Promise((resolve, reject) => {
      APIUtil.getJSONWithCredentials(
        process.env.REACT_APP_DOMAIN + `/api/user/delete?id=${id}`,
        resolve,
        reject
      );
    });
  }

  static update(user) {
    let form = {
      nameStore: user.nameStore,
      address: user.address,
      imageStore: user.imageStore,
      name: user.name,
      imageCMND: user.imageCMND,
      fullName: user.fullName,
      personalCode: user.personalCode,
      roleId: user.roleId,
    };

    return new Promise((resolve, reject) => {
      APIUtil.postJSONWithCredentials(
        process.env.REACT_APP_DOMAIN + `/api/user/update?id=${user.id}`,
        JSON.stringify(form),
        resolve,
        reject
      );
    });
  }


  static showOnForm(form) {
    return new Promise((resolve, reject) => {
      APIUtil.postJSONWithCredentials(
        process.env.REACT_APP_DOMAIN + `/api/user/show-on-form`,
        JSON.stringify(form),
        resolve,
        reject
      );
    });
  }


}
