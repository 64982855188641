export default class ConfigConstant {

    static getValue(key) {
        return ConfigConstant[key]
    }

    static RTL_EP01_FORM_TYPE = {
        key: 'RTL_EP01_FORM_TYPE',
        name: 'Chương trình khuyến mãi mua hàng 2022',
        promotionId: 2415,
        menu: [
            {
                id: 1,
                name: 'Dashboards',
                path: `/RTL_EP01_FORM_TYPE`
            },
            {
                id: 2,
                name: 'Thành viên',
                path: `/RTL_EP01_FORM_TYPE/user/list`
            },
            {
                id: 3,
                name: 'Đang chờ duyệt',
                path: `/RTL_EP01_FORM_TYPE/form/list/waiting`,
            },
            {
                id: 4,
                name: 'Lịch sử duyệt đơn',
                path: `/RTL_EP01_FORM_TYPE/form/list/history`,
            },
            {
                id: 5,
                name: 'Quà tặng',
                path: `/RTL_EP01_FORM_TYPE/gift-order/list`,
            },
            {
                id: 6,
                name: 'Báo cáo chương trình',
                path: `/RTL_EP01_FORM_TYPE/accumulation/list`,
            },
            {
                id: 7,
                name: 'Quản lý Kho',
                path: `/RTL_EP01_FORM_TYPE/warehouse/list`,
            }

        ]
    }

    static CTR_SCAN_QR_FORM_TYPE = {
        key: 'CTR_SCAN_QR_FORM_TYPE',
        name: 'Nhà thầu',
        promotionId: 2416,
        menu: [
            {
                id: 1,
                name: 'Dashboards',
                path: `/CTR_SCAN_QR_FORM_TYPE`
            },
            {
                id: 2,
                name: 'Thành viên',
                path: `/CTR_SCAN_QR_FORM_TYPE/user/list`
            },
            {
                id: 3,
                name: 'QR Code',
                path: `/CTR_SCAN_QR_FORM_TYPE/qr-code/list`,
            },
            {
                id: 4,
                name: 'Lịch sử Scan QR',
                path: `/CTR_SCAN_QR_FORM_TYPE/form/list/scanqr/history`,
            },
            {
                id: 5,
                name: 'Quà tặng',
                path: `/CTR_SCAN_QR_FORM_TYPE/gift-order/list`,
            },
            {
                id: 6,
                name: 'Báo cáo chương trình',
                path: `/CTR_SCAN_QR_FORM_TYPE/accumulation/list`,
            },
        ]
    }

    static PREDICT_FOOTBALL = {
        key: 'PREDICT_FOOTBALL',
        name: 'Dự đoán bóng đá',
        promotionId: 2417,
        menu: [
            {
                id: 1,
                name: 'Dashboards',
                path: `/PREDICT_FOOTBALL`
            },
            {
                id: 2,
                name: 'Thành viên',
                path: `/PREDICT_FOOTBALL/user/list`
            },
            {
                id: 3,
                name: 'Trận đấu',
                path: `/PREDICT_FOOTBALL/match/list`
            },
            {
                id: 4,
                name: 'Quà tặng',
                path: `/PREDICT_FOOTBALL/gift-order/list`
            },
            {
                id: 5,
                name: 'Báo cáo chương trình',
                path: `/PREDICT_FOOTBALL/accumulation/list`
            },
        ]
    }

    static INVOICE_PROMOTION_2023 = {
        key: 'INVOICE_PROMOTION_2023',
        name: 'Chương trình khuyến mãi mua hàng 2023',
        promotionId: 2418,
        menu: [
            {
                id: 1,
                name: 'Dashboards',
                path: `/INVOICE_PROMOTION_2023`
            },
            {
                id: 2,
                name: 'Thành viên',
                path: `/INVOICE_PROMOTION_2023/user/list`,
                hasSubMenu: true,
                subMenu: [
                    {
                        id: 1,
                        name: 'Cửa hàng',
                        path: `/INVOICE_PROMOTION_2023/user/list/retailer`,

                    }, {
                        id: 2,
                        name: 'Nhà thầu',
                        path: `/INVOICE_PROMOTION_2023/user/list/constructor`,

                    }, {
                        id: 3,
                        name: 'Nhà phân phối',
                        path: `/INVOICE_PROMOTION_2023/user/list/distributor`,

                    }, {
                        id: 4,
                        name: 'Nhà phân phối T2',
                        path: `/INVOICE_PROMOTION_2023/user/list/distributor-t2`,

                    }]
            },
            {
                id: 3,
                name: 'Đang chờ duyệt',
                path: `/INVOICE_PROMOTION_2023/form/list/waiting`,
                hasSubMenu: true,
                subMenu: [
                    {
                        id: 1,
                        name: 'Cửa hàng, nhà phân phối',
                        path: `/INVOICE_PROMOTION_2023/form/list/waiting/retailer-distributor`,

                    }, {
                        id: 2,
                        name: 'Nhà thầu',
                        path: `/INVOICE_PROMOTION_2023/form/list/waiting/constructor`,

                    }]
            },
            {
                id: 4,
                name: 'Lịch sử duyệt đơn',
                path: `/INVOICE_PROMOTION_2023/form/list/history`,
                hasSubMenu: true,
                subMenu: [
                    {
                        id: 1,
                        name: 'Cửa hàng, nhà phân phối',
                        path: `/INVOICE_PROMOTION_2023/form/list/history/retailer-distributor`,

                    }, {
                        id: 2,
                        name: 'Nhà thầu',
                        path: `/INVOICE_PROMOTION_2023/form/list/history/constructor`,

                    }]
            },
            {
                id: 5,
                name: 'Quà tặng',
                path: `/INVOICE_PROMOTION_2023/gift-order/list`,
            },
            {
                id: 6,
                name: 'Báo cáo chương trình',
                path: `/INVOICE_PROMOTION_2023/accumulation/list`,
            },
            {
                id: 7,
                name: 'Quản lý Kho',
                path: `/INVOICE_PROMOTION_2023/warehouse/list`,
            },
            {
                id: 8,
                name: 'Gotit Voucher',
                path: `/RTL_EP01_FORM_TYPE/gotit-voucher/list`,
            },
            {
                id: 9,
                name: 'Duyệt CMND/CCCD',
                path: `/INVOICE_PROMOTION_2023/draft-profile/list`
            }
        ]
    }

    static INVOICE_PROMOTION_2023_MINI_8_PLATE = {
        key: 'INVOICE_PROMOTION_2023_MINI_8_PLATE',
        name: 'CTKM Cho tấm mini 8mm 2023',
        promotionId: 2419,
        menu: [
            {
                id: 1,
                name: 'Dashboards',
                path: `/INVOICE_PROMOTION_2023_MINI_8_PLATE`
            },
            {
                id: 2,
                name: 'Thành viên',
                path: `/INVOICE_PROMOTION_2023_MINI_8_PLATE/user/list`,
                hasSubMenu: true,
                subMenu: [
                    {
                        id: 1,
                        name: 'Cửa hàng',
                        path: `/INVOICE_PROMOTION_2023_MINI_8_PLATE/user/list/retailer`,

                    }, {
                        id: 2,
                        name: 'Nhà thầu',
                        path: `/INVOICE_PROMOTION_2023_MINI_8_PLATE/user/list/constructor`,

                    }, {
                        id: 3,
                        name: 'Nhà phân phối',
                        path: `/INVOICE_PROMOTION_2023_MINI_8_PLATE/user/list/distributor`,

                    }, {
                        id: 4,
                        name: 'Nhà phân phối T2',
                        path: `/INVOICE_PROMOTION_2023_MINI_8_PLATE/user/list/distributor-t2`,

                    }]
            },
            {
                id: 3,
                name: 'Đang chờ duyệt',
                path: `/INVOICE_PROMOTION_2023_MINI_8_PLATE/form/list/waiting`,
                hasSubMenu: true,
                subMenu: [
                    {
                        id: 1,
                        name: 'Cửa hàng, nhà phân phối',
                        path: `/INVOICE_PROMOTION_2023_MINI_8_PLATE/form/list/waiting/retailer-distributor`,

                    }]
            },
            {
                id: 4,
                name: 'Lịch sử duyệt đơn',
                path: `/INVOICE_PROMOTION_2023_MINI_8_PLATE/form/list/history`,
                hasSubMenu: true,
                subMenu: [
                    {
                        id: 1,
                        name: 'Cửa hàng',
                        path: `/INVOICE_PROMOTION_2023_MINI_8_PLATE/form/list/history/retailer-distributor`,

                    }]
            },
            {
                id: 6,
                name: 'Báo cáo chương trình',
                path: `/INVOICE_PROMOTION_2023_MINI_8_PLATE/accumulation/list`,
            },
            {
                id: 7,
                name: 'Quản lý Kho',
                path: `/INVOICE_PROMOTION_2023_MINI_8_PLATE/warehouse/list`,
            },
        ]
    }

    static FEEDBACK = {
        key: 'FEEDBACK',
        name: 'Khiếu nại',
        menu: [
            {
                id: 1,
                name: 'Dashboard',
                path: '/FEEDBACK',
                hasSubMenu: false,
            },
            {
                id: 2,
                name: 'Chủ đề',
                path: '/FEEDBACK/topic',
                hasSubMenu: false,
            },
            {
                id: 3,
                name: 'Phòng ban',
                path: '/FEEDBACK/department/list',
                hasSubMenu: false,
            },
            {
                id: 4,
                name: 'Nhân viên',
                path: '/FEEDBACK/employee/list',
                hasSubMenu: false,
            },
            {
                id: 5,
                name: 'Thành viên',
                path: `/FEEDBACK/user/list`,
                hasSubMenu: true,
                subMenu: [
                    {
                        id: 1,
                        name: 'Cửa hàng',
                        path: `/FEEDBACK/user/list/retailer`,

                    }, {
                        id: 2,
                        name: 'Nhà thầu',
                        path: `/FEEDBACK/user/list/constructor`,

                    }, {
                        id: 3,
                        name: 'Nhà phân phối',
                        path: `/FEEDBACK/user/list/distributor`,

                    }, {
                        id: 4,
                        name: 'Nhà phân phối T2',
                        path: `/FEEDBACK/user/list/distributor-t2`,

                    }]
            },
            {
                id: 6,
                name: 'Khiếu nại',
                path: '/FEEDBACK/message/list',
                hasSubMenu: false
            }
        ]
    }

    static INVOICE_PROMOTION_2023_H2 = {
        key: 'INVOICE_PROMOTION_2023_H2',
        name: 'Chương trình khuyến mãi mua hàng 2023 H2',
        promotionId: 2420,
        menu: [
            {
                id: 1,
                name: 'Dashboards',
                path: `/INVOICE_PROMOTION_2023_H2`
            },
            {
                id: 2,
                name: 'Thành viên',
                path: `/INVOICE_PROMOTION_2023_H2/user/list`,
                hasSubMenu: true,
                subMenu: [
                    {
                        id: 1,
                        name: 'Cửa hàng',
                        path: `/INVOICE_PROMOTION_2023_H2/user/list/retailer`,

                    }, {
                        id: 2,
                        name: 'Nhà thầu',
                        path: `/INVOICE_PROMOTION_2023_H2/user/list/constructor`,

                    }, {
                        id: 3,
                        name: 'Nhà phân phối',
                        path: `/INVOICE_PROMOTION_2023_H2/user/list/distributor`,

                    }, {
                        id: 4,
                        name: 'Nhà phân phối T2',
                        path: `/INVOICE_PROMOTION_2023_H2/user/list/distributor-t2`,

                    }]
            },
            {
                id: 3,
                name: 'Đang chờ duyệt',
                path: `/INVOICE_PROMOTION_2023_H2/form/list/waiting`,
                hasSubMenu: true,
                subMenu: [
                    {
                        id: 1,
                        name: 'Nhà phân Phối',
                        path: `/INVOICE_PROMOTION_2023_H2/form/list/waiting/npp`,

                    },
                    {
                        id: 2,
                        name: 'Nhà phân Phối T2',
                        path: `/INVOICE_PROMOTION_2023_H2/form/list/waiting/npp-t2`,

                    }, {
                        id: 3,
                        name: 'Knauf',
                        path: `/INVOICE_PROMOTION_2023_H2/form/list/waiting/knauf`,

                    }]
            },
            {
                id: 4,
                name: 'Lịch sử duyệt đơn',
                path: `/INVOICE_PROMOTION_2023_H2/form/list/history`,
                hasSubMenu: true,
                subMenu: [
                    {
                        id: 1,
                        name: 'Nhà phân Phối T2',
                        path: `/INVOICE_PROMOTION_2023_H2/form/list/history/npp-t2`,

                    }, {
                        id: 2,
                        name: 'Cửa hàng',
                        path: `/INVOICE_PROMOTION_2023_H2/form/list/history/retailer`,

                    }]
            },
            {
                id: 5,
                name: 'Quà tặng',
                path: `/INVOICE_PROMOTION_2023_H2/gift-order/list`,
            },
            {
                id: 6,
                name: 'Báo cáo chương trình',
                path: `/INVOICE_PROMOTION_2023_H2/point/list`,
            },
            {
                id: 7,
                name: 'Quản lý Kho',
                path: `/INVOICE_PROMOTION_2023_H2/warehouse/list`,
            },
            {
                id: 8,
                name: 'Duyệt CMND/CCCD',
                path: `/INVOICE_PROMOTION_2023_H2/draft-profile/list`
            }
        ]
    }

    static EVENT = {
        key: 'EVENT',
        name: 'Event',
        menu: [
            {
                id: 2,
                name: 'Chương trình',
                path: '/EVENT/promotion/list'
            },
            {
                id: 3,
                name: 'Danh sách tham gia',
                path: '/EVENT/registration/list',
                hasSubMenu: false,
            },
            {
                id: 4,
                name: 'Danh sách quà',
                path: '/EVENT/gift/list',
                hasSubMenu: false
            }
            // {
            //     id: 3,
            //     name: 'Phòng ban',
            //     path: '/FEEDBACK/department/list',
            //     hasSubMenu: false,
            // },
            // {
            //     id: 4,
            //     name: 'Nhân viên',
            //     path: '/FEEDBACK/employee/list',
            //     hasSubMenu: false,
            // },
            // {
            //     id: 5,
            //     name: 'Thành viên',
            //     path: `/FEEDBACK/user/list`,
            //     hasSubMenu: true,
            //     subMenu: [
            //         {
            //             id: 1,
            //             name: 'Cửa hàng',
            //             path: `/FEEDBACK/user/list/retailer`,

            //         }, {
            //             id: 2,
            //             name: 'Nhà thầu',
            //             path: `/FEEDBACK/user/list/constructor`,

            //         }, {
            //             id: 3,
            //             name: 'Nhà phân phối',
            //             path: `/FEEDBACK/user/list/distributor`,

            //         }, {
            //             id: 4,
            //             name: 'Nhà phân phối T2',
            //             path: `/FEEDBACK/user/list/distributor-t2`,

            //         }]
            // },
            // {
            //     id: 6,
            //     name: 'Khiếu nại',
            //     path: '/FEEDBACK/message/list',
            //     hasSubMenu: false
            // }
        ]
    }

}