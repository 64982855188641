export const INIT = 1;
export const APPROVED = 2;
export const REJECTED = 3;
export const SEND = 4;
export const CANCELED = 5;

export function findName(value) {
    switch (value) {
        case INIT: return 'Chờ duyệt'
        case APPROVED: return 'Chờ gửi'
        case REJECTED: return 'Đã từ chối'
        case SEND: return 'Đã gửi'
        case CANCELED: return "Đã hủy"
    }
}

export function findColor(value) {
    switch (value) {
        case INIT: return '#ff5722'
        case APPROVED: return '#20c997'
        case REJECTED: return '#9e9e9e'
        case SEND: return '#20c997'
        case CANCELED: return "#9e9e9e"
    }
}