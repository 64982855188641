import APIUtil from '../utils/APIUtils'

export default class GotitVoucherModel {

    static upload(data) {
        const formData = new FormData();
        formData.append("file", data);
        return new Promise((resolve, reject) => {
            APIUtil.uploadFile(process.env.REACT_APP_DOMAIN + `/api/gotit-voucher/upload`, formData, resolve, reject);
        });
    }

    static find({ code, status, page, pageSize }) {
        var param = Object.fromEntries(Object.entries({
            code, status, page, pageSize
        }).filter(([_, v]) => (v != null && v != '')));
        return new Promise((resolve, reject) => {
            APIUtil.getJSONWithCredentials(process.env.REACT_APP_DOMAIN + `/api/gotit-voucher/find?${new URLSearchParams(param).toString()}`, resolve, reject);
        });
    }

}