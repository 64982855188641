import APIUtil from "../utils/APIUtils";

export default class AccumulationModel {

  static get(id) {
    return new Promise((resolve, reject) => {
      APIUtil.getJSONWithCredentials(
        process.env.REACT_APP_DOMAIN +
        `/api/accumulation/get?id=${id}`,
        resolve,
        reject
      );
    });
  }

  static findByUid(uid, promotionId) {
    return new Promise((resolve, reject) => {
      APIUtil.getJSONWithCredentials(
        process.env.REACT_APP_DOMAIN +
        `/api/accumulation/findByUid?uid=${uid}&promotionId=${promotionId}`,
        resolve,
        reject
      );
    });
  }

  static analytics(id) {
    return new Promise((resolve, reject) => {
      APIUtil.getJSONWithCredentials(
        process.env.REACT_APP_DOMAIN +
        `/api/accumulation/analytics?promotionId=${id}`,
        resolve,
        reject
      );
    });
  }


  static list(promotionId, type, page, pageSize) {
    return new Promise((resolve, reject) => {
      APIUtil.getJSONWithCredentials(
        process.env.REACT_APP_DOMAIN +
        `/api/accumulation/list?promotionId=${promotionId}&type=${type}&page=${page}&pageSize=${pageSize}`,
        resolve,
        reject
      );
    });
  }

  static find(promotionId, search, roleId, page, pageSize) {
    var param = Object.fromEntries(
      Object.entries({
        search,
        page,
        pageSize,
      }).filter(([_, v]) => v != null)

    );

    if (roleId !== 0 && roleId != null) {
      param['roleIds'] = [roleId]
    }
    return new Promise((resolve, reject) => {
      APIUtil.getJSONWithCredentials(
        process.env.REACT_APP_DOMAIN +
        `/api/accumulation/find?${new URLSearchParams(
          param
        ).toString()}&promotionId=${promotionId}`,
        resolve,
        reject
      );
    });
  }
}
