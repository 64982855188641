
import { useMemo } from 'react'
import * as UserType from '../../constant/type/UserType'
import { Tag } from 'antd'

const useCleanNameUser = (name) => {
    return useMemo(() => {
        let _name = name.trim();
        _name = name.replace(/^CH\s/, "");
        _name = _name.replace(/^NPP\s/, "");
        return _name;
    }, [name])
}

const useNameUser = (user) => {
    return useMemo(() => {
        if (user) {
            switch (user.roleId) {
                case UserType.CONSTRUCTOR: {
                    return user.fullName || user.name
                }
                default: {
                    return user.nameStore;
                }
            }
        }
        return ''
    }, [user])
}

const useRoleNameUser = (user) => {
    return useMemo(() => {
        if (user) {
            switch (user.roleId) {
                case UserType.CONSTRUCTOR: return 'NT'
                case UserType.RETAILER: return 'CH'
                case UserType.DISTRIBUTOR: return "NPP"
                case UserType.DISTRIBUTOR_T2: return "NPP T2"
            }
        }
        return '';
    }, [user])
}

const NameWithRoleUser = ({ user }) => {
    const name = useNameUser(user);
    const roleName = useRoleNameUser(user);
    const cleanName = useCleanNameUser(name).toUpperCase();

    return <>
        <span className='tag-role-user' style={{ backgroundColor: `${UserType.findColor(user.roleId)}` }}>{roleName}</span>
        <span>{cleanName}</span>
    </>
}

export { useRoleNameUser, useNameUser, NameWithRoleUser }