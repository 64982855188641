import APIUtil from "../utils/APIUtils";

export default class MessageFeedbackModel {

    static find({ topicId, subTopicId, status, page, pageSize }) {
        var param = Object.fromEntries(
            Object.entries({
                topicId,
                subTopicId,
                status,
                page,
                pageSize
            }).filter(([_, v]) => v != null && v != 0)
        );

        return new Promise((resolve, reject) => {
            APIUtil.getJSONWithCredentials(
                process.env.REACT_APP_DOMAIN +
                `/api/feedback/message/find?${new URLSearchParams(param).toString()}`,
                resolve,
                reject
            );
        });
    }

    static get({ id }) {
        return new Promise((resolve, reject) => {
            APIUtil.getJSONWithCredentials(
                process.env.REACT_APP_DOMAIN +
                `/api/feedback/message/get?id=${id}`,
                resolve,
                reject
            );
        });
    }

    static updateStatus({ id, status, note }) {
        var form = {
            status,
            note,
        };
        return new Promise((resolve, reject) => {
            APIUtil.postJSONWithCredentials(
                process.env.REACT_APP_DOMAIN + `/api/feedback/message/update-status?id=${id}`,
                JSON.stringify(form),
                resolve,
                reject
            );
        });
    }

    static update(id, form) {
        return new Promise((resolve, reject) => {
            APIUtil.postJSONWithCredentials(
                process.env.REACT_APP_DOMAIN + `/api/feedback/message/update?id=${id}`,
                JSON.stringify(form),
                resolve,
                reject
            );
        });
    }

    static stats({ topicId, subTopicId }) {
        var param = Object.fromEntries(
            Object.entries({
                topicId,
                subTopicId,
            }).filter(([_, v]) => v != null && v != 0)
        );

        return new Promise((resolve, reject) => {
            APIUtil.getJSONWithCredentials(
                process.env.REACT_APP_DOMAIN +
                `/api/feedback/message/stats?${new URLSearchParams(param).toString()}`,
                resolve,
                reject
            );
        });
    }

    static statsByStatusDate() {
        return new Promise((resolve, reject) => {
            APIUtil.getJSONWithCredentials(
                process.env.REACT_APP_DOMAIN +
                `/api/feedback/message/stats-status-date`,
                resolve,
                reject
            );
        });
    }

    static statsByTopicDate() {
        return new Promise((resolve, reject) => {
            APIUtil.getJSONWithCredentials(
                process.env.REACT_APP_DOMAIN +
                `/api/feedback/message/stats-topic-date`,
                resolve,
                reject
            );
        });
    }

    static statsByRatingMonth() {
        return new Promise((resolve, reject) => {
            APIUtil.getJSONWithCredentials(
                process.env.REACT_APP_DOMAIN +
                `/api/feedback/message/stats-rating-topic-month`,
                resolve,
                reject
            );
        });
    }

    static statsByRating() {
        return new Promise((resolve, reject) => {
            APIUtil.getJSONWithCredentials(
                process.env.REACT_APP_DOMAIN +
                `/api/feedback/message/stats-rating-topic`,
                resolve,
                reject
            );
        });
    }

}