export const INIT = 1;
export const RECEVIED = 2;
export const CLOSE = 3;

export function findName(value) {
    switch (value) {
        case INIT: return 'Đang xử lý'
        case RECEVIED: return 'Đã phản hồi'
        case CLOSE: return 'Đã đóng'
    }
}

export function findColor(value) {
    switch (value) {
        case INIT: return '#dc3535'
        case RECEVIED: return '#28a745'
        case CLOSE: return '#9e9e9e'
    }
}