export interface User {
  id: number;
  phone: string;
  status: number;
  roleId: number;
  name: string;
  avatar: string;
  cityId: number;
  districtId: number;
  address: string;
  createdTime: number;
  updatedTime: number;
  nameStore: string;
  imageStore: string;
  imageCMND: string;
  fullName: string;
  personalCode: string;
  officialName: string;
  showOnForm: boolean;
  followerId: string;
}

export interface Page<T> {
  page: number;
  pageSize: number;
  totalPage: number;
  list: Array<T>;
}

export interface Form {
  id: number;
  promotionId: number;
  userId: number;
  status: number;
  type: number;
  user: User;
  distributor: User;
  time: number;
  note: string;
  createdTime: number;
}

export interface RtlEp01Form extends Form {
  frame: number;
  plate: number;
  compound: number;
  mini8Plate: number;
  distributorName: string;
  jsonImage: Array<ImgForm>;
  billCreatedTime: number;
  point: number;
  secondPoint: number;
  distributorId: number;
  invoiceImage: string;
  standard9Plate: number;
  moistureResistant9Plate: number;
  fireProofPlate: number;
  threeInOnePlate: number;
  ultraMoistPlate: number;
}

export interface AllForm extends Form {
  frame: number;
  plate: number;
  compound: number;
  mini8Plate: number;
  distributorName: string;
  jsonImage: Array<ImgForm>;
  billCreatedTime: number;
  point: number;
  codes: Array<string>;
  match: any;
}

export interface ImgForm {
  url: string;
}

export interface StatsForm {
  total: number;
  approved: number;
}

export interface ImgRealtimePhoto {
  url: string;
  time: number;
  location: JSON;
}

export interface UserFilter {
  cityId: number;
  status: number;
  search: string;
  roleId: number;
  region: number;
  page: number;
  pageSize: number;
}

export interface FormFilter {
  cityId: number;
  status: Array<number>;
  search: string;
  promotionIds: Array<number>;
  page: number;
  pageSize: number;
  promotionId: number;
  // roleId: number;
  roleIds: Array<number>;
  searchByDistributor: boolean
}

export interface CountUserDashboard {
  numApprovedRetailer: number;
  numApprovedContractor: number;
  numApproved: number;
}

export interface UserDateMetric {
  date: string;
  retailer: number;
  contractor: number;
}

export interface UserCityMetric {
  city: number;
  total: number;
  retailer: number;
  contractor: number;
}

export interface FormGift {
  title: string;
  type: number;
  totalValue: number;
  cardPhones: Array<CardPhoneGift>;
  userId: number;
}

export interface CardPhoneGift {
  id: number;
  network: number;
  value: number;
  seri: string;
  code: string;
}

export interface Broadcast {
  id: number;
  name: string;
  timeStart: number;
  status: number;
  type: number;
  cityIds: Array<number>;
  districtIds: Array<number>;
  postId: number;
  seen: number;
  click: number;
  totalUids: number;
  totalUidsAfterBuildUser: number;
  totalUidsSuccessSend: number;
  userIds: Array<number>;
}

export interface FormPromotionMetric {
  promotionId: number;
  total: number;
}

export interface CountGiftDTO {
  total: number;
  send: number;
  received: number;
}

export interface PromotionMetric {
  promotion: number;
  engagement: number;
}

export interface CountFormDTO {
  total: number;
  init: number;
  approved: number;
  sendGift: number;
  receivedGift: number;
}

export interface GiftOrder {
  id: number;
  status: number;
  title: string;
  type: number;
  amount: number;
  point: number;
  secondPoint: number;
  mainPoint: number;
  user: User;
  gift: Gift;
  createdTime: number;
  updatedTime: number;
  money: number;
}

export interface Gift {
  id: number;
  name: string;
  link: string;
  point: number;
  gotItCode: string;
  cap: number;
  type: number
}

export interface EgagementsFilter {
  type: number;
}

export interface MatchFootball {
  id: number;
  teamOne: string;
  teamTwo: string;
  logoTeamOne: string;
  logoTeamTwo: string;
  timeStart: number;
  status: number;
  teamOneScore: number;
  teamTwoScore: number;
  season: string;
  totalPredict: number;
  totalWin: number;
  totalFailed: number;
}

export interface PredictMatchForm {
  user: User;
  match: MatchFootball;
  matchId: number;
  season: string;
  teamOneScore: string;
  teamTwoScore: string;
  teamWin: number;
  status: number;
}

export interface Accumulation {
  promotionId: number;
  user: User;
  point: number;
  secondPoint: number;
  historyPoint: number;
  historyFrame: number;
  historyPlate: number;
  historyMini8Plate: number;
  historySecondPoint: number;
  historyCompound: number;
  historyStandard9Plate: number;
  historyMoistureResistant9Plate: number;
  historyFireProofPlate: number;
  historyThreeInOnePlate: number;
  historyUltraMoistPlate: number;
  type: number;
}

export interface GotitVoucher {
  id: number;
  name: string;
  sms: string;
  email: string;
  code: string;
  price: number;
  issueDate: number;
  expiredDate: number;
  status: number;
  giftOrderId: number;
}

export interface DraftProfile {
  id: number;
  uid: number;
  phone: string;
  name: string;
  cityId: number;
  districtId: number;
  address: string;
  suitSize: string;
  personalCode: string;
  imageCMND: string;
  status: number;
  user: User;
}

export interface WarehouseEdge {
  userIdFrom: number;
  userIdTo: number;
  plate: number;
  frame: number;
  compound: number;
  userFrom: User;
  userTo: User;
}

export interface TopicFitler {
  topicId?: number,
  subTopicId?: number,
}

export interface DepartmentFilter {
  managerId?: number;
  page?: number;
  pageSize?: number;
}

export interface EmployeeFilter {
  text?: string
  page?: number;
  pageSize?: number;
}

export interface Holder {
  name: string;
  phone: string;
  followerId: string;
  avatar: string;
}

export interface TopicStatistical {
  total: number;
  received: number;
  close: number;
  init: number;
}


export interface TopicFeedback {
  id: number,
  title: string,
  topicIds: Array<number>,
  holders: Array<Holder>,
  statistical: TopicStatistical,
  type: number,
  subs?: Array<TopicFeedback>
}

export enum TypeTopic {
  PARENT = 1,
  CHILD = 2
}

export interface MessageFeedback {
  id: number,
  topic: TopicFeedback,
  subTopics: Array<TopicFeedback>,
  holder: Holder,
  content: string,
  rating: number,
  status: number,
  reply: string,
  user: User,
  createdTime: number,
  updatedTime: number,
  images: Array<string>,
  department: Department,
  departmentId?: number;
  leadTime: number;
  employeeId?: number;
  topicId?: number;
  subTopicIds?: Array<number>;
  note: string;
  timeResponse: number;
}

export interface MessageFeedbackFilter {
  topicId?: number,
  subTopicId?: number,
  page?: number,
  pageSize?: number,
  status?: number
}

export interface Employee {
  id: number;
  name: string;
  departmentId: number;
  avatar: string;
  phone: string;
  department: Department;
  status: number;
}


export interface Department {
  id: number;
  name: string;
  employees: Array<Employee>;
  manager: Employee;
  employeeIds: Array<number>;
}

export interface Point {
  id: number;
  user: User;
  value: number;
  history: number;
}


export interface RegistrationEvent {
  id: number,
  user: User,
  promotionId: number;
  checkIn: boolean;
  timeCheckIn: number;
  code: number;
  status: number;
  giftName: string;
  createdTime: number;
}


export interface Promotion {
  id: number;
  title: string;
  type: number;
  status: number;
  timeStart: number;
  timeEnd: number;
  content: any;
}

export interface RegistrationEventFilter {
  promotionId?: number,
  page?: number,
  pageSize?: number,
}


export interface GiftFilter {
  promotionId?: number,
  type?: number
}