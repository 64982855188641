import "react-responsive-modal/styles.css";
import "../../../component/popup/styles.scss";
import { useEffect, useState } from "react";
import { Accumulation, Page, AllForm } from "../../../interface";
import Layout from "../../../component/Layout";
import { useParams } from "react-router-dom";
import AccumulationModel from "../../../model/AccumulationModel";
import FormModel from "../../../model/FormModel";
import DateTimeUtil from "../../../utils/DateTimeUtil";
import * as StockFormStatus from "../../../constant/status/FormStatus";
import * as UserType from "../../../constant/type/UserType";

const default_avatar = "http://cdn.onlinewebfonts.com/svg/img_264570.png";
function DetailAccumulationPromotion2023Page() {
  let { id } = useParams();
  const [accumulation, setAccumulation] = useState<Accumulation>();
  const [userId, setUserId] = useState<number>();
  const [formPage, setFormPage] = useState<Page<AllForm>>();

  const fetchAccumulation = () => {
    AccumulationModel.get(id).then((resp) => {
      if (resp.error === 0) {
        setAccumulation(resp.data);
        setUserId(resp.data.user.id);
      }
    });
  };

  const fetchFindFormByUser = (promotionId) => {
    FormModel.findByUser(
      userId,
      promotionId,
      0,
      500
    ).then((resp) => {
      if (resp.error === 0) {
        setFormPage(resp.data);
      }
    });
  };

  useEffect(() => {
    if (userId && accumulation) {
      fetchFindFormByUser(accumulation.promotionId);
    }
  }, [userId]);

  useEffect(() => {
    fetchAccumulation();
  }, []);

  const user = accumulation && accumulation.user;

  return (
    <Layout>
      <main className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-xl-12">
              <div className="tab-content">
                <div
                  className="tab-pane fade show active"
                  id="account"
                  role="tabpanel"
                >
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title mb-0">Chi tiết</h5>
                    </div>
                    <div className="card-body">
                      {accumulation && (
                        <form>
                          <div className="row">
                            <div className="col-md-8">
                              <div className="form-group">
                                <label>Tài khoản</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={UserType.findName(user?.roleId)}
                                />
                              </div>
                              {user?.roleId == UserType.RETAILER && (
                                <>
                                  <div className="form-group">
                                    <label>Cửa hàng</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={accumulation.user.nameStore}
                                    />
                                  </div>
                                </>
                              )}
                              {user?.roleId == UserType.CONSTRUCTOR && (
                                <>
                                  <div className="form-group">
                                    <label>Họ và tên</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={accumulation.user.fullName}
                                    />
                                  </div>
                                </>
                              )}
                              <div className="form-group">
                                <label>Số điện thoại</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={accumulation.user.phone}
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="text-center">
                                {user?.roleId == UserType.CONSTRUCTOR ? (
                                  <img
                                    alt=""
                                    src={
                                      accumulation.user.avatar
                                        ? accumulation.user.avatar
                                        : default_avatar
                                    }
                                    className="rounded-circle img-responsive mt-2"
                                    width={128}
                                    height={128}
                                  />
                                ) : (
                                  <img
                                    alt=""
                                    src={
                                      accumulation.user.avatar
                                        ? accumulation.user.imageStore
                                        : default_avatar
                                    }
                                    className="img-responsive mt-2"
                                    width={128}
                                    height={128}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </form>
                      )}
                    </div>
                    <div style={{ marginBottom: "120px" }} className="card">
                      <table className="table">
                        <thead>
                          <tr className="border-none">
                            <th>Số điểm</th>
                            <th>Số tấm</th>
                            <th>Số khung</th>
                            <th>Số bột</th>
                            <th>Số tấm Mini 8mm</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <span
                                style={{
                                  color: "#e91e63",
                                  fontWeight: "600",
                                  fontSize: "2rem",
                                }}
                              >
                                {accumulation?.point}
                              </span>{" "}
                              / {accumulation?.historyPoint}
                            </td>
                            <td>{accumulation?.historyPlate}</td>
                            <td>{accumulation?.historyFrame}</td>
                            <td>{accumulation?.historyCompound}</td>
                            <td>{accumulation?.historyMini8Plate}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-xl-12">
                  <div className="header">
                    <h1 style={{ color: "#3e4676" }} className="header-title">
                      {" "}
                      Lịch sử tích{" "}
                    </h1>
                  </div>
                </div>
                <div style={{ marginBottom: "120px" }} className="card">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Thời gian</th>
                        <th>Số tấm</th>
                        <th>Số khung</th>
                        <th>Số bột</th>
                        <th>Số Tấm Mini 8mm</th>
                        <th>Số điểm</th>
                        <th>Trạng thái</th>
                      </tr>
                    </thead>
                    <tbody>
                      {formPage &&
                        formPage.list &&
                        formPage.list.map((form, key) => {
                          return (
                            <tr key={key}>
                              <td>{form.id}</td>
                              <td>
                                {DateTimeUtil.toString(form.createdTime * 1000)}
                              </td>
                              <td>{form.plate}</td>
                              <td>{form.frame}</td>
                              <td>{form.compound}</td>
                              <td>{form.mini8Plate}</td>
                              <td>
                                <span
                                  style={{
                                    color: "#e91e63",
                                    fontWeight: "600",
                                  }}
                                >
                                  {form.point}
                                </span>
                              </td>
                              <td>
                                <span
                                  style={{
                                    backgroundColor: StockFormStatus.findColor(
                                      form.status
                                    ),
                                  }}
                                  className="badge badge-info"
                                >
                                  {StockFormStatus.findName(form.status)}
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}

export default DetailAccumulationPromotion2023Page;
