import Layout from "../../../component/Layout";
import "../../../component/popup/styles.scss";
import { useEffect, useState } from "react";
import DateTimeUtil from "../../../utils/DateTimeUtil";
import { Button, Modal, DatePicker } from "antd";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Pagination } from "antd";
import LinkUtil from "../../../utils/LinkUtil";
import MatchModel from "../../../model/MatchModel";
import MatchFootballStatus from "../../../constant/status/MatchFootballStatus";
import ConfigConstant from "../../../constant/config";
import AlertUtils from "../../../utils/AlertUtils";
import moment from "moment";

const PAGE_SIZE = 10;
function ListMatch(props: any) {
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [match, setMatch] = useState<any>(null);
  const [filter, setFilter] = useState<any>(
    LinkUtil.initParamWithDefault(searchParams, {
      status: 0,
    })
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [start_time, set_start_time] = useState(moment());
  const [selectedMatch, setSelectedMatch] = useState<any>(null);

  const findMatch = () => {
    MatchModel.find(filter.page, PAGE_SIZE).then((resp) => {
      if (resp.error === 0) {
        setMatch(resp.data);
      }
    });
  };

  const approvedMatch = (matchId) => {
    MatchModel.approvedMatch(matchId).then((resp) => {
      if (resp.error === 0) {
        findMatch();
        AlertUtils.showSuccess("Duyệt thành công!");
      } else {
        AlertUtils.showError(resp.msg);
      }
    });
  };

  const serialize2ParamsUrl = () => {
    searchParams.set("q", JSON.stringify(filter));
    setSearchParams(searchParams);
  };

  const showModal = (item) => {
    setIsModalOpen(true);
    setSelectedMatch(item);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    MatchModel.createBroadcast(
      selectedMatch?.id,
      2417,
      start_time.toDate().getTime()
    ).then((res) => {
      if (res.error === 0) {
        AlertUtils.showSuccess("Tạo broadcast thành công");
      } else {
        AlertUtils.showError(res.msg);
      }
      findMatch();
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    findMatch();
    serialize2ParamsUrl();
  }, [filter]);

  return (
    <Layout>
      <main className="content">
        <div className="container-fluid">
          <div className="header">
            <h1 className="header-title">Danh sách trận đấu</h1>
          </div>
          <div className="row">
            <div className="col-12 col-xl-12">
              <div className="card">
                <div className="card-bar" style={{ padding: "10px" }}>
                  <div style={{ float: "right" }}>
                    <Button
                      type="primary"
                      onClick={() =>
                        navigate(
                          `/${ConfigConstant.PREDICT_FOOTBALL.key}/match/create`
                        )
                      }
                    >
                      Tạo trận đấu
                    </Button>
                  </div>
                </div>
                <table className="table">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Đội nhà</th>
                      <th>Đội khách</th>
                      <th>Thời gian</th>
                      <th>Trạng thái</th>
                      <th>Hành động</th>
                    </tr>
                  </thead>
                  <tbody>
                    {match &&
                      match?.list.map((item) => (
                        <tr key={item.id}>
                          <td>{item.id}</td>
                          <td>{item.cHost.name}</td>
                          <td>{item.cGuest.name}</td>
                          <td>{DateTimeUtil.toStringDetail(item.timeStart)}</td>
                          <td>
                            <span
                              style={{
                                backgroundColor: MatchFootballStatus.findColor(
                                  item.status
                                ),
                              }}
                              className="badge badge-info"
                            >
                              {MatchFootballStatus.findName(item.status)}
                            </span>
                          </td>
                          <td className="table-action">
                            <>
                              {item.status === MatchFootballStatus.INIT && (
                                <Button
                                  onClick={() => approvedMatch(item.id)}
                                  type="primary"
                                >
                                  Duyệt trận đấu
                                </Button>
                              )}
                              {item.status != MatchFootballStatus.INIT && (
                                <>
                                  <Button
                                    onClick={() => {
                                      navigate(
                                        `/${ConfigConstant.PREDICT_FOOTBALL.key}/form/list/${item.id}`
                                      );
                                    }}
                                    type="primary"
                                  >
                                    Danh sách dự đoán
                                  </Button>
                                  <br />
                                  {item.broadcastId == null && (
                                    <Button
                                      className="mt-2"
                                      onClick={() => showModal(item)}
                                      type="default"
                                    >
                                      Gửi broadcast
                                    </Button>
                                  )}
                                </>
                              )}
                            </>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <div className="cart-paging">
                  {match && (
                    <Pagination
                      defaultCurrent={1}
                      current={filter.page + 1}
                      onChange={(pageNumber) => {
                        setFilter({ ...filter, page: pageNumber - 1 });
                      }}
                      total={match.totalPage * PAGE_SIZE}
                    />
                  )}
                </div>
                <Modal
                  title="Thời gian gửi"
                  visible={isModalOpen}
                  onOk={handleOk}
                  onCancel={handleCancel}
                >
                  <DatePicker
                    format="YYYY-MM-DD HH:mm:ss"
                    value={start_time}
                    showTime={{
                      defaultValue: moment("00:00:00", "HH:mm:ss"),
                    }}
                    onChange={(data: any) => set_start_time(data)}
                  />
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}

export default ListMatch;
