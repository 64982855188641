export const INVOICE_PROMOTION = 20;
export const SCAN_QR_COLLECT_POINT_PROMOTION = 30;
export const PREDICT_FOOTBALL_PROMOTION = 51;
export const EVENT_PROMOTION = 61;

export function findName(value) {
    // eslint-disable-next-line default-case
    switch (value) {
        case INVOICE_PROMOTION: return 'Tích đơn'
        case SCAN_QR_COLLECT_POINT_PROMOTION: return 'Scan QR tích điểm'
        case PREDICT_FOOTBALL_PROMOTION: return 'Dự đoán bóng đá'
        case EVENT_PROMOTION : return 'Scan QR tham gia sự kiện'
    }
}

export function findColor(value) {
    // eslint-disable-next-line default-case
    switch (value) {
        case INVOICE_PROMOTION: return '#20c997'
        case SCAN_QR_COLLECT_POINT_PROMOTION: return '#dc3535'
        case PREDICT_FOOTBALL_PROMOTION: return '#dc3535'
        case EVENT_PROMOTION: return '#dc3535'
    }
}