import "../../../component/popup/styles.scss";
import { useEffect, useState } from "react";
import { Accumulation, Page, AllForm } from "../../../interface";
import Layout from "../../../component/Layout";
import { useParams } from "react-router-dom";
import AccumulationModel from "../../../model/AccumulationModel";
import FormModel from "../../../model/FormModel";
import DateTimeUtil from "../../../utils/DateTimeUtil";
import * as StockFormStatus from "../../../constant/status/FormStatus";
import {
  CTR_EP_SCAN_QR_FORM_COLLECT_POINT_TYPE_ONE,
  RTL_EP_FORM_COLLECT_POINT_TYPE_ONE,
} from "../../../constant/type/AccumulationType";

const default_avatar = "http://cdn.onlinewebfonts.com/svg/img_264570.png";
function DetailAccumulationPage() {
  let { id } = useParams();
  const [accumulation, setAccumulation] = useState<Accumulation>();
  const [userId, setUserId] = useState<number>();
  const [formPage, setFormPage] = useState<Page<AllForm>>();

  const fetchAccumulation = () => {
    AccumulationModel.get(id).then((resp) => {
      if (resp.error === 0) {
        setAccumulation(resp.data);
        setUserId(resp.data.user.id);
      }
    });
  };

  const fetchFindFormByUser = () => {
    FormModel.findByUser(userId, 0, 200).then((resp) => {
      if (resp.error === 0) {
        setFormPage(resp.data);
      }
    });
  };

  useEffect(() => {
    if (userId) {
      fetchFindFormByUser();
    }
  }, [userId]);

  useEffect(() => {
    fetchAccumulation();
  }, []);

  return (
    <Layout>
      <main className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-xl-12">
              <div className="tab-content">
                <div
                  className="tab-pane fade show active"
                  id="account"
                  role="tabpanel"
                >
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title mb-0">Chi tiết</h5>
                    </div>
                    <div className="card-body">
                      {accumulation && (
                        <form>
                          <div className="row">
                            <div className="col-md-8">
                              <div className="form-group">
                                <label>Tên</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={accumulation.user.name}
                                />
                              </div>
                              <div className="form-group">
                                <label>Số điện thoại</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={accumulation.user.phone}
                                />
                              </div>
                              {accumulation.type ==
                                RTL_EP_FORM_COLLECT_POINT_TYPE_ONE && (
                                <>
                                  <div className="form-group">
                                    <label>Số khung đã tích</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={accumulation.historyFrame}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label>Số tấm đã tích</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={accumulation.historyFrame}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label>Số điểm chính thức đã tích</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={accumulation.historyPoint}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label>Số điểm đổi bia đã tích</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={accumulation.historySecondPoint}
                                    />
                                  </div>

                                  <div className="form-group">
                                    <label>Số điểm chính thức hiện tại</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={accumulation.point}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label>Số điểm đổi bia hiện tại</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={accumulation.secondPoint}
                                    />
                                  </div>
                                </>
                              )}

                              {accumulation.type ==
                                CTR_EP_SCAN_QR_FORM_COLLECT_POINT_TYPE_ONE && (
                                <>
                                  <div className="form-group">
                                    <label>Số điểm đã scan</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={accumulation.historyPoint}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label>Số điểm hiện tại</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={accumulation.point}
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                            <div className="col-md-4">
                              <div className="text-center">
                                <img
                                  alt=""
                                  src={
                                    accumulation.user.avatar
                                      ? accumulation.user.avatar
                                      : default_avatar
                                  }
                                  className="rounded-circle img-responsive mt-2"
                                  width={128}
                                  height={128}
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                </div>

                {accumulation?.type == RTL_EP_FORM_COLLECT_POINT_TYPE_ONE && (
                  <>
                    <div className="col-12 col-xl-12">
                      <div className="header">
                        <h1
                          style={{ color: "#3e4676" }}
                          className="header-title"
                        >
                          {" "}
                          Lịch sử tích{" "}
                        </h1>
                      </div>
                    </div>
                    <div style={{ marginBottom: "120px" }} className="card">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Thời gian</th>
                            <th>Số tấm</th>
                            <th>Số khung</th>
                            <th>Trạng thái</th>
                          </tr>
                        </thead>
                        <tbody>
                          {formPage &&
                            formPage.list &&
                            formPage.list.map((form, key) => {
                              return (
                                <tr key={key}>
                                  <td>{form.id}</td>
                                  <td>
                                    {DateTimeUtil.toString(
                                      form.createdTime * 1000
                                    )}
                                  </td>
                                  <td>{form.plate}</td>
                                  <td>{form.frame}</td>
                                  <td>
                                    <span
                                      style={{
                                        backgroundColor:
                                          StockFormStatus.findColor(
                                            form.status
                                          ),
                                      }}
                                      className="badge badge-info"
                                    >
                                      {StockFormStatus.findName(form.status)}
                                    </span>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </>
                )}

                {accumulation?.type ==
                  CTR_EP_SCAN_QR_FORM_COLLECT_POINT_TYPE_ONE && (
                  <>
                    <div className="col-12 col-xl-12">
                      <div className="header">
                        <h1
                          style={{ color: "#3e4676" }}
                          className="header-title"
                        >
                          {" "}
                          Lịch sử quét{" "}
                        </h1>
                      </div>
                    </div>
                    <div style={{ marginBottom: "120px" }} className="card">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Thời gian</th>
                            <th>Code</th>
                            <th>Điểm</th>
                            <th>Trạng thái</th>
                          </tr>
                        </thead>
                        <tbody>
                          {formPage &&
                            formPage.list &&
                            formPage.list.map((form, key) => {
                              return (
                                <tr key={key}>
                                  <td>{form.id}</td>
                                  <td>
                                    {DateTimeUtil.toString(
                                      form.createdTime * 1000
                                    )}
                                  </td>
                                  <td>{form.codes.join(", ")}</td>
                                  <td>{form.point}</td>
                                  <td>
                                    <span
                                      style={{
                                        backgroundColor:
                                          StockFormStatus.findColor(
                                            form.status
                                          ),
                                      }}
                                      className="badge badge-info"
                                    >
                                      {StockFormStatus.findName(form.status)}
                                    </span>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}

export default DetailAccumulationPage;
