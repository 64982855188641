import Layout from "../../../component/Layout";
import { useEffect, useState } from "react";
import { Page } from "../../../interface";
import { City } from "../../../utils/ProvinceUtil";
import { useSearchParams } from "react-router-dom";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { Pagination } from "antd";
import AccumulationModel from "../../../model/AccumulationModel";
import NumberUtils from "../../../utils/NumberUtils";
import LinkUtil from "../../../utils/LinkUtil";
import { FormFilter } from "../../../interface/index";
import ConfigConstant from "../../../constant/config";

const default_avatar = "http://cdn.onlinewebfonts.com/svg/img_264570.png";
const PAGE_SIZE = 10;

const PROMOTION_ID = ConfigConstant.PREDICT_FOOTBALL.promotionId;
function ListAccumulationPredictFootballPage() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [historyAccumulationPage, setHistoryAccumulationPage] =
    useState<Page<any>>();
  const [filter, setFilter] = useState<any>(LinkUtil.initParam(searchParams));

  const fetchAccumulations = () => {
    AccumulationModel.find(
      PROMOTION_ID,
      filter.search,
      filter.page,
      PAGE_SIZE
    ).then((resp) => {
      if (resp.error === 0) {
        setHistoryAccumulationPage(resp.data);
      }
    });
  };

  const serialize2Url = () => {
    var q = JSON.stringify(filter);
    if (q !== "{}") {
      searchParams.set("q", q);
      setSearchParams(searchParams);
    }
  };

  const parseParams = () => {
    var param = searchParams.get("q");
    if (param) {
      setFilter(JSON.parse(param));
    }
  };

  const export_excel = () => {
    window.location.href =
      process.env.REACT_APP_DOMAIN +
      `/api/accumulation/export-excel?promotionId=${PROMOTION_ID}`;
    return;
  };

  useEffect(() => {
    parseParams();
  }, []);

  useEffect(() => {
    fetchAccumulations();
    serialize2Url();
  }, [filter]);

  return (
    <Layout>
      <main className="content">
        <div className="container-fluid">
          <div className="header">
            <h1 className="header-title">Báo cáo chương trình nhà thầu</h1>
          </div>
          <div className="row">
            <div className="col-12 col-xl-12">
              <div className="card">
                <div className="card-bar" style={{ padding: "10px" }}>
                  <div className="row ">
                    <div className="col-2 col-xl-2 ml-auto">
                      <input
                        className="form-control"
                        placeholder="SDT hoặc Tên"
                        type="text"
                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                          setFilter({
                            search: e.currentTarget.value,
                          } as FormFilter);
                        }}
                      />
                    </div>
                    <div className="col-2 col-xl-2 flex-end">
                      <Button type="primary" onClick={export_excel}>
                        Xuất Excel
                      </Button>
                    </div>
                  </div>
                </div>
                <table className="table">
                  <thead>
                    <tr>
                      <th></th>
                      <th>SDT</th>
                      <th>Nhà thầu</th>
                      <th>Địa chỉ</th>
                      <th>Số điểm đã tích</th>
                      <th>Số điểm còn lại</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {historyAccumulationPage &&
                      historyAccumulationPage.list &&
                      historyAccumulationPage.list.map((accumulation, key) => {
                        return (
                          <tr key={key}>
                            <td>
                              <img
                                className="m-avatar"
                                alt=""
                                src={
                                  accumulation.user.avatar
                                    ? accumulation.user.avatar
                                    : default_avatar
                                }
                              />
                            </td>
                            <td>{accumulation.user.phone}</td>
                            <td>{accumulation.user.name}</td>
                            <td>{City.getName(accumulation.user.cityId)}</td>
                            <td>
                              {NumberUtils.formatNumber(
                                accumulation.historyPoint
                              )}
                            </td>
                            <td>
                              {NumberUtils.formatNumber(accumulation.point)}
                            </td>
                            <td className="table-action">
                              <Button
                                onClick={() => {
                                  navigate(
                                    `/${ConfigConstant.PREDICT_FOOTBALL.key}/accumulation/${accumulation.id}`
                                  );
                                }}
                                type="primary"
                              >
                                Chi tiết
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <div className="cart-paging">
                  {historyAccumulationPage && (
                    <Pagination
                      defaultCurrent={1}
                      current={filter.page + 1}
                      onChange={(pageNumber) => {
                        setFilter({ ...filter, page: pageNumber - 1 });
                      }}
                      total={historyAccumulationPage.totalPage * PAGE_SIZE}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}

export default ListAccumulationPredictFootballPage;
