import Layout from "../../../component/Layout";
import { useEffect, useState } from "react";
import { Department, DepartmentFilter, Page, TypeTopic } from "../../../interface";
import { useSearchParams } from "react-router-dom";
import { Avatar, Button, Pagination } from "antd";
import { useNavigate } from "react-router-dom";
import LinkUtil from "../../../utils/LinkUtil";
import DepartmentModel from "../../../model/DepartmentModel";
const PAGE_SIZE = 10;


function ListDepartmentPage(props: any) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [departmentPage, setDepartmentPage] = useState<Page<Department>>();
  const [filter, setFilter] = useState<DepartmentFilter>({ ...LinkUtil.params2Object(searchParams), page: 0 });


  const fetchDepartments = () => {
    DepartmentModel.find(filter.managerId,
      filter.page, filter.pageSize).then((resp) => {
        if (resp.error === 0) {
          setDepartmentPage(resp.data);
        }
      });
  };


  useEffect(() => {
    setFilter(LinkUtil.params2Object(searchParams))
  }, [searchParams])

  useEffect(() => {
    fetchDepartments()
  }, [filter]);



  return (
    <Layout>
      <main className="content">
        <div className="container-fluid">
          <div className="header">
            <h1 className="header-title">Phòng ban</h1>
          </div>
          {/* <div className="row">
            <div className="col-md-6 col-lg-3 col-xl">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col mt-0">
                      <h5 className="card-title">Đã tiếp nhận</h5>
                    </div>
                    <div className="col-auto">
                      <div className="avatar">
                        <div className="avatar-title rounded-circle bg-primary-dark">
                          <i className="align-middle fas fa-fw fa-store"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h1 className="display-5 mt-1 mb-3">
                    {stats?.total}
                  </h1>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-3 col-xl">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col mt-0">
                      <h5 className="card-title">Đang xử lý</h5>
                    </div>
                    <div className="col-auto">
                      <div className="avatar">
                        <div className="avatar-title rounded-circle bg-primary-dark">
                          <i className="align-middle fas fa-fw  fa-ad"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h1 className="display-5 mt-1 mb-3">
                    {stats?.init}
                  </h1>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-3 col-xl">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col mt-0">
                      <h5 className="card-title">Đã phẩn hồi</h5>
                    </div>
                    <div className="col-auto">
                      <div className="avatar">
                        <div className="avatar-title rounded-circle bg-primary-dark">
                          <i className="align-middle far fa-fw fa-heart"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h1 className="display-5 mt-1 mb-3">
                    {stats?.received}
                  </h1>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-3 col-xl">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col mt-0">
                      <h5 className="card-title">Đóng</h5>
                    </div>
                    <div className="col-auto">
                      <div className="avatar">
                        <div className="avatar-title rounded-circle bg-primary-dark">
                          <i className="align-middle fas fa-fw fa-gift"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h1 className="display-5 mt-1 mb-3">
                    {stats?.close}
                  </h1>
                </div>
              </div>
            </div>
          </div> */}
          <div className="row">
            <div className="col-12 col-xl-12">
              <div className="card">
                <div className="card-bar" style={{ padding: "10px" }}>
                </div>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Phòng ban</th>
                      <th>Quản lý</th>
                      <th>Nhân viên</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {departmentPage &&
                      departmentPage.list &&
                      departmentPage.list.map((department, key) => {
                        return (
                          <tr key={department.id}>
                            <td>{department.name}</td>
                            <td>
                              <Avatar size={32} src={department.manager.avatar} />
                              <span style={{ paddingLeft: '10px' }}>{department?.manager?.name}</span>
                            </td>
                            <td>{department?.employeeIds?.length || 0}</td>
                            <td className="table-action">
                              <Button
                                type="primary"
                              >
                                Chi tiết
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <div className="cart-paging">
                  {departmentPage && (
                    <Pagination
                      defaultCurrent={1}
                      defaultPageSize={PAGE_SIZE}
                      current={(filter.page || 0) + 1}
                      onChange={(pageNumber) => {
                        setFilter({ ...filter, page: pageNumber - 1 });
                      }}
                      showSizeChanger={false}
                      total={departmentPage.totalPage * PAGE_SIZE}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}

export default ListDepartmentPage;
