import APIUtil from "../utils/APIUtils";

const domain = process.env.REACT_APP_DOMAIN;

export default class EmployeeModel {

    static find(text , page, pageSize) {
        var param = Object.fromEntries(Object.entries({
            text, page, pageSize
        }).filter(([_, v]) => v != null && v !== 0));
        return new Promise((resolve, reject) => {
            APIUtil.getJSONWithCredentials(
                domain +
                `/api/employee/find?${new URLSearchParams(param).toString()}`,
                resolve,
                reject
            );
        });
    }


    static search(text, departmentId) {
        var param = Object.fromEntries(Object.entries({
            text,
            departmentId
        }).filter(([_, v]) => v != null && v !== 0));
        return new Promise((resolve, reject) => {
            APIUtil.getJSONWithCredentials(
                domain +
                `/api/employee/search?${new URLSearchParams(param).toString()}`,
                resolve,
                reject
            );
        });
    }
}