import "../../../component/popup/styles.scss";
import { useEffect, useState } from "react";

import Layout from "../../../component/Layout";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "antd";
import moment from "moment";
import TransactionWarehouseModel from "../../../model/TransactionWarehouseModel";
import AlertUtils from "../../../utils/AlertUtils";


interface ErrorFormUpdateWarehouse {
  errorPhoneInput: string,
  errorPlateInput: string,
  errorFrameInput: string
}

function UpdateWarehousePage() {
  const navigate = useNavigate();
  const [error, setError] = useState<ErrorFormUpdateWarehouse>()
  const [timeSnapshot, set_timeSnapshot] = useState(moment());
  const [form, setForm] = useState<any>({
    timeSnapshot: timeSnapshot.toDate().getTime(),
    plate: 0,
    frame: 0,
    phone: ''
  });

  const changeTime = (data) => {
    if (data) {
      set_timeSnapshot(data);
      setForm({ ...form, timeSnapshot: data.toDate().getTime() });
    }

  };

  const submit = () => {
    setError(undefined)
    if (!form.phone || form.phone <= 0) {
      setError({ ...form, errorPhoneInput: 'Vui lòng nhập số điện thoại' })
      return;
    }

    if (form.plate <= 0 && form.frame <= 0) {
      setError({ ...form, errorPlateInput: 'Vui lòng nhập số  tấm hoặc khung' })
      return;
    }

    TransactionWarehouseModel.create({
      phone: form.phone,
      plate: form.plate,
      frame: form.frame,
      timeSnapshot: form.timeSnapshot / 1000
    }).then(resp => {
      if (resp.error == 0) {
        AlertUtils.showSuccess("Thành công")
        navigate(-1)
      } else {
        AlertUtils.showError(resp.msg)
      }
    })

  }

  return (
    <Layout>
      <main className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-xl-12">
              <div className="tab-content">
                <div
                  className="tab-pane fade show active"
                  id="account"
                  role="tabpanel"
                >
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title mb-0">Cập nhật thêm kho</h5>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-6">
                          <div className="form-group">
                            <label>Số điện thoại</label>
                            <input
                              type="text"
                              className="form-control"
                              value={form.phone}
                              onChange={(event) => {
                                console.log(event.target.value)
                                setForm({ ...form, phone: event.target.value })
                              }}
                            />
                            {error?.errorPhoneInput && <p className="error">{error?.errorPhoneInput}</p>}
                          </div>
                          <div className="form-group">
                            <label>Số Tấm</label>
                            <input
                              type="text"
                              className="form-control"
                              value={form.plate}
                              onChange={(event) => {
                                setForm({ ...form, plate: Number(event.target.value) })
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <label>Số Khung</label>
                            <input
                              type="text"
                              className="form-control"
                              value={form.frame}
                              onChange={(event) => {
                                setForm({ ...form, frame: Number(event.target.value) })
                              }}
                            />
                          </div>
                          {error?.errorPlateInput && <p className="error">{error?.errorPlateInput}</p>}
                          <div className="form-group">
                            <label style={{ width: "100%" }}>
                              Thời gian
                            </label>
                            <DatePicker
                              value={moment(timeSnapshot)}
                              onChange={changeTime}
                              format={"DD/MM/YYYY"}
                            />
                          </div>
                          <button
                            className="btn btn-primary m-btn-primary"
                            style={{ float: "right" }}
                            onClick={submit}
                          >
                            Save
                          </button>
                        </div>
                        <div className="col-6"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}

export default UpdateWarehousePage;
