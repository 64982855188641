import Layout from "../../../component/Layout";
import "../../../component/popup/styles.scss";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import UserModel from "../../../model/UserModel";
import { User } from "../../../interface";
import { City } from "../../../utils/ProvinceUtil";

import {
  AreYouSurePopup,
  AreYouSureWithNoteDropdownPopup,
  ShowOnFormPopup,
} from "../../../component/popup";
import * as UserStatus from "../../../constant/status/UserStatus";
import * as UserType from "../../../constant/type/UserType";
import AlertUtils from "../../../utils/AlertUtils";
import {
  CONSTRUCTOR,
  DISTRIBUTOR,
  RETAILER,
  DISTRIBUTOR_T2,
} from "../../../constant/type/UserType";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import UploadImageV2 from "../../../component/UploadImageV2";

import "./user.css";
import FormModel from "../../../model/FormModel";
import * as StatusForm from "../../../constant/status/FormStatus";
import { QRCode } from "react-qrcode-logo";

const default_avatar = "http://cdn.onlinewebfonts.com/svg/img_264570.png";
let list_reason_user_reject = [
  "Hình ảnh bảng hiệu không hợp lệ, Qúy Khách vui lòng chụp và đăng kí lại nhé",
  "Cửa hàng Qúy Khách đã được đăng kí trên tài khoản zalo khác, nếu có gì thắc mắc, quý khách liên hệ tới hotline “0906997459” nhé",
];

let list_resson_user_reject_ctr = [
  "Hình ảnh CMND/CCCD không rõ",
  "Họ tên không trùng khớp với CMND/CCCD",
  "Số CMND/CCCD này đã được sử  dụng bởi một SDT khác, vui lòng liên hệ hotline: “0906997459” để biết thêm chi tiết",
];

interface PropsOfPageDetailUser {
  config: any;
}

function DetailUserPage(props: PropsOfPageDetailUser) {
  const navigate = useNavigate();
  let { id } = useParams();
  const [isShowConfirmDeletePopup, setIsShowConfirmDeletePopup] =
    useState(false);
  const [isShowConfirmUpdatePopup, setIsShowConfirmUpdatePopup] =
    useState(false);
  const [isShowConfirmApprovedPopup, setIsShowConfirmApprovedPopup] =
    useState(false);
  const [
    isShowConfirmRejectedWithNotePopup,
    setIsShowConfirmRejectedWithNotePopup,
  ] = useState(false);

  const [isShowOnFormPopup, set_isShowOnFormPopup] = useState(false);

  const [user, setUser] = useState<User>();
  const [isLoading, setIsLoading] = useState(false);
  const [isChangeRole, setIsChangeRole] = useState(false);
  const [count_form, set_count_form] = useState(0);
  const [count_form_waiting, set_count_form_waiting] = useState(0);
  const [count_form_approved, set_count_form_approved] = useState(0);

  const fetchUser = (id: any) => {
    setIsLoading(true);
    UserModel.get(id)
      .then((resp) => {
        if (resp.error === 0) {
          setUser(resp.data);
          setIsChangeRole(resp.data.roleId !== RETAILER);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const updateStatus = (id: any, status: number, note: any) => {
    UserModel.updateStatus(id, status, note).then((resp) => {
      if (resp.error === 0) {
        navigate(-1);
        fetchUser(id);
      }
    });
  };

  const deleteUser = () => {
    UserModel.delete(id).then((resp) => {
      if (resp.error === 0) {
        AlertUtils.showSuccess("Đã xóa thành công");
        navigate(-1);
      } else {
        AlertUtils.showError(resp.msg);
      }
    });
  };

  const updateUser = () => {
    UserModel.update(user).then((resp) => {
      if (resp.error === 0) {
        AlertUtils.showSuccess("Đã cập nhập thành công");
      } else {
        AlertUtils.showError(resp.msg);
      }
    });
  };

  const get_num_form_approved = () => {
    FormModel.count(
      StatusForm.APPROVED,
      props.config?.promotionId,
      user?.id
    ).then((resp) => {
      set_count_form_approved(resp.data);
    });
  };

  const get_num_form_waiting = () => {
    FormModel.count(StatusForm.INIT, props.config?.promotionId, user?.id).then(
      (resp) => {
        set_count_form_waiting(resp.data);
      }
    );
  };

  const get_num_form = () => {
    FormModel.count(null, props.config?.promotionId, user?.id).then((resp) => {
      set_count_form(resp.data);
    });
  };

  useEffect(() => {
    fetchUser(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user?.id) {
      get_num_form_approved();
      get_num_form_waiting();
      get_num_form();
    }
  }, [user]);

  if (isLoading) {
    return <div></div>;
  }

  return (
    <Layout>
      {
        <AreYouSurePopup
          open={isShowConfirmDeletePopup}
          onAgree={() => {
            setIsShowConfirmDeletePopup(false);
            deleteUser();
          }}
          onCloseModal={() => {
            setIsShowConfirmDeletePopup(false);
          }}
        />
      }
      {
        <AreYouSurePopup
          open={isShowConfirmUpdatePopup}
          onAgree={() => {
            setIsShowConfirmUpdatePopup(false);
            updateUser();
          }}
          onCloseModal={() => {
            setIsShowConfirmUpdatePopup(false);
          }}
        />
      }
      DISTRIBUTOR_T2
      {
        <AreYouSurePopup
          open={isShowConfirmApprovedPopup}
          onAgree={() => {
            setIsShowConfirmApprovedPopup(false);
            updateStatus(user?.id, UserStatus.APPROVED, null);
          }}
          onCloseModal={() => {
            setIsShowConfirmApprovedPopup(false);
          }}
        />
      }
      {
        <AreYouSureWithNoteDropdownPopup
          list={
            user?.roleId === UserType.CONSTRUCTOR
              ? list_resson_user_reject_ctr
              : list_reason_user_reject
          }
          open={isShowConfirmRejectedWithNotePopup}
          onAgree={(note) => {
            setIsShowConfirmRejectedWithNotePopup(false);
            updateStatus(user?.id, UserStatus.REJECTED, note);
          }}
          onCloseModal={() => {
            setIsShowConfirmRejectedWithNotePopup(false);
          }}
        />
      }
      {
        <ShowOnFormPopup
          userId={user?.id}
          open={isShowOnFormPopup}
          onAgree={() => {
            set_isShowOnFormPopup(false);
            fetchUser(user?.id);
          }}
          onCloseModal={() => {
            set_isShowOnFormPopup(false);
            fetchUser(user?.id);
          }}
        />
      }
      <main className="content">
        <div className="container-fluid card">
          {user && (
            <div className="row profile">
              <div className="col-3">
                <div className="profile-sidebar">
                  <div className="profile-userpic">
                    <div className="text-center">
                      {[
                        UserType.DISTRIBUTOR,
                        UserType.DISTRIBUTOR_T2,
                        UserType.RETAILER,
                      ].includes(user.roleId) ? (
                        <QRCode
                          id="qrcode"
                          value={`https://zalo.me/s/1374515648362638464/invoice-form/${user.id}`}
                          size={290}
                          ecLevel={"H"}
                          logoImage={user.avatar}
                          removeQrCodeBehindLogo={false}
                          logoPaddingStyle={"square"}
                          logoPadding={10}
                          logoWidth={90}
                          qrStyle={"dots"}
                          // includeMargin={true}
                        />
                      ) : (
                        <img
                          alt=""
                          src={user.avatar ? user.avatar : default_avatar}
                          className="rounded-circle img-responsive mt-2"
                          width={128}
                          height={128}
                        />
                      )}
                    </div>
                  </div>
                  <div className="profile-usertitle">
                    <div className="profile-usertitle-name">{user.name}</div>
                    <div className="profile-usertitle-job">
                      {UserType.findName(user.roleId)}
                    </div>
                  </div>

                  <div className="portlet light bordered">
                    <div className="row list-separated profile-stat">
                      <div className="col-md-4 col-sm-4 col-xs-6">
                        <div className=" profile-stat-title">
                          {count_form_waiting}
                        </div>
                        <div className="uppercase profile-stat-text">
                          Đơn Chờ
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4 col-xs-6">
                        <div className=" profile-stat-title">
                          {count_form_approved}
                        </div>
                        <div className="uppercase profile-stat-text">
                          Đã duyêt
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4 col-xs-6">
                        <div className=" profile-stat-title">{count_form}</div>
                        <div className="uppercase profile-stat-text">
                          Tổng đơn
                        </div>
                      </div>
                    </div>
                    <div>
                      <h4 className="profile-desc-title">Thông tin</h4>
                      <span className="profile-desc-text">Tài khoản zalo</span>
                      <div
                        className="margin-top-20 profile-desc-link"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span
                          className="uppercase profile-desc-title"
                          style={{ fontSize: "14px" }}
                        >
                          ID:
                        </span>
                        {user.id}
                      </div>
                      <div
                        className="margin-top-20 profile-desc-link"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span
                          className="uppercase profile-desc-title"
                          style={{ fontSize: "14px" }}
                        >
                          SDT:
                        </span>
                        {user.phone}
                      </div>
                      <div
                        className="margin-top-20 profile-desc-link"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span
                          className="uppercase profile-desc-title"
                          style={{ fontSize: "14px" }}
                        >
                          Trạng thái:
                        </span>
                        <span
                          style={{
                            backgroundColor: UserStatus.findColor(user?.status),
                            padding: "0 10px",
                            color: "white",
                          }}
                        >
                          {UserStatus.findName(user?.status)}
                        </span>
                      </div>
                      {/* <div
                        className="margin-top-20 profile-desc-link"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span
                          className="uppercase profile-desc-title"
                          style={{ fontSize: "14px" }}
                        >
                          QR:
                        </span>
                        
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-8">
                <div className="profile-content">
                  <div className="card box-shadow-none">
                    {/* <div className="card-header">
                      <h5 className="card-title mb-0">Thông tin thành viên</h5>
                    </div> */}
                    <div className="card-body">
                      {user && (
                        <form>
                          <div className="row">
                            <div className="col-12">
                              <div className="form-group">
                                <label htmlFor="inputUsername">
                                  Đại lý / Nhà thầu / NPP
                                </label>
                                <select
                                  disabled={isChangeRole}
                                  className="form-control"
                                  value={user.roleId}
                                  onChange={(
                                    e: React.FormEvent<HTMLSelectElement>
                                  ) => {
                                    setUser({
                                      ...user,
                                      roleId: Number(e.currentTarget.value),
                                    });
                                  }}
                                >
                                  {(!user.roleId || user.roleId === 0) && (
                                    <option value={0}></option>
                                  )}
                                  <option value={CONSTRUCTOR}>Nhà thầu</option>
                                  <option value={RETAILER}>Đại lý</option>
                                  <option value={DISTRIBUTOR}>
                                    Nhà Phân Phối
                                  </option>
                                  <option value={DISTRIBUTOR_T2}>
                                    Nhà Phân Phối T2
                                  </option>
                                </select>
                              </div>
                              <div className="form-group">
                                <label htmlFor="inputUsername">Tên Zalo</label>
                                <input
                                  type="text"
                                  onChange={(
                                    e: React.FormEvent<HTMLInputElement>
                                  ) => {
                                    setUser({
                                      ...user,
                                      name: e.currentTarget.value,
                                    });
                                  }}
                                  className="form-control"
                                  disabled={[
                                    DISTRIBUTOR,
                                    DISTRIBUTOR_T2,
                                  ].includes(user?.roleId)}
                                  value={user.name}
                                />
                              </div>
                              {user.roleId === CONSTRUCTOR && (
                                <div className="form-group">
                                  <label htmlFor="inputUsername">
                                    Họ và tên
                                  </label>
                                  <input
                                    type="text"
                                    onChange={(
                                      e: React.FormEvent<HTMLInputElement>
                                    ) => {
                                      setUser({
                                        ...user,
                                        fullName: e.currentTarget.value,
                                      });
                                    }}
                                    className="form-control"
                                    value={user.fullName}
                                  />
                                </div>
                              )}
                              <div className="form-group">
                                <label htmlFor="inputUsername">
                                  Số điện thoại
                                </label>
                                <input
                                  disabled
                                  type="text"
                                  className="form-control"
                                  value={user.phone}
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="inputUsername">
                                  Thành phố / Tỉnh
                                </label>
                                <select
                                  disabled
                                  className="form-control"
                                  value={user.cityId}
                                  onChange={(
                                    e: React.FormEvent<HTMLSelectElement>
                                  ) => {
                                    setUser({
                                      ...user,
                                      cityId: Number(e.currentTarget.value),
                                    });
                                  }}
                                >
                                  {(!user.cityId || user.cityId === 0) && (
                                    <option value={0}></option>
                                  )}
                                  {City.getOptions().map((item) => {
                                    return (
                                      <option
                                        key={item.value}
                                        value={item.value}
                                      >
                                        {item.label}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="form-group">
                                <label htmlFor="inputUsername">Địa chỉ</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(
                                    e: React.FormEvent<HTMLInputElement>
                                  ) => {
                                    setUser({
                                      ...user,
                                      address: e.currentTarget.value,
                                    });
                                  }}
                                  value={user.address}
                                />
                              </div>
                              {(user.roleId === RETAILER || user.roleId === DISTRIBUTOR || user.roleId === DISTRIBUTOR_T2) && (
                                <>
                                  <div className="form-group">
                                    <label htmlFor="inputUsername">
                                      Tên Cửa Hàng
                                    </label>
                                    <input
                                      type="text"
                                      onChange={(
                                        e: React.FormEvent<HTMLInputElement>
                                      ) => {
                                        setUser({
                                          ...user,
                                          nameStore: e.currentTarget.value,
                                        });
                                      }}
                                      className="form-control"
                                      value={user.nameStore}
                                    />
                                  </div>
                                  {user.imageStore && (
                                    <div className="form-group">
                                      <label htmlFor="inputUsername">
                                        Hình ảnh cửa hàng
                                      </label>
                                      <div className="text-left">
                                        {/* <Image src={user.imageStore} /> */}
                                        <UploadImageV2
                                          change_images={(data: any) =>
                                            setUser({
                                              ...user,
                                              imageStore: data[0]?.url,
                                            })
                                          }
                                          size={1}
                                          disabled={false}
                                          exits_images={[
                                            { url: user.imageStore },
                                          ]}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}
                              {user.roleId === CONSTRUCTOR && (
                                <>
                                  <div className="form-group">
                                    <label htmlFor="inputUsername">
                                      Số CMND / CCCD
                                    </label>
                                    <input
                                      type="text"
                                      onChange={(
                                        e: React.FormEvent<HTMLInputElement>
                                      ) => {
                                        setUser({
                                          ...user,
                                          personalCode: e.currentTarget.value,
                                        });
                                      }}
                                      className="form-control"
                                      value={user.personalCode}
                                    />
                                  </div>
                                  {user.imageCMND && (
                                    <div className="form-group">
                                      <label htmlFor="inputUsername">
                                        Hình ảnh CMND
                                      </label>
                                      <div className="img-center">
                                        <UploadImageV2
                                          change_images={(data: any) =>
                                            setUser({
                                              ...user,
                                              imageCMND: data[0]?.url,
                                            })
                                          }
                                          size={1}
                                          disabled={false}
                                          exits_images={[
                                            { url: user.imageCMND },
                                          ]}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </form>
                      )}
                    </div>
                    {user && (
                      <div className="card-footer">
                        {user.status === UserStatus.WAIT_APPROVAL && (
                          <>
                            <button
                              onClick={() => {
                                setIsShowConfirmApprovedPopup(true);
                              }}
                              type="submit"
                              className="btn btn-danger m-btn-danger"
                            >
                              Duyệt
                            </button>
                            <button
                              onClick={() => {
                                setIsShowConfirmRejectedWithNotePopup(true);
                              }}
                              style={{
                                marginLeft: "40px",
                                backgroundColor: "#3e4676",
                              }}
                              type="submit"
                              className="btn btn-danger m-btn-danger"
                            >
                              Từ chối
                            </button>
                          </>
                        )}
                        <button
                          onClick={() => {
                            setIsShowConfirmUpdatePopup(true);
                          }}
                          style={{
                            marginLeft: "40px",
                          }}
                          className="btn btn-primary m-btn-primary"
                        >
                          Cập nhập
                        </button>
                        <Button
                          onClick={() => {
                            setIsShowConfirmDeletePopup(true);
                          }}
                          style={{ marginRight: "40px", marginLeft: "40px" }}
                          danger={true}
                        >
                          Xóa
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </main>
    </Layout>
  );
}

export default DetailUserPage;
