import "react-responsive-modal/styles.css";
import "../../../component/popup/styles.scss";
import { useEffect, useState } from "react";
import { GiftOrder } from "../../../interface";
import {
  AreYouSurePopup,
  AreYouSureWithNotePopup,
} from "../../../component/popup";
import GiftOrderModel from "../../../model/GiftOrderModel";
import * as GiftOrderStatus from "../../../constant/status/GiftOrderStatus";
import { City, District } from "../../../utils/ProvinceUtil";
import Layout from "../../../component/Layout";
import { useNavigate, useParams } from "react-router-dom";
import AlertUtils from "../../../utils/AlertUtils";
import DateTimeUtil from "../../../utils/DateTimeUtil";
import {
  RTL_EP_01_TYPE_GIFT_PROMOTION,
  CTR_SCAN_QR_TYPE_GIFT_PROMOTION,
} from "../../../constant/type/GiftOrderType";

function DetailGiftOrderPromotion2023Page() {
  let { id } = useParams();
  const navigate = useNavigate();
  const [isShowApprovedPopup, setIsShowApprovedPopup] = useState(false);
  const [isShowSentPopup, setIsShowSentPopup] = useState(false);
  const [isRejectPopup, setIsRejectPopup] = useState(false);
  const [giftOrder, setGiftOrder] = useState<GiftOrder>();

  const fetchGiftOrder = () => {
    GiftOrderModel.get(id).then((resp) => {
      if (resp.error === 0) {
        setGiftOrder(resp.data);
      }
    });
  };

  const updateStatus = (id: any, status: number, note: any) => {
    GiftOrderModel.updateStatus(id, status, note).then((resp) => {
      if (resp.error === 0) {
        navigate(-1);
        AlertUtils.showSuccess("Thành công!");
      } else {
        AlertUtils.showError(resp.msg);
      }
      fetchGiftOrder();
    });
  };

  const getFullAddress = (user) => {
    let tmp = `${user.address}`;
    if (user.districtId != null) {
      tmp += `, ${District.getName(user.districtId)}`;
    }
    if (user.cityId != null) {
      tmp += `, ${City.getName(user.cityId)}`;
    }
    return tmp;
  };

  useEffect(() => {
    fetchGiftOrder();
  }, []);

  return (
    <Layout>
      <main className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-xl-12">
              <div className="tab-content">
                <div
                  className="tab-pane fade show active"
                  id="account"
                  role="tabpanel"
                >
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title mb-0">Chi tiết</h5>
                    </div>
                    <div className="card-body">
                      {giftOrder && (
                        <form>
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label htmlFor="inputUsername">Tên</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={giftOrder.user.name}
                                />
                              </div>
                              {giftOrder.type ==
                                RTL_EP_01_TYPE_GIFT_PROMOTION && (
                                  <div className="form-group">
                                    <label htmlFor="inputUsername">
                                      Cửa hàng
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={giftOrder.user.nameStore}
                                    />
                                  </div>
                                )}
                              <div className="form-group">
                                <label htmlFor="inputUsername">
                                  Số điện thoại
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={giftOrder.user.phone}
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="inputUsername">Địa chỉ</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={getFullAddress(giftOrder.user)}
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="inputUsername">Thời gian</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={DateTimeUtil.toStringDetail(
                                    giftOrder.createdTime * 1000
                                  )}
                                />
                              </div>
                              {giftOrder.type ==
                                RTL_EP_01_TYPE_GIFT_PROMOTION && (
                                  <div className="form-group">
                                    <label htmlFor="inputUsername">
                                      Quà tặng
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={giftOrder.gift.name}
                                    />
                                  </div>
                                )}
                              {giftOrder.type ==
                                CTR_SCAN_QR_TYPE_GIFT_PROMOTION && (
                                  <div className="form-group">
                                    <label htmlFor="inputUsername">Giá trị</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={giftOrder.money.toLocaleString(
                                        "it-IT",
                                        { style: "currency", currency: "VND" }
                                      )}
                                    />
                                  </div>
                                )}
                              {giftOrder.type ==
                                CTR_SCAN_QR_TYPE_GIFT_PROMOTION && (
                                  <div className="form-group">
                                    <label htmlFor="inputUsername">
                                      GotIt Code
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={giftOrder.gift.gotItCode}
                                    />
                                  </div>
                                )}
                              <div className="form-group">
                                <label htmlFor="inputUsername">Số lượng</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={giftOrder.amount}
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="inputUsername">
                                  Tổng số điểm
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={giftOrder.point}
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="inputUsername">
                                  Trạng thái
                                </label>
                                <input
                                  style={{
                                    backgroundColor: GiftOrderStatus.findColor(
                                      giftOrder.status
                                    ),
                                  }}
                                  disabled
                                  type="text"
                                  className="form-control input-detail-status"
                                  value={GiftOrderStatus.findName(
                                    giftOrder.status
                                  )}
                                />
                              </div>
                            </div>
                            <div className="col-md-8">
                              <div className="text-center">
                                <div className="previews-img-container">
                                  <div className="item">
                                    <img
                                      alt=""
                                      src={
                                        giftOrder.gift.link ||
                                        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTlJ37z4GI_bLaF6P6WHYpmOSDvdvTTdyw6YDiovLkdZeOnbMQ4DuDxXgZJwFRnrpCxC0M&usqp=CAU"
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      )}
                    </div>
                    <div className="card-footer">
                      {giftOrder?.status === GiftOrderStatus.APPROVED &&
                        <>
                          <button
                            onClick={() => setIsShowSentPopup(true)}
                            className="btn btn-green"
                          >
                            Gửi quà
                          </button>
                          <AreYouSurePopup
                            open={isShowSentPopup}
                            onCloseModal={() => {
                              setIsShowSentPopup(false);
                            }}
                            onAgree={() => {
                              updateStatus(id, GiftOrderStatus.SEND, null);
                              setIsShowSentPopup(false);
                            }}
                          />
                        </>
                      }
                      {giftOrder?.status === GiftOrderStatus.INIT && (
                        <>
                          <button
                            onClick={() => setIsShowApprovedPopup(true)}
                            className="btn btn-green"
                          >
                            Duyệt
                          </button>
                          <AreYouSurePopup
                            open={isShowApprovedPopup}
                            onCloseModal={() => {
                              setIsShowApprovedPopup(false);
                            }}
                            onAgree={() => {
                              updateStatus(id, GiftOrderStatus.APPROVED, null);
                              setIsShowApprovedPopup(false);
                            }}
                          />

                          <button
                            onClick={() => setIsRejectPopup(true)}
                            style={{ marginLeft: "30px", padding: "5px 30px" }}
                            className="btn btn-delete"
                          >
                            Từ chối
                          </button>
                          <AreYouSureWithNotePopup
                            open={isRejectPopup}
                            onCloseModal={() => {
                              setIsRejectPopup(false);
                            }}
                            onAgree={(note) => {
                              updateStatus(id, GiftOrderStatus.REJECTED, note);
                              setIsRejectPopup(false);
                            }}
                          />

                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}

export default DetailGiftOrderPromotion2023Page;
