import province from './province.json'
import region from './region.json'

var __DISTRICTS = [];
var __CITIES = [];
var __CITY_OPTIONS = [];

function compare(a, b) {
    if (a.value < b.value) {
        return -1;
    }
    if (a.value > b.value) {
        return 1;
    }
    return 0;
}

for (const cityId in province) {
    const city = province[cityId];
    __CITIES.push({ key: cityId, value: city.name });
    __CITY_OPTIONS.push({ value: cityId, label: city.name })

    city["districts"].forEach(district => {
        __DISTRICTS.push({ key: district.id, value: { name: district.name, cityId: cityId } });
    });
}

__CITIES = __CITIES.sort(compare)
__CITY_OPTIONS = __CITY_OPTIONS.sort(compare)


export class Region {
    static SOUTH = 1;
    static CENTRAL = 2;
    static NORTH = 3;

    static getName(id) {
        return region[id]?.name;
    }

    static getNameByCityId(cityId) {
        const _regions = [this.SOUTH, this.CENTRAL, this.NORTH];
        for (const _region of _regions) {
            if (region[_region].provinces.includes(Number(cityId))) {
                return region[_region].name;
            }
        }
        return "";
    }

    static getList() {
        return Object.entries(region).map(([key, value]) => ({ key, value }));
    }

    static get(id) {
        return region[id]
    }

    static getListCity(id) {
        if (!id) return __CITIES;
        return __CITIES.filter(item => region[id]?.provinces?.includes(Number(item.key)));
    }
}

export class City {
    static getName(id) {
        return province[id]?.name || "None";
    }

    static getList() {
        return __CITIES
    }

    static getOptions() {
        return __CITY_OPTIONS;
    }
}

export class District {
    static getName(districtId) {
        const targetDistrict = __DISTRICTS.find(district => district.key === districtId);
        return targetDistrict?.value?.name;
    }

    static getList(cityId) {
        const districts = province[cityId]?.districts ?? [];
        return districts.map(district => ({ key: district.id, value: district.name }))
            .sort(compare);
    }

    static getOption(listCityIds) {
        let options = [];
        for (const cityId of listCityIds) {
            options.push(...this.getList(cityId)?.map(district => ({ value: district.key, label: district.value })) ?? []);
        }
        return options;
    }
}