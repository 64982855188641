import APIUtil from "../utils/APIUtils";

const domain = process.env.REACT_APP_DOMAIN;

export default class DepartmentModel {

    static find(managerId, page, pageSize) {
        var param = Object.fromEntries(Object.entries({
            managerId, page, pageSize
        }).filter(([_, v]) => v != null && v !== 0));
        return new Promise((resolve, reject) => {
            APIUtil.getJSONWithCredentials(
                domain +
                `/api/department/find?${new URLSearchParams(param).toString()}`,
                resolve,
                reject
            );
        });
    }

    static getList() {
        return new Promise((resolve, reject) => {
            APIUtil.getJSONWithCredentials(
                domain +
                `/api/department/list`,
                resolve,
                reject
            );
        });
    }
}